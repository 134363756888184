import "./Tabs.css";
import { useEffect } from "react";

interface ProjectTabsProps {
  tabList: any;
  uniqueId: any;
  classNames?: any;
  onSelectTab?: any;
  activeIndex?: any;
  projectStatus?: any;
}

const ProjectTabs = (props: ProjectTabsProps) => {
  const { onSelectTab, tabList, uniqueId, classNames, activeIndex, projectStatus } = props;

  const onClickTab = (selectedTab: string) => {
    if (projectStatus === "Pending") {
      // Prevent selection of Token Management and Dividend Management tabs
      if (selectedTab === "Token Management" || selectedTab === "Dividend Management") {
        return;
      }
    } else if (projectStatus === "Accepted") {
      // Only allow Token Management to be clicked, prevent Dividend Management
      if (selectedTab !== "Token Management") {
        return;
      }
    } else if (projectStatus === "Listed") {
      // Allow any tab to be selected when project is listed
      onSelectTab(selectedTab);
    }
  };

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    document.head.appendChild(styleSheet);

    // Clear existing dynamic rules
    while (styleSheet.sheet && styleSheet.sheet.cssRules.length) {
      styleSheet.sheet.deleteRule(0);
    }

    // Add new dynamic rules
    tabList.forEach((item: any, index: number) => {
      const idx = index + 1;
      const checkedRule = `input[id="${uniqueId}-custom-test-${idx}"]:checked ~ .custom-tabs .custom-tab label[for="${uniqueId}-custom-test-${idx}"]::after`;
      const labelRule = `input[id="${uniqueId}-custom-test-${idx}"]:checked ~ .custom-tabs .custom-tab label[for="${uniqueId}-custom-test-${idx}"]`;
      if (styleSheet.sheet) {
        styleSheet.sheet.insertRule(`${checkedRule} { opacity: 1; }`, styleSheet.sheet.cssRules.length);
        styleSheet.sheet.insertRule(`${labelRule} { color: black; cursor: default; }`, styleSheet.sheet.cssRules.length);
      }
    });

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [tabList, uniqueId]);

  const hasActiveIndex = (index: number) => ({
    ...(activeIndex !== undefined ? { checked: index === activeIndex, readOnly: true } : { defaultChecked: index === 0 }),
  });

  return (
    <div className={`custom-wrapper ${classNames}`}>
      <div className="custom-tabs-panel overflow-x-scroll text-nowrap mb-[3px]">
        {tabList.map((item: any, index: number) => (
          <input {...hasActiveIndex(index)} key={index} type="radio" id={`${uniqueId}-custom-test-${index + 1}`} name={`${uniqueId}-custom-tab`} />
        ))}
        <div className="custom-tabs " style={{ width: "65%" }}>
          {tabList.map((item: any, index: number) => (
            <div
              key={index}
              className={`custom-tab  ${
                (projectStatus === "Pending" && (item === "Token Management" || item === "Dividend Management")) ||
                (projectStatus === "Accepted" && item === "Dividend Management")
                  ? "pointer-events-none opacity-50" // Disable click and make tab appear disabled
                  : ""
              }`}
              onClick={() => {
                onSelectTab(item);
                onClickTab(item);
              }}
            >
              <label htmlFor={`${uniqueId}-custom-test-${index + 1}`}>
                <div className="custom-content">
                  <div className="custom-text">{item}</div>
                </div>
              </label>
            </div>
          ))}
        </div>
        <div className="custom-separator"></div>
      </div>
    </div>
  );
};

export default ProjectTabs;
