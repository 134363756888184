import { useState, useEffect, ReactElement } from "react";
import RevenueQ3 from "./RevenueQ3";

interface ComponentType {
    title: string;
    component: ReactElement;
    actionNeed?: boolean;
}

interface ListType {
    [key: string]: ComponentType;
}

interface UserSettingType {
    list: ListType;
}

interface TokenManagementType {
    userSetting: UserSettingType;
}

interface ProjectDataType {
    contractTerm: number; // in months
    commercialOperationDate: string; // ISO date string
    revenueSharePercentage:number;
    fixedIntrestRatePercentage:number;
    fundingGoal:number;
    fundingReceived:number;
}

interface RevenueManagementProps {
    projectData: ProjectDataType;
    isLoading: boolean;
}

export default function RevenueManagement({ projectData, isLoading }: RevenueManagementProps) {
    const [tokenManagementComponents, setTokenManagementComponents] = useState<TokenManagementType>({
        userSetting: { list: {} },
    });

    const [selectedComponent, setSelectedComponent] = useState<string | null>(null);

    useEffect(() => {
        if (projectData) {
            console.log("Proejct data in revenue amangement: ",projectData)
            const { contractTerm, commercialOperationDate,revenueSharePercentage,fixedIntrestRatePercentage,fundingReceived ,fundingGoal} = projectData;
            const tokenId = projectData?.tokenIds[0]?._id;
            const startDate = new Date(commercialOperationDate);
            const numberOfQuarters = Math.ceil(contractTerm / 3);

            const generatedComponents: ListType = {};
            for (let i = numberOfQuarters - 1; i >= 0; i--){
                const quarter = (i % 4) + 1;
                const year = startDate.getFullYear() + Math.floor(i / 4);
                const key = `Q${quarter}_${year}`;

                generatedComponents[key] = {
                    title: `Q${quarter} ${year}`,
                    component: <RevenueQ3 quarter={quarter} year={year} tokenId={tokenId} revenueSharePercentage={revenueSharePercentage} fixedIntrestRatePercentage={fixedIntrestRatePercentage} fundingReceived={fundingReceived} fundingGoal={fundingGoal} />, // Pass quarter and year as props
                };
            }

            setTokenManagementComponents({
                userSetting: {
                    list: generatedComponents,
                },
            });

            // Set the first component as the selected component by default
            setSelectedComponent(Object.keys(generatedComponents)[0] || null);
        }
    }, [projectData]);

    // Fallback in case selectedComponent is not found
    const selectedComponentObject = selectedComponent
        ? tokenManagementComponents.userSetting.list[selectedComponent]
        : null;

        return (
          <div className="flex flex-col xl:flex-row divide-y xl:divide-y-0 xl:divide-x divide-monochrome-0 h-full">
            <div className="flex gap-4 flex-col md:flex-row xl:flex-col xl:w-[300px] p-[18px] xl:pb-0">
              {Object.keys(tokenManagementComponents.userSetting.list).map((innerKey, innerIndex) => (
                <div
                  key={innerIndex}
                  onClick={() => setSelectedComponent(innerKey)}
                  className={`flex justify-between items-center gap-4 py-3 px-4 body-regular rounded-lg cursor-pointer ${
                    innerKey === selectedComponent ? "bg-white border-white" : ""
                  }`}
                >
                  <span>{tokenManagementComponents.userSetting.list[innerKey].title}</span>
                  {tokenManagementComponents.userSetting.list[innerKey].actionNeed && <p className="rounded-full w-2 h-2 bg-danger-100"></p>}
                </div>
              ))}
            </div>
            <div className="pt-3 !xl:pt-0 xl:p-4 w-full">
                {selectedComponentObject ? selectedComponentObject.component : <p className="xl:py-72 sm:py-60 py-60 px-10 text-center">Please set contract term before revenue management</p>}
            </div>
          </div>
        );
    }
