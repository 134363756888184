import { numberWithCommas } from '@/constants/extensions';

type Props = {
  icon: JSX.Element;
  count: number;
  title: string;
}
const ProjectOverViewCard = (props: Props) => {
  return (<div className="bg-white p-4 flex flex-column rounded-lg gap-4">
    <div className="flex flex-column gap-4 items-start">
      {props.icon}
      <div className='flex flex-col gap-2'>
      <h2>{numberWithCommas(props.count)}</h2>
      <span className='text-monochrome-30 body-small-regular'>{props.title}</span>
      </div>
    </div>
  </div>)
}

export default ProjectOverViewCard;