const IcProjectCompleted = (props: any) => {
    return (
        <div {...props}>
            <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.333374" width="28" height="28" rx="8" fill="#E8FFF9" />
                <path d="M8.55554 13.5716L12.2078 18.1433L19.5275 9" stroke="#00C798" strokeWidth="2.74298" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    )
}

export default IcProjectCompleted