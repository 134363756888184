import { ROUTES } from ".";
import { IcContactSupport, IcDashboard, IcFillContactSupport, IcFillDashboard, IcFillProjects, IcFillSettings, IcFillTransactions, IcProjects, IcSetting, IcTransactions } from "@/assets/menu";

export const NavBarList = [
    {
      title: 'Dashboard',
      icon: <IcDashboard />,
      iconWhenSelected: <IcFillDashboard />,
      link: ROUTES.DASHBOARD
    },
    {
      title: 'Projects',
      icon: <IcProjects />,
      iconWhenSelected: <IcFillProjects />,
      link: ROUTES.PROJECTS
    },
    {
      title: 'Transactions',
      icon: <IcTransactions/>,
      iconWhenSelected: <IcFillTransactions />,
      link: ROUTES.TRANSACTIONS
    },
    {
      title: 'Contact Support',
      icon: <IcContactSupport />,
      iconWhenSelected: <IcFillContactSupport />,
      link: ROUTES.SUPPORT
    }, {
      title: 'Settings',
      icon: <IcSetting />,
      iconWhenSelected: <IcFillSettings />,
      link: ROUTES.SETTINGS
    }
  ]