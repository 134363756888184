import { IcDropdownChecked } from "@/assets/menu";
import Button from "@/components/UI/Button";
import { Modal } from "flowbite-react";

interface GetCapitalModalProps {
    show: boolean;
    handleClose: () => void;
    handleProjectDetails: () => void;
}
const GetCapitalSucessModal = ({ show, handleClose ,handleProjectDetails}: GetCapitalModalProps) => {
    return (
        <Modal show={show} onClose={handleClose} position={'center'} size={'2xl'}>
            <Modal.Body className="p-4">
                <section className="p-4 flex flex-col gap-7 items-center">
                    <IcDropdownChecked className={'h-10 w-10 bg-green-200 flex items-center justify-center rounded-full !z-50'}></IcDropdownChecked>
                    <div className="flex flex-col gap-2 items-center">
                        <h3>Submission Successful</h3>
                        <p className="text-sm w-[70%] body-small-regular text-center">Thank you for submitting your project! Our team will review your submission and get back to you as soon as possible.</p>
                        <br />
                        {/* <p>Your next step is to complete the project details form.</p>
                         */}
                    </div>
                    <Button primary rounded onClick={handleProjectDetails} classNames="w-full">Complete Project Details</Button>
                </section>
            </Modal.Body>
        </Modal>
    )
}

export default GetCapitalSucessModal;