import { useState, ReactElement, useEffect } from "react";
import HoldersList from "./HoldersList";
import PurchaseRequest from "./PurchaseRequest";
import Listing from "./Listing";

interface ComponentType {
  title: string;
  component: ReactElement;
  actionNeed?: boolean;
}

interface ListType {
  listing: ComponentType;
  purchaseRequest: ComponentType;
  holders: ComponentType;
}

interface UserSettingType {
  list: ListType;
}

interface TokenManagementType {
  userSetting: UserSettingType;
}

export default function TokenManagement({ projectData, isLoading = false, refetch, initialSubTab }: any) {
  const [tokenManagementComponents] = useState<TokenManagementType>({
    userSetting: {
      list: {
        listing: {
          title: "Listing",
          component: <Listing projectData={projectData} refetch={refetch} />,
        },
        purchaseRequest: {
          title: "Purchase Requests",
          component: <PurchaseRequest projectData={projectData} />,
        },
        holders: {
          title: "Holders",
          component: <HoldersList projectData={projectData} />,
        },
      },
    },
  });

  const [selectedComponent, setSelectedComponent] = useState({
    link: "listing",
    components: tokenManagementComponents.userSetting.list.listing.component,
  });

  useEffect(() => {
    if (initialSubTab && tokenManagementComponents.userSetting.list[initialSubTab]) {
      setSelectedComponent({
        link: initialSubTab,
        components: tokenManagementComponents.userSetting.list[initialSubTab].component,
      });
    }
  }, [initialSubTab, tokenManagementComponents]);

  return (
    <div className="grid xl:grid-cols-5 divide-y xl:divide-y-0 xl:divide-x divide-monochrome-0 h-full w-full">
      <div className="w-fit xl:w-full xl:col-span-1 flex gap-4 flex-row xl:flex-col p-[18px] xl:pb-0">
        {Object.keys(tokenManagementComponents.userSetting.list).map((innerKey, innerIndex) => (
          <div
            key={"inner-" + innerIndex}
            onClick={() => {
              // Disable Purchasing Requests and Holders when project.status is Accepted
              if (projectData?.status === "Accepted" && (innerKey === "purchaseRequest" || innerKey === "holders")) {
                return; // Prevent clicking
              } else {
                setSelectedComponent({
                  link: innerKey,
                  components: tokenManagementComponents.userSetting.list[innerKey as keyof ListType].component,
                });
              }
            }}
            className={`py-3 px-4 body-regular rounded-lg cursor-pointer ${innerKey === selectedComponent.link ? "bg-white border-white" : ""} ${
              projectData?.status === "Accepted" && (innerKey === "purchaseRequest" || innerKey === "holders")
                ? "pointer-events-none opacity-50" // Disable interaction and visually indicate disabled state
                : ""
            }`}
          >
            <span>{tokenManagementComponents.userSetting.list[innerKey as keyof ListType].title}</span>
            {tokenManagementComponents.userSetting.list[innerKey as keyof ListType].actionNeed && <p className="rounded-full w-2 h-2 bg-danger-100"></p>}
          </div>
        ))}
      </div>
      <div className="col-span-1 xl:col-span-4 pt-3 !xl:pt-0 xl:p-4 w-full">{selectedComponent.components}</div>
    </div>
  );
}
