import React from "react";

const IcFillTransactions = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.763 2.5C16.6371 2.5 18.3334 4.15383 18.3334 6.98483H14.8074V7.01372C13.171 7.01372 11.8445 8.30707 11.8445 9.9025C11.8445 11.4979 13.171 12.7913 14.8074 12.7913H18.3334V13.0513C18.3334 15.8462 16.6371 17.5 13.763 17.5H6.23706C3.36298 17.5 1.66669 15.8462 1.66669 13.0513V6.94872C1.66669 4.15383 3.36298 2.5 6.23706 2.5H13.763ZM17.7111 8.22701C18.0548 8.22701 18.3334 8.49861 18.3334 8.83365V10.9425C18.3294 11.2759 18.0531 11.5452 17.7111 11.5491H14.8741C14.0457 11.56 13.3213 11.007 13.1334 10.2203C13.0393 9.73191 13.1714 9.22797 13.4942 8.84351C13.8171 8.45906 14.2978 8.2334 14.8074 8.22701H17.7111ZM15.2074 9.20197H14.9334C14.7651 9.20004 14.603 9.26387 14.4833 9.3792C14.3637 9.49453 14.2963 9.65177 14.2963 9.81584C14.2963 10.16 14.5803 10.4402 14.9334 10.4441H15.2074C15.5593 10.4441 15.8445 10.1661 15.8445 9.82306C15.8445 9.48004 15.5593 9.20197 15.2074 9.20197ZM10.3185 5.74266H5.61484C5.26588 5.74263 4.98186 6.01633 4.9778 6.35652C4.9778 6.70073 5.26181 6.98088 5.61484 6.98483H10.3185C10.6704 6.98483 10.9556 6.70676 10.9556 6.36375C10.9556 6.02073 10.6704 5.74266 10.3185 5.74266Z"
        fill="#00C798"
      />
    </svg>
  );
};

export default IcFillTransactions;
