import { useState } from "react";
import Loader from "./Loader";
import { IcTotalFinancingReceived } from "@/assets/Icons";
import { useQuery } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import { numberWithCommas } from "@/constants/extensions";
import { fetchTotalFinancingReceived } from "@/services/api";
interface BalanceCardProps {
  balance: number;
}

const BalanceCard = () => {

  const { companyId } = useAuth();

  const { data: totalFinancingReceived = [], isLoading } = useQuery({
    queryKey: ["getTotalFinancingReceived", companyId],
    queryFn: () => fetchTotalFinancingReceived(companyId),
    enabled: !!companyId,
    select: (data) => data.data
  });
  console.log("TotalFinancing Recevied: ",totalFinancingReceived)
  
  return (
    <div className="bg-white p-4 grow flex flex-column rounded-lg">
      <div className="flex flex-row gap-4 items-start">
        <IcTotalFinancingReceived />
        <div className="flex flex-column gap-2">
          <span className="body-small-regular text-monochrome-30">Total Financing Received</span>
          {
            isLoading ? <Loader isLoading={isLoading} /> : <h1>{numberWithCommas(totalFinancingReceived.totalFinancing)} <span className="text-monochrome-30 leading-6 text-base">USDC</span></h1>
          }
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;
