import "./detailStyles/general.css";

import useAuth from "@/hooks/useAuth";
import GeneralDetails from "./Sections/GeneralDetails";
import AssetDetails from "./Sections/AssetDetails";
import ValuationDetail from "./Sections/ValuationDetail";
import FinancingDetails from "./Sections/FinancingDetails";
import DocumentsDetails from "./Sections/DocumentsDetails";

export default function DetailsCards({ refs, data, refetch }: any) {
  
  useAuth();
  const { Documents, Financing, Valuation, General, Asset } = refs;

  const isInReviewForListing = data?.isInReviewForListing ?? false;

  return (
    <div className="flex flex-col gap-4">
      <div className="general-card h-auto" ref={General} data-section="General">
        <GeneralDetails data={data} refetch={refetch} />
      </div>

      <div className="general-card" ref={Asset} data-section="Asset">
        <AssetDetails data={data} refetch={refetch} isInReviewForListing={isInReviewForListing} />
      </div>

      <div className="general-card" ref={Valuation} data-section="Valuation">
        <ValuationDetail data={data} refetch={refetch} isInReviewForListing={isInReviewForListing}/>
      </div>

      <div className="general-card" ref={Financing} data-section="Financing">
        <FinancingDetails data={data} refetch={refetch} isInReviewForListing={isInReviewForListing} />
      </div>

      <div className="general-card" ref={Documents} data-section="Documents">
        <DocumentsDetails data={data} refetch={refetch} isInReviewForListing={isInReviewForListing} />
      </div>
    </div>
  );
}
