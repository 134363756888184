import React from "react";

const IcFillContactSupport = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2136_1359)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.98143 2.14362C9.38424 2.13283 8.79078 2.23992 8.23502 2.45875C7.67926 2.67758 7.17211 3.00386 6.74259 3.41892C6.31308 3.83397 5.96962 4.32965 5.73189 4.87759C5.49416 5.42553 5.36681 6.01498 5.35714 6.61219V12.8579C5.35714 13.2368 5.20663 13.6001 4.93872 13.8681C4.67081 14.136 4.30745 14.2865 3.92857 14.2865H2.14286C1.57454 14.2865 1.02949 14.0607 0.627628 13.6588C0.225765 13.257 0 12.7119 0 12.1436L0 9.28647C0 8.71815 0.225765 8.17311 0.627628 7.77124C1.02949 7.36938 1.57454 7.14362 2.14286 7.14362H3.21429V6.58647C3.22732 5.70846 3.41319 4.84162 3.76128 4.03545C4.10936 3.22929 4.61285 2.49959 5.24298 1.88803C5.87311 1.27647 6.61755 0.795029 7.43376 0.471209C8.24998 0.147388 9.12199 -0.0124718 10 0.00075902C10.878 -0.0124718 11.75 0.147388 12.5662 0.471209C13.3825 0.795029 14.1269 1.27647 14.757 1.88803C15.3872 2.49959 15.8906 3.22929 16.2387 4.03545C16.5868 4.84162 16.7727 5.70846 16.7857 6.58647V7.14362H17.8571C18.4255 7.14362 18.9705 7.36938 19.3724 7.77124C19.7742 8.17311 20 8.71815 20 9.28647V12.1436C20 12.7119 19.7742 13.257 19.3724 13.6588C18.9705 14.0607 18.4255 14.2865 17.8571 14.2865H16.7857V15.0008C16.7859 15.9661 16.4306 16.8978 15.7877 17.6179C15.1448 18.338 14.2592 18.7962 13.3 18.905C13.1137 19.2376 12.8421 19.5145 12.5131 19.7071C12.1842 19.8997 11.8098 20.0011 11.4286 20.0008H9.28571C8.71739 20.0008 8.17235 19.775 7.77049 19.3731C7.36862 18.9713 7.14286 18.4262 7.14286 17.8579C7.14286 17.2896 7.36862 16.7445 7.77049 16.3427C8.17235 15.9408 8.71739 15.715 9.28571 15.715H11.4286C12.2029 15.715 12.8814 16.125 13.2571 16.7408C13.6509 16.6503 14.0023 16.4289 14.254 16.1129C14.5057 15.7968 14.6428 15.4048 14.6429 15.0008V6.61076C14.6331 6.01047 14.5044 5.41808 14.2642 4.86783C14.0241 4.31759 13.6773 3.82039 13.2438 3.405C12.8104 2.98961 12.2989 2.66425 11.7389 2.44774C11.1789 2.23123 10.5816 2.12787 9.98143 2.14362Z"
          fill="#00C798"
        />
      </g>
      <defs>
        <clipPath id="clip0_2136_1359">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcFillContactSupport;
