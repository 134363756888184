import IcBuy from "@/assets/ic_buy";
import IcCross from "@/assets/ic_cross";
import IcPending from "@/assets/Icons/ic_pending";
import IcSell from "@/assets/ic_sell";
import { IcDropdownChecked } from "@/assets/menu";
import { ITransaciton } from "@/types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { formatNumberWithCommas } from "@/utils/commanFunctions";

dayjs.extend(customParseFormat);

interface ITransactionItemProps {
  item: ITransaciton;
  onClick: () => void;
  isSelected: boolean;
  uniqueId: string;
  currentUserId: string;
  currentWalletAddress: string;
}

const TransactionItem: React.FC<ITransactionItemProps> = ({ 
  item, 
  onClick, 
  isSelected, 
  uniqueId, 
  currentUserId, 
  currentWalletAddress 
}) => {
  if (!item || !item.updatedAt || item.paymentTokensTransferred === undefined) {
    return null;
  }

  const parsedDate = dayjs(item.updatedAt);
  const isOnChain = item.transactionMethod === "ON_CHAIN";

  const isIncomingTransaction = () => {
    if (isOnChain) {
      return (item.toAddress?.toLowerCase() || "") === (currentWalletAddress?.toLowerCase() || "");
    }
    return item.toUserId?._id === currentUserId;
  };

  const isOutgoingTransaction = () => {
    if (isOnChain) {
      return (item.fromAddress?.toLowerCase() || "") === (currentWalletAddress?.toLowerCase() || "");
    }
    return item.fromUserId?._id === currentUserId;
  };

  const incoming = isIncomingTransaction();
  const outgoing = isOutgoingTransaction();
  
  const transactionType = incoming ? "Buy" : "Sell";
  const transactionColor = incoming ? "text-green-500" : outgoing ? "text-red-600" : "text-gray-400";
  const transactionSign = incoming ? "+" : outgoing ? "-" : "";

  const getTransactionName = () => {
    switch (item.transactionPurpose) {
      case "SECURITY_TOKEN_PURCHASE": return "Security Token Purchase";
      case "DIVIDEND_PAYMENT": return "Dividend Payment";
      case "SECURITY_TOKEN_SALE": return "Sell Token";
      case "SECURITY_TOKEN_TRANSFER": return "Security Token Transfer";
      case "PAYMENT_TOKEN_TOP_UP": return "Top Up";
      case "PAYMENT_TOKEN_WITHDRAW": return "Withdraw";
      default: return "Transaction";
    }
  };

  const getStatusIcon = () => {
    switch (item.status) {
      case "FAILED": return <IcCross className="!text-red-500 transform scale-110" />;
      case "COMPLETED": return <IcDropdownChecked className="h-[16px] w-[16px]" />;
      case "PENDING": return <IcPending className="!text-yellow-300" />;
      default: return null;
    }
  };

  return (
    <div
      className={`grid grid-cols-5 py-4 text-monochrome-60 border-b cursor-pointer border-monochrome-10 ${
        isSelected ? "bg-gray-100" : "bg-white"
      }`}
      onClick={onClick}
      data-id={uniqueId}
    >
      <span className="flex items-center gap-5">
        {transactionType === "Buy" ? (
          <IcBuy className="text-green-500" />
        ) : (
          <IcSell className="text-red-500" />
        )}
        <span className="ml-2 text-xs">{getTransactionName()}</span>
      </span>
      <span className="text-end text-xs">{formatNumberWithCommas(item.paymentTokenName) || "USD"}</span>
      <span className="text-end text-xs">{parsedDate.format("MMM D, YYYY")}</span>
      <span className="text-end text-xs">{parsedDate.format("hh:mm A")}</span>
      <span className={`text-end text-xs ${transactionColor}`}>
        {transactionSign}
        <span>${Math.abs(item.paymentTokensTransferred).toFixed(2)}</span>
      </span>
    </div>
  );
};

export default TransactionItem;