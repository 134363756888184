import { formatNumberWithCommas } from "@/utils/commanFunctions";

function FundingRecievedBanner({ projectData, refetch }: any) {
    if (!projectData) {
        console.error("Project data is undefined in FundingRecievedBanner component.");
        return null;
    }

    console.log("Project Data in Funding Received: ", projectData);

    const fundingGoal = projectData.fundingGoal || 0;
    const fundingReceived = projectData.fundingReceived || 0;
    const fundingPercentage = (fundingReceived / fundingGoal) * 100;

    return (
        <div className="bg-white rounded-lg p-4 shadow-md w-full">
            <div className="block sm:flex justify-between items-start sm:items-center mb-4">
                <div className="block sm:flex items-start">
                    <div className="bg-[#E8FFF9] rounded-[8px] px-2 py-2 mr-2 w-10 h-10 mb-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-[#E8FFF9]"
                            viewBox="0 0 20 20"
                            fill="#00c798"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4 4a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1V8z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </div>
                    <div className="block sm:flex flex-col gap-[6px]">
                        <h2 className="body-small-regular text-monochrome-30">Funding Received</h2>
                        <div className="flex items-center">
                            <h2 className="text-[0.999rem] sm:text-3xl text-gray-800">
                                {fundingReceived && formatNumberWithCommas(String(fundingReceived ?? 0))}
                                <span className="text-monochrome-30 ml-0 sm:ml-2 font-medium text-base">&nbsp; USD</span>
                            </h2>
                           
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col gap-[6px] mr-1rem">
                        <h2 className="body-small-regular text-monochrome-30">Funding Goal</h2>
                        <div className="flex items-center">
                            <h2 className="text-[0.999rem] sm:text-3xl text-gray-800">
                                {fundingGoal && formatNumberWithCommas(String(fundingGoal ?? 0))}
                                <span className="text-monochrome-30 ml-0 sm:ml-2 font-medium text-base">&nbsp;USD</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col gap-[6px]">
                        <h2 className="body-small-regular text-monochrome-30">Funding Received Percentage</h2>
                        <div className="flex items-center">
                            <h2 className="text-[0.999rem] sm:text-3xl font-bold text-gray-800">
                                {fundingPercentage.toFixed(2)}%
                            </h2>
                            {/* <span className="text-gray-500 ml-0 sm:ml-2">%</span> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-gray-300 rounded-full h-4">
                <div
                    className="bg-green-500 rounded-full h-4"
                    style={{ width: `${fundingPercentage}%`, transition: "all 1s" }}
                ></div>
            </div>
            <div className="flex justify-between mt-2 text-xs text-gray-500">
                <span>0%</span>
                <span>100%</span>
            </div>
        </div>
    );
}

export default FundingRecievedBanner;
