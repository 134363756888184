import { useState, useEffect } from 'react'
import Details from '@/components/Projects/Details'
import Overview from '@/components/Projects/Overview'
// import RevenueManagement from '@/components/Projects/RevenueManagement'
import ProjectTabs from '@/components/Projects/Tabs/Tabs'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchProjectById } from '@/services/api';
import TokenManagement from '@/components/Projects/TokenManagement';
import RevenueManagement from '@/components/Projects/RevenueManagement';
// import TokenManagement from '@/components/Projects/TokenManagement'

export default function OverviewPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("projectId") || "";
    const tabParam = searchParams.get("tab") || "Overview";
    const subTabParam = searchParams.get("subTab") || "";

    const { data: projectData, isLoading, refetch } = useQuery({
        queryKey: ["fetchProjectById", projectId],
        // queryKey: ["fetchProjectById", "6717402e2adcf4343acc8c99"],
        queryFn: () => fetchProjectById(projectId),
        // queryFn: () => fetchProjectById("6717402e2adcf4343acc8c99"),
        enabled: !!projectId,
        select: (res) => res.data
    });
    const [currentTab, setCurrentTab] = useState(tabParam);
    const [currentSubTab, setCurrentSubTab] = useState(subTabParam);

    // Update the current tab and sub-tab whenever the URL changes
    useEffect(() => {
        setCurrentTab(tabParam);
        setCurrentSubTab(subTabParam);
    }, [tabParam, subTabParam]);

    const tabList = ["Overview", "Due Diligence", "Token Management", "Dividend Management"];
    const activeIndex = tabList.indexOf(currentTab);

    const handleTabSelect = (tab: string) => {
        
        setCurrentTab(tab);
        setCurrentSubTab("");
        navigate(`/projects/overview?projectId=${projectId}&tab=${tab}`);
    };

    function SelectedTab() {
        if (currentTab === "Overview") {
            return <Overview projectData={projectData} isLoading={isLoading} />
        } else if (currentTab === "Due Diligence") {
            return <Details projectData={projectData} isLoading={isLoading} refetch={refetch} />
        }
        else if (currentTab === "Token Management") {
            return <TokenManagement projectData={projectData} isLoading={isLoading} refetch={refetch} initialSubTab={currentSubTab} />
        } else if (currentTab === "Dividend Management") {
            return <RevenueManagement projectData={projectData} isLoading={isLoading} />
        }
    }

    return (
        <div className='w-full'>
            <ProjectTabs onSelectTab={handleTabSelect} tabList={tabList} uniqueId="main-tab-component"  activeIndex={activeIndex} projectStatus={projectData?.status} />
            <SelectedTab  />
        </div>
    )
}
