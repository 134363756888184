import React, { useState } from 'react';
import { numberWithCommas } from "@/constants/extensions";
import { http } from "@/services/http"; // Assuming http service is configured to handle PATCH requests
import toast from "react-hot-toast";
import { ENDPOINT } from '@/constants';
import { useQueryClient } from '@tanstack/react-query';
import { FaCheck, FaPencilAlt } from 'react-icons/fa';
import { GrFormClose } from "react-icons/gr";

const ProjectDashboardItem = ({ item,handleClickProject }: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showPencil, setShowPencil] = useState(false);
    const [updatedName, setUpdatedName] = useState(item.name);
    const queryClient = useQueryClient();

    const handleDoubleClick = () => {
        setIsEditing(true);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedName(e.target.value);
    };

    const handleSave = async () => {
        try {
            if (updatedName !== item.name) {
                const response = await http.patch(`${ENDPOINT.PROJECT}/${item._id}`, { name: updatedName });
                if (response.status === 200 && response.data) {
                    toast.success("Project name updated successfully.");
                    queryClient.invalidateQueries('getProjects'); // Invalidate the projects query to refetch the data
                } else {
                    toast.error("Failed to update project name.");
                }
            }
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating project:', error);
            toast.error("Failed to update project name.");
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSave();
        } else if (e.key === 'Escape') {
            setUpdatedName(item.name);
            setIsEditing(false);
        }
    };

    return (
        <div key={item._id} className="grid grid-cols-4 py-3 border-b border-b-monochrome-10 hover:bg-gray-100 cursor-pointer">
            <div className="col-span-1 z-10 flex gap-2 text-black sm:gap-4 text-sm items-center font-medium" onMouseEnter={() => setShowPencil(true)} onMouseLeave={() => setShowPencil(false)}>
                {isEditing ? (
                    <>
                        <span className="flex items-center gap-2">
                            <input
                                type="text"
                                value={updatedName}
                                onChange={handleNameChange}
                                onKeyDown={handleKeyDown}
                                autoFocus
                                className="border-b body-medium border-gray-400 focus:outline-none w-44"
                            />
                            <FaCheck className={`cursor-pointer hover:text-blue-500`} onClick={handleSave}/>
                            <GrFormClose size={22} className={`cursor-pointer hover:text-red-500`} onClick={() => setIsEditing(false)}/>
                        </span>
                    </>
                ) : ( 
                    <span className='body-medium flex gap-2 items-center'>
                        {item.name}
                        <FaPencilAlt className={`cursor-pointer ${showPencil ? "block" : "hidden"}`} onClick={handleDoubleClick} />
                    </span>
                )} 
            </div>
            <span onClick={()=> handleClickProject(item)} className="text-monochrome-60 body-regular text-end my-auto">{numberWithCommas(item.fundingReceived ?? 0)} USD</span>
            <span onClick={()=> handleClickProject(item)} className="text-monochrome-60 body-regular text-end my-auto">{numberWithCommas(item.fundingGoal)} USD</span>
            <div  onClick={()=> handleClickProject(item)} className="text-monochrome-60 body-regular flex justify-end items-center my-auto">
                <div className={`bg-status-10 rounded-lg py-1 px-3 caption-medium flex justify-end items-center ml-auto ${item.status ? `project-status-${item.status?.toLowerCase()}` : ''}`}>
                    <div className={`rounded-full w-2 h-2 mr-2`}></div>
                    {item.status || "Unknown Status"}
                </div>
            </div>
        </div>
    );
};

export default ProjectDashboardItem;
