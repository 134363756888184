import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { ROUTES } from "@/constants";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { authToken } = useContext(AuthContext);

  if (!authToken) {
    return <Navigate to={ROUTES.APP_ROOT} />;
  }

  return children;
};

export default ProtectedRoute;
