import { Modal } from 'flowbite-react'

export default function ProjectModal({ open, onClose, children }: any) {
    return (
        <Modal show={open} onClose={() => { onClose(false) }} position={'center'} size={'2xl'}>
            <Modal.Body className="p-6">
                {children}
            </Modal.Body>
        </Modal>
    )
}
