import { removeCommas } from "@/utils/commanFunctions";
import React, { useEffect, useRef, useState } from "react";
import { defaultState } from "../DetailConstant";
import Dropdown from "@/components/UI/Dropdown/Dropdown";
import ReactFlagsSelect from "react-flags-select";
import countryList from "react-select-country-list";
import Button from "@/components/UI/Button";
import Loader from "@/components/Loader";
import { removeProjectImage, updateProjectDetails, updateProjectImage } from "@/services/api";
import ConfirmationModal from "../ConfirmationModal";
import ImageCropper from "../ImageCropper";
import axios from "axios";
import { http } from "@/services/http";
import { ENDPOINT } from "@/constants";
import { Tooltip } from "flowbite-react";
import { FaInfoCircle } from "react-icons/fa";

type DefaultStateKeys = keyof typeof defaultState;

const CustomTooltip = ({ content }: { content: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <FaInfoCircle className="text-gray-400 cursor-pointer ml-1" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} />
      {isVisible && (
        <div className="min-w-[300px] absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48">
          {content}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

const GeneralDetails = ({ data, refetch }: any) => {
  const {
    name = "",
    projectDescription = "",
    websiteLink = "",
    spvLocation = "",
    assetLocation = "",
    hasRevenueGeneratingAssets = false,
    projectImage = "",
  } = { ...data };

  const [isEditMode, setIsEditMode] = useState({
    general: false,
  });
  const [isUpdating, setUpdating] = useState(false);
  const [details, setDetail] = useState({
    general: {
      name: "",
      projectDescription: "",
      websiteLink: "",
      spvLocation: "",
      assetLocation: "",
      hasRevenueGeneratingAssets: false,
      projectImage: "",
    },
  });

  const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
    <div className="label flex items-center">
      {label}
      <CustomTooltip content={tooltip} />
    </div>
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setDetail({
      general: {
        name,
        projectDescription,
        websiteLink,
        spvLocation,
        assetLocation,
        hasRevenueGeneratingAssets,
        projectImage,
      },
    });
  }, [data]);

  const handleChangeDetails = async (evt: any, changingTo: any, isFundingUpdateIndex?: number) => {
    const { name, value } = evt.target;

    // List of names to check against
    const namesToCheck = [
      "operationLifetime",
      "totalInitialCost",
      "annualOperationCosts",
      "npv",
      "irr",
      "lcoe",
      "paybackPeriod",
      "capacityFactor",
      "fundingGoal",
      "tokenPrice",
      "tokenAmount",
      "contractTerm",
      "amount",
    ];

    // Replace commas only if the name is in the list
    const processedValue = namesToCheck.includes(name) && typeof value === "string" ? removeCommas(value) : value;

    if (!isUpdating) {
      setDetail((prevState: any) => {
        return {
          ...prevState,
          [changingTo]: {
            ...prevState[changingTo],
            [name]: processedValue,
          },
        };
      });
    }
  };

  const handleClickCancleOrEdit = async (clickedTo: DefaultStateKeys) => {
    setIsEditMode((prevState: any) => ({ ...prevState, [clickedTo]: !prevState[clickedTo] }));
    const generalPrevState: any = {
      name,
      projectDescription,
      spvLocation,
      assetLocation,
      hasRevenueGeneratingAssets,
    };

    setDetail((prevState: any) => {
      return {
        ...prevState,
        [clickedTo]: {
          ...generalPrevState,
        },
      };
    });
  };

  const handleUpdateDetails = async () => {
    setUpdating(true);
    const updatedDetails: any = { ...data, ...details["general"] };
    await updateProjectDetails(data._id, updatedDetails);
    await refetch();
    setUpdating(false);
    setShowConfirmModal(false);
  };

  const handleSaveClick = () => {
    setShowConfirmModal(true);
  };

  const [whatToShow, setWhatToShow] = useState("uploadBtn");
  const selectImageRef = useRef<HTMLInputElement>(null);
  const [imgSrc, setImgSrc] = useState("");
  const source = useRef(axios.CancelToken.source());

  const handleSaveImage = async (fileObject: any) => {
    if (fileObject) {
      setUpdating(true);
      const formData = new FormData();
      formData.append("file", fileObject);

      try {
        await http.patch(`${ENDPOINT.PROJECT}/uploadProjectImage/${data._id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          cancelToken: source.current.token,
        });
        await refetch();
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Upload canceled:", error.message);
        } else {
          console.error("Error submitting data:", error);
        }
      } finally {
        setUpdating(false);
      }
    }
  };

  const handleCancelUpload = () => {
    source.current.cancel("Image upload canceled by the user.");
    setUpdating(false);

    setTimeout(() => {
      removeImageFromProjectOnCancellationOfRequest();
    }, 2000);
  };

  const removeImageFromProjectOnCancellationOfRequest = async () => {
    await removeProjectImage(data._id);
    refetch();
  };

  console.log("------", details.general.projectImage);

  return (
    <>
      <div className="general-card-section">
        <h2 className="text-[14px]">General</h2>
        <p className="text-[11px] ">Project overview information</p>
        <div className="h-[82%] flex items-center justify-center mt-4">
          {details.general.projectImage ? (
            <div className="mt-4">
              <div className="mb-1 text-[11px]">Project Image</div>
              <img src={details.general.projectImage} alt="project image" className="w-full object-cover" />
            </div>
          ) : !imgSrc ? (
            <div className="flex flex-col gap-3">
              {data?.projectImage && <img src={data?.projectImage} alt="project image" className="w-full object-cover" />}
              {!data?.projectImage && <p className="text-xs">Please Upload Project Image by clicking on Edit</p>}

              {isEditMode.general && (
                <Button primary rounded onClick={() => selectImageRef.current?.click()} classNames="bg-primary">
                  Upload project image
                </Button>
              )}
            </div>
          ) : (
            ""
          )}
          <ImageCropper
            selectImageRef={selectImageRef}
            imgSrc={imgSrc}
            setImgSrc={setImgSrc}
            handleSaveImage={handleSaveImage}
            isUpdating={isUpdating}
            handleCancelUpload={handleCancelUpload}
          />
        </div>
      </div>
      <div className="vertical-line"></div>
      <div className="general-card-section text-[11px]">
        <div className="field">
          <LabelWithTooltip label="Project Name" tooltip="Enter the official name of your project" />
          <input
            type="text"
            name="name"
            disabled={!isEditMode.general}
            value={details.general.name}
            onChange={(evt) => handleChangeDetails(evt, "general")}
            placeholder="Solar Plant Berlin"
            className={`${isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
          />
        </div>
        <div className="field">
          <LabelWithTooltip label="Website link" tooltip="Enter the URL of your project's official website" />
          <input
            type="text"
            name="websiteLink"
            disabled={!isEditMode.general}
            value={details.general.websiteLink}
            onChange={(evt) => handleChangeDetails(evt, "general")}
            placeholder="Company Website URL"
            className={`${isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
          />
        </div>
        <div className="field">
          <LabelWithTooltip label="Description" tooltip="Provide a brief description of your project" />
          <textarea
            value={details.general.projectDescription}
            disabled={!isEditMode.general}
            onChange={(evt) => handleChangeDetails(evt, "general")}
            name="projectDescription"
            placeholder="Description"
            style={{ resize: isEditMode.general ? "vertical" : "none" }}
            className={`${
              isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
            } px-0 w-full text-ternary text-sm placeholder-sm !text-[11px]`}
            rows={isEditMode.general ? 4 : 1}
          />
        </div>
        <div className="field">
          <LabelWithTooltip label="Is this asset already generating revenue?" tooltip="Select whether your project is currently generating revenue" />
          <Dropdown
            menuBoxMarginTop={5}
            disabled={!isEditMode.general}
            value={details.general.hasRevenueGeneratingAssets.toString()}
            className={`${
              isEditMode.general ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
            } px-0 w-full text-ternary text-sm`}
            onChange={(selectedValue: any) => {
              const evt = { target: { name: "hasRevenueGeneratingAssets", value: selectedValue.value } };
              handleChangeDetails(evt, "general");
            }}
            options={[
              { value: "true", label: "Yes" },
              { value: "false", label: "No" },
            ]}
          />
        </div>
        <div className="block flex-wrap items-center justify-between">
          <div className="field w-[100%] ">
            <LabelWithTooltip label="Physical location of revenue-generating asset" tooltip="Select the country where your asset is physically located" />
            <div className="value">
              <ReactFlagsSelect
                searchable={true}
                searchPlaceholder="Search location"
                placeholder="Select location"
                className={`${isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
                selectButtonClassName={`border-t-0 border-l-0 border-r-0 !rounded-none bg-transparent ${
                  isEditMode.general ? "border-b-0" : "border-b"
                }  border-gray-300 hover:bg-transparent body-small-regular`}
                optionsSize={12}
                selectedSize={12}
                disabled={!isEditMode.general}
                selected={countryList().labelMap[details.general.assetLocation?.toLocaleLowerCase()]}
                onSelect={(code) => {
                  const evt = { target: { name: "assetLocation", value: countryList().getLabel(code) } };
                  handleChangeDetails(evt, "general");
                }}
              />
            </div>
          </div>
          <div className="field w-[100%] ">
            <LabelWithTooltip
              label="SPV Location of revenue-generating asset (if existent)"
              tooltip="Select the country where your Special Purpose Vehicle (SPV) is located, if applicable"
            />
            <div className="value">
              <ReactFlagsSelect
                searchable={true}
                searchPlaceholder="Search location"
                placeholder="Select location"
                className={`${isEditMode.general ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
                selectButtonClassName={`border-t-0 border-l-0 border-r-0 !rounded-none bg-transparent ${
                  isEditMode.general ? "border-b-0" : "border-b"
                } border-gray-300 hover:bg-transparent body-small-regular`}
                optionsSize={12}
                selectedSize={12}
                disabled={!isEditMode.general}
                selected={countryList().labelMap[details.general.spvLocation?.toLocaleLowerCase()]}
                onSelect={(code) => {
                  const evt = { target: { name: "spvLocation", value: countryList().getLabel(code) } };
                  handleChangeDetails(evt, "general");
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 justify-end">
          <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("general")}>
            {isEditMode.general ? "Cancel" : "Edit"}
          </Button>
          {isEditMode.general && (
            <Button disabled={isUpdating} secondary rounded classNames={`h-10`} onClick={handleSaveClick}>
              {isUpdating ? <Loader isLoading={true} /> : "Save"}
            </Button>
          )}
        </div>
      </div>

      <ConfirmationModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleUpdateDetails}
        message="Are you sure you want to update the general details of your project?"
      />
    </>
  );
};

export default GeneralDetails;
