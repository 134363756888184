import { numberWithCommas } from "@/constants/extensions";

const InvestorPurchaseItems = ({ item }: any) => {
  const status = item.isPurchaseRequestAccepted === true ? "Accepted" : "Not Accepted";
  return (
    <div key={item._id} className="grid grid-cols-4 py-3 border-b border-b-monochrome-10">
      <div className="z-10 flex gap-2 text-black sm:gap-4 text-sm items-start font-medium">
        <span className="body-medium flex gap-2 items-center">{item.investorId.userId.firstName + " " + item.investorId.userId.lastName}</span>
      </div>
      <span className="text-monochrome-60 body-regular text-start my-auto">{numberWithCommas(item.tokenAmount * 500 ?? 0)} USD</span>
      <span className="text-monochrome-60 body-regular text-start my-auto">{numberWithCommas(item.tokenAmount ?? 0)} USD</span>
      <div className="text-monochrome-60 body-regular flex justify-start items-start my-auto">
        <div className={`bg-status-10 px-3 rounded-lg py-1 caption-medium flex justify-start items-center  project-status-${status.toLowerCase()}`}>
          <div className={`rounded-full w-2 h-2 mr-2`}></div>
          {status}
        </div>
      </div>
    </div>
  );
};

export default InvestorPurchaseItems;
