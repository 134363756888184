const IcWithdrowal = (props: any) => {
    return (
        <div {...props}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.91956 1.33333C8.19569 1.33333 8.41956 1.55719 8.41956 1.83333V9.86066C8.41956 10.1368 8.19569 10.3607 7.91956 10.3607C7.64336 10.3607 7.41956 10.1368 7.41956 9.86066L7.41956 1.83333C7.41956 1.55719 7.64336 1.33333 7.91956 1.33333Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.62276 7.5552C5.81842 7.36034 6.135 7.361 6.32986 7.55667L7.91956 9.15294L9.50929 7.55667C9.70416 7.361 10.0208 7.36034 10.2164 7.5552C10.4121 7.75007 10.4127 8.0666 10.2179 8.26227L8.27389 10.2143C8.18003 10.3085 8.05256 10.3615 7.91956 10.3615C7.78663 10.3615 7.65916 10.3085 7.5653 10.2143L5.62131 8.26227C5.42645 8.0666 5.4271 7.75007 5.62276 7.5552Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.4157 4.79321C10.4411 4.51823 10.6845 4.31587 10.9595 4.34123C12.1911 4.45478 13.1904 4.75259 13.8367 5.62473C14.4565 6.46093 14.6669 7.72079 14.6669 9.50579C14.6669 11.8721 14.2957 13.3269 13.0801 14.0735C12.5007 14.4293 11.7831 14.5866 10.9617 14.663C10.1436 14.7391 9.16079 14.7391 8.02139 14.7391H7.97919C6.83946 14.7391 5.85647 14.7391 5.03828 14.663C4.21686 14.5866 3.49934 14.4293 2.92001 14.0735C1.70446 13.3269 1.33362 11.872 1.33362 9.50579C1.33362 7.72086 1.54392 6.46097 2.16356 5.62477C2.80986 4.75258 3.80925 4.45477 5.04106 4.34123C5.31604 4.31588 5.5595 4.51825 5.58484 4.79322C5.61019 5.0682 5.40782 5.31166 5.13285 5.33701C3.97798 5.44346 3.35072 5.70232 2.96702 6.22013C2.55665 6.77393 2.33362 7.73739 2.33362 9.50579C2.33362 11.8729 2.73244 12.7847 3.4434 13.2214C3.82732 13.4572 4.36226 13.5958 5.1309 13.6673C5.89773 13.7387 6.83532 13.7391 8.00026 13.7391C9.16492 13.7391 10.1023 13.7387 10.8691 13.6673C11.6377 13.5958 12.1727 13.4572 12.5567 13.2214C13.2679 12.7847 13.6669 11.8728 13.6669 9.50579C13.6669 7.73746 13.4438 6.77399 13.0333 6.22017C12.6495 5.70231 12.0222 5.44345 10.8677 5.33701C10.5927 5.31165 10.3904 5.06819 10.4157 4.79321Z" fill="black" />
            </svg>

        </div>
    )
}

export default IcWithdrowal