import { IcNextPage, IcPreviousPage, IcSearch } from "@/assets";
import React, { useEffect, useState } from "react";
import { formatNumberWithCommas } from "@/utils/commanFunctions";
import { IcCopy } from "@/assets/Icons";
import toast from "react-hot-toast";
import Button from "@/components/UI/Button";

const perPageItems = [10, 25, 50, 100];

const InvestorDistribution = ({ revenueData, open, setOpen }: any) => {
  const initialState = {
    submit: false,
    submitted: false,
    confirmTransaction: false,
    SuccessConfirmTransaction: false,
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPageItems[0]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [hasTokenHolders, setHasTokenHolders] = useState(false);

  useEffect(() => {
    if (revenueData?.investorRevenueShares) {
      const filteredHolders = revenueData.investorRevenueShares.filter((item: any) => {
        return !!item.investorId?.userId;
      });
      setHasTokenHolders(filteredHolders.length > 0);
    }
  }, [revenueData]);

  useEffect(() => {
    if (revenueData) {
      // Adjusting the filter logic to check the investor's name
      const filtered =
        revenueData?.investorRevenueShares?.filter((item: any) => {
          const investorName = `${item.investorId.userId.firstName} ${item.investorId.userId.lastName}`;
          return investorName.toLowerCase().includes(searchTerm.toLowerCase());
        }) || [];
      setFilteredData(filtered);
    }
  }, [revenueData, searchTerm]);

  console.log("Revenue Data in Investor: ", revenueData);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1) return;
    if (pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (item: number) => {
    setItemsPerPage(item);
    setCurrentPage(1); // Reset to first page on items per page change
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!"); // Optional toast notification
  };

  return (
    <div className="mt-4">
      <div className="bg-white rounded-lg p-4">
        <div className="grid sm:grid-cols-3 justify-between items-center gap-4 sm:gap-0 mb-4">
          <h4>Investor Distribution List</h4>
          <div className="flex justify-between gap-4">
            <div className="relative w-[210px] sm:w-[230px] md:w-[300px]">
              <input
                type="text"
                placeholder="Search investors..."
                className="!w-full !border-none body-small-regular !text-[12px] !m-0 !bg-monochrome-5 !rounded-lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="absolute top-[9px] right-3 text-monochrome-20">
                <IcSearch />
              </div>
            </div>
          </div>
          <div className="mb-2 md:mb-0 flex flex-col items-end w-full md:w-auto sm:w-full">
            <Button secondary rounded onClick={() => setOpen({ ...initialState, confirmTransaction: true })} disabled={!hasTokenHolders}>
              Confirm Transfer
            </Button>
          </div>
        </div>
        <div className="overflow-x-auto lg:overflow-hidden">
          <div className="xl:relative w-[220%] lg:w-full">
            <div className="grid grid-cols-4 body-small-regular text-monochrome-30 py-3 border-b border-monochrome-10">
              <span className="text-start">Name</span>
              <span className="text-start">Amount to Transfer</span>
              <span className="text-start">IBAN</span>
              <span className="text-start">Status</span>
            </div>
            <div>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <div key={index} className={`grid grid-cols-4 py-3 border-b border-monochrome-10 ${index === currentItems.length - 1 ? "mb-[55px]" : ""}`}>
                    <span className="text-monochrome-60 body-regular text-start my-auto">
                      {item.investorId.userId.firstName} {item.investorId.userId.lastName}
                    </span>
                    <span className="text-monochrome-60 body-regular text-start my-auto">{formatNumberWithCommas(String(item.revenueShare ?? 0))} USD</span>
                    <div className="flex items-center gap-2 text-monochrome-60 body-regular text-start my-auto">
                      <p>{item.investorId?.bankIban || ""} </p>
                      {item.investorId?.bankIban && (
                        <button
                          onClick={() => copyToClipboard(item.investorId?.bankIban)}
                          className="text-lg text-white hover:text-white focus:outline-none hover:bg-slate-200"
                          title="IBAN Copied to clipboard"
                        >
                          <IcCopy className="h-5 w-5 hover:fill-current text-white" />
                        </button>
                      )}
                    </div>
                    <div
                      className={`w-fit bg-status-10 rounded-lg py-1 px-3 caption-medium flex justify-start items-center project-status-${
                        item?.distributions[0]?.isDistributed ? "listed" : "pending"
                      }`}
                    >
                      <div className={`rounded-full w-2 h-2 mr-2`}></div>
                      {item?.distributions[0]?.isDistributed ? "Completed" : "Pending"}
                    </div>
                  </div>
                ))
              ) : (
                <div className="center min-h-40">
                  <p className="text-monochrome-60 text-[0.75rem] text-start my-auto pt-4">No Token Holders.</p>
                </div>
              )}
            </div>
            <div className="xl:absolute xl:bottom-[-55px] w-full flex justify-between pt-[16px] text-[14px]">
              <div className="flex gap-3 items-center">
                {/* <ul className="flex">
                                    {perPageItems.map((item, index) => (
                                        <li
                                            key={`page-items-${index}`}
                                            onClick={() => handleItemsPerPageChange(item)}
                                            className={`py-2 px-3 body-small-regular border-2 first:rounded-l-lg last:rounded-r-lg cursor-pointer bg-monochrome-5 ${item === itemsPerPage ? 'border-green-100 bg-status-approved text-white' : 'border-monochrome-10 text-monochrome-30'
                                                }`}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                                <span className="text-monochrome-20 body-small-regular">Items per page</span> */}
              </div>
              <div className="flex items-center gap-2 body-small-regular">
                <span className="text-monochrome-20">Page</span>
                <input
                  className="!bg-monochrome-5 !text-[12px] !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto] !border-0 p-2 rounded-l-lg !leading-[20px]"
                  type="text"
                  value={currentPage}
                  onChange={(e) => handlePageChange(Number(e.target.value))}
                />
                <span className="text-monochrome-20">of {totalPages}</span>
                <div className="grid grid-cols-2 border-2 border-monochrome-10 divide-monochrome-10 divide-x rounded-lg items-center">
                  <button className="p-2 bg-monochrome-5 rounded-l-lg" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <IcPreviousPage />
                  </button>
                  <button className="p-2 bg-monochrome-5 rounded-r-lg" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    <IcNextPage />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorDistribution;
