type Props = {}

const IcFilePlaceholder = (props: Props) => {
    return (
        <div {...props}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3538 5.64625L9.85375 2.14625C9.80728 2.09983 9.75212 2.06303 9.69143 2.03793C9.63073 2.01284 9.56568 1.99995 9.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3L2.5 14C2.5 14.2652 2.60536 14.5196 2.79289 14.7071C2.98043 14.8946 3.23478 15 3.5 15H12.5C12.7652 15 13.0196 14.8946 13.2071 14.7071C13.3946 14.5196 13.5 14.2652 13.5 14V6C13.5001 5.93432 13.4872 5.86927 13.4621 5.80858C13.437 5.74788 13.4002 5.69272 13.3538 5.64625ZM10 3.70688L11.7931 5.5H10V3.70688ZM12.5 14H3.5L3.5 3H9V6C9 6.13261 9.05268 6.25979 9.14645 6.35355C9.24021 6.44732 9.36739 6.5 9.5 6.5H12.5V14Z" fill="black" />
            </svg>
        </div>
    )
}

export default IcFilePlaceholder