import { IUser } from "@/types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface CmpyState {
  userData: IUser;
  setUser: (userData: IUser) => void;
  companyData: any;
  setCompany: (companyData: any) => void;
  reset: () => void;
}

const initialState = {
  userData: {
    isDeleted: false,
    deletedAt: null,
    _id: "",
    isProfileExisting: false,
    walletAddress: "",
    emailNotifications: false,
    investorId: "",
    role: "",
    permissions: [],
    kycStatus: "",
    statusUpdates: [],
    personalIdentityDocuments: [],
    pepHits: [],
    sanctionHits: [],
    __v: 0,
    email: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    metamaskWalletAddress: null,
    middleNames: "",
    position: "",
    // Add any additional fields here
  },
  companyData: {
    companyTicker: "",
    members: [],
    companyName: "",
    registrationNumber: "",
    businessAddress: "",
    businessPhone: "",
    industrySector: "",
    ownershipStructure: "",
    businessActivities: "",
    legalType: "",
    registrationDate: null,
    visitAddress: "",
    postalAddress: "",
    companyStatus: "",
    numberOfEmployeesInterval: "",
    industryCode: "",
    registeredCorporateTaxID: "",
    registeredCorporateVatID: "",
    signingCombinations: [],
    boardMembers: [],
    kybStatus: "Not Submitted",
    bankAccountHolderName: "",
    bankName: "",
    bankAccountNumber: "",
    bankSwiftBicCode: "",
    bankIban: "",
    bankDefaultCurrency: "",
    bankTransactionLimits: "",
    privacySettings: "",
    dataSharingPreferences: "",
    consentManagement: [],
    documentId: [],
    isDeleted: false,
    deletedAt: null,
  },
};

const useStore = create<CmpyState>()(
  persist(
    (set) => ({
      ...initialState,
      setUser: (userData) => set((state) => ({ ...state, userData: { ...state.userData, ...userData } })),
      setCompany: (companyData) => set((state) => ({ ...state, companyData: { ...state.companyData, ...companyData } })),
      reset: () => set(initialState),
    }),
    { name: "penomo_cmpy_store" }
  )
);

export default useStore;
