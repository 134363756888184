import Button from "@/components/UI/Button";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { FaInfoCircle } from "react-icons/fa";
import { z } from "zod";

interface Props {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  setFormDetails: (value: any) => void;
  formDetails: any;
  handleSubmit: () => void;
}

interface IErrors {
  fundingGoal?: string;
  name?: string;
  websiteLink?: string;
  projectDescription?: string;
}

const CustomTooltip = ({ content }: { content: string }) => {
  const [isVisible, setIsVisible] = useState(false);
  

  return (
    <div className="relative inline-block">
      <FaInfoCircle
        className="text-gray-400 cursor-pointer ml-1"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      />
      {isVisible && (
        <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48">
          {content}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
  <div className="label flex items-center">
    {label}
    <CustomTooltip content={tooltip} />
  </div>
);

const capitalNeededSchema = z.object({
  fundingGoal: z.string().min(1, "Capital needed is required"),
  name: z.string().min(1, "Project Name is required"),
  websiteLink: z.string().url("Website Link must be a valid URL"),
  projectDescription: z.string().optional(),
});

const formatNumberWithCommas = (value: string) => {
  const num = value.replace(/\D/g, ""); // Remove non-digit characters
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas
};

const removeCommas = (value: string) => {
  return value.replace(/,/g, ""); // Remove commas
};

const CapitalNeededStep = ({ currentStep, setCurrentStep, setFormDetails, formDetails }: Props) => {
  const [errors, setErrors] = useState<IErrors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // Handle comma formatting only for the fundingGoal field
    const formattedValue = name === "fundingGoal" ? formatNumberWithCommas(value) : value;

    setFormDetails((prevFormData: any) => ({
      ...prevFormData,
      [name]: name === "fundingGoal" ? removeCommas(formattedValue) : value, // Store without commas only for fundingGoal
    }));

    const fieldValidationResult = capitalNeededSchema.pick({ [name]: true }).safeParse({ [name]: value });
    if (!fieldValidationResult.success) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: fieldValidationResult.error.flatten().fieldErrors[name][0],
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors } as any;
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleNext = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const result = capitalNeededSchema.safeParse(formDetails);

    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors);
      toast.error("Please correct the errors before submitting.");
      return;
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <>
      <h4>How much capital do you need?</h4>
      <form onSubmit={handleNext}>
        {/* Capital Needed Field */}
        <div className="relative mt-6 text-sm">
          {/* <label className="text-sm">Capital needed (amount in USD)</label> */}
          <LabelWithTooltip label="Capital needed (amount in USD)" tooltip="Enter the amount you want to raise" />
          <input
            type="text"
            name="fundingGoal"
            className={`mt-2 w-full pl-6 border-monochrome-10`}
            value={formDetails?.fundingGoal ? formatNumberWithCommas(formDetails.fundingGoal) : ""}
            onChange={(e) => handleChange(e)}
          />
          <p className="absolute bottom-2 left-2 text-monochrome-60">$</p>
          <p className="absolute bottom-2 right-2 text-monochrome-60">USD</p>
        </div>
        {errors.fundingGoal && <p className="text-red-500 mt-1 text-[0.688rem]">{errors.fundingGoal}</p>}

        {/* Project Name Field */}
        <div className="relative mt-6 text-sm">
          {/* <label className="text-sm">Name of the Project</label> */}
          <LabelWithTooltip label="Name of the Project" tooltip="Enter the name of your project" />
          <input
            type="text"
            name="name"
            className={`mt-2 w-full pl-6 border-monochrome-10`}
            value={formDetails?.name || ""}
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.name && <p className="text-red-500 mt-1 text-[0.688rem]">{errors.name}</p>}

        {/* Website Link Field */}
        <div className="relative mt-6 text-sm">
          {/* <label className="text-sm">Website Link</label> */}
          <LabelWithTooltip label="Website link" tooltip="Enter the official website link of your company" />
          <input
            type="text"
            name="websiteLink"
            className={`mt-2 w-full pl-6 border-monochrome-10`}
            value={formDetails?.websiteLink || ""}
            onChange={(e) => handleChange(e)}
          />
        </div>
        {errors.websiteLink && <p className="text-red-500 mt-1 text-[0.688rem]">{errors.websiteLink}</p>}

        {/* Description Field (Textarea) */}
        <div className="grid grid-cols-1 gap-2 mt-6 text-sm">
          {/* <label className="text-sm">Project Description</label> */}
          <LabelWithTooltip label="Project description" tooltip="Enter the description/ details related to your project" />
          <textarea
            placeholder="Add relevant links"
            name="projectDescription"
            className="border border-monochrome-20 rounded-lg"
            rows={6}
            value={formDetails?.projectDescription || ""}
            onChange={(e) => handleChange(e)}
          ></textarea>
          {errors.projectDescription && <p className="text-red-500 text-[0.688rem]">{errors.projectDescription}</p>}
        </div>

        <Button secondary rounded type="submit" classNames="mt-6">
          Next
        </Button>
      </form>
    </>
  );
};

export default CapitalNeededStep;
