import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";

interface ConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationModal = ({ show, onClose, onConfirm, message }: ConfirmationModalProps) => {
  return (
    <Modal show={show} onHide={onClose} position={"center"} size={"2xl"}>
      <Modal.Body className="p-4">
        <section className="p-4 flex flex-col gap-7 items-center">
          <h3 className="text-sm font-semibold">{message}</h3>
          <div className="flex gap-4 w-full">
            <Button secondary rounded onClick={onConfirm} classNames="w-full">
              Yes
            </Button>
            <Button primary rounded onClick={onClose} classNames="w-full">
              No
            </Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
