import React, { useState } from "react";
import { FaInfoCircle, FaPlus } from "react-icons/fa";

import { Link, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import Loader from "@/components/Loader";
import { changeDocumentRequestStatus, getProjectRequestedDocuments, updateProjectDocumentRequest } from "@/services/api";
import AddDocumentModal from "./AddDocumentModal";
import Button from "@/components/UI/Button";
import { FaPencilAlt } from "react-icons/fa";
import EditDocumentModal from "./EditDocumentModal";

// Document info mapping
const DOCUMENT_INFO: { [key: string]: string } = {
  DATA_ROOM: "A secure space for confidential information sharing and document storage.",
  ORGANIZATION_DOCUMENTS: "Key documents related to company organization and structure.",
  LEGAL_DOCUMENTS: "Documents related to legal obligations and contracts.",
  COMPLIANCE_DOCUMENTS: "Documents that show compliance with regulatory standards.",
  BOARD_MEMBER_DOCUMENTS: "Documents containing details about the board members and their roles.",
  PERSONAL_IDENTITY_DOCUMENTS: "Documents verifying personal identity, such as ID cards or passports.",
  FINANCIAL_PROJECTIONS: "Estimates of future financial performance, including revenue and profit forecasts.",
  TAXATION_AND_INCENTIVES: "Documents related to taxation, government incentives, and tax credits.",
  FUNDING_AGREEMENTS: "Contracts outlining the terms and conditions of funding agreements with investors or lenders.",
  INSURANCE_POLICIES: "Documents detailing coverage of risks through insurance policies.",
  LAND_AGREEMENTS: "Contracts or deeds related to land ownership or leasing for the project.",
  POWER_PURCHASE_AGREEMENTS: "Contracts for the sale of power generated by the project to off-takers or utility companies.",
  JOINT_VENTURE_OR_PARTNERSHIP_AGREEMENTS: "Agreements detailing joint ventures or partnerships for the project.",
  INTELLECTUAL_PROPERTY_RIGHTS: "Documents that protect intellectual property like patents, copyrights, and trademarks.",
  SOCIAL_IMPACT_ASSESSMENTS: "Reports analyzing the projects social impact on communities and stakeholders.",
  SUSTAINABILITY_CERTIFICATIONS: "Certifications that verify the project meets sustainability or environmental standards.",
  OPERATION_AND_MAINTENANCE_PLANS: "Plans detailing the operation and ongoing maintenance of the project.",
  PERFORMANCE_DATA: "Data documenting the operational performance or output of the project over time.",
  HEALTH_AND_SAFETY_PLANS: "Documents outlining the measures to protect the health and safety of employees and stakeholders.",
  ENVIRONMENTAL_IMPACT_ASSESSMENTS: "studies analyzing the environmental impact of the project on the surrounding ecosystem.",
  BIODIVERSITY_STUDIES: "Documents detailing studies on the biodiversity of the area affected by the project.",
  FEASIBILITY_STUDIES: "Reports assessing the economic, technical, and legal feasibility of the project.",
  TECHNICAL_DOCUMENTS: "Documents containing technical specifications and engineering designs for the project.",
  ENGINEERING_REPORTS: "Detailed reports on the engineering aspects of the project, such as design and structural analysis.",
  PERMITTING_AND_APPROVALS: "Documents showing permits and approvals obtained from regulatory authorities.",
  EQUIPMENT_WARRANTIES: "Documents covering warranty details for equipment used in the project.",
  PROJECT_DECK: "A presentation summarizing key aspects of the project, including goals, strategy, and financials.",
  PROJECT_CONTRACTS_AND_REVENUE_AGREEMENTS: "Contracts defining the revenue streams and payment terms for the project.",
  BALANCE_SHEET: "A financial statement summarizing the companys assets, liabilities, and equity.",
  INCOME_STATEMENT: "A financial statement showing the companys revenues, expenses, and profits.",
  CASH_FLOW_STATEMENT: "A financial statement outlining the cash inflows and outflows over a period.",
  DEBT_SCHEDULE: "A document detailing the companys debts and repayment schedules.",
  OPERATIONAL_PERMITS: "Documents granting permission to operate certain aspects of the project.",
  PROJECT_TEAM_OVERVIEW: "Information on the key team members involved in the project.",
  PREVIOUS_DUE_DILIGENCE_REPORTS: "Reports from prior assessments conducted on the projects feasibility, risks, and finances.",
  RISK_ASSESSMENT_AND_MITIGATION_PLAN: "Plans and reports identifying risks and outlining strategies to mitigate them.",
  ESG_IMPACT_REPORT: "A report analyzing the environmental, social, and governance (ESG) impact of the project.",
};

// Helper function to format document names
const formatDocumentName = (name: string) => {
  return name
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const CustomTooltip = ({ content }: { content: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block z-40">
      <FaInfoCircle className="text-gray-400 cursor-pointer ml-1" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} />
      {isVisible && (
        <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-10 transform -translate-x-1/2  w-[14rem]">
          {content}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
  <div className="label flex items-center">
    {label}
    <CustomTooltip content={tooltip} />
  </div>
);

const DocumentsDetails = ({ data, refetch }: any) => {
  const [isLoading, setIsLoading] = useState<{ [key: string]: boolean }>({});
  const [showAddDocument, setShowAddDocument] = useState<boolean>(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null); // State for selected document ID
  const [searchParams] = useSearchParams();
  const projectId: any = searchParams.get("projectId");
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentText, setDocumentText] = useState("");
  console.log("🚀 ~ DocumentsDetails ~ documentText:", documentText);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [documentToEdit, setDocumentToEdit] = useState({ file: null, text: "" });

  const handleEditClick = (document: any) => {
    // Set the document data to be edited and open the modal
    setDocumentToEdit(document);
    setIsEditModalOpen(true);
  };

  // Handle file upload and text submission from modal
  const handleSubmissionFromModal = (file: any | null, text: string) => {
    if (!selectedDocumentId) return; // Ensure the document ID is selected
    const formData = new FormData();
    if (file) {
      formData.append("file", file?.file);
      formData.append("documentText", file?.text);
    }

    setIsLoading((prev) => ({ ...prev, [selectedDocumentId]: true }));

    updateProjectDocumentRequest(projectId, selectedDocumentId, formData) // Use selectedDocumentId
      .then((res) => {
        setIsLoading((prev) => ({ ...prev, [selectedDocumentId]: false }));
        if (res.code === 200) {
          refetchRequest();
          toast.success(res.message);
          setShowAddDocument(false); // Close modal after successful submission
          setIsEditModalOpen(false);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setIsLoading((prev) => ({ ...prev, [selectedDocumentId]: false }));
        toast.error(err.message || "Failed to upload document or submit text, please try again");
      });
  };

  const removeDocument = (documentRequestId: string) => {
    changeDocumentRequestStatus(documentRequestId)
      .then((res) => {
        if (res.code === 200) {
          toast.success(res.message);
          refetchRequest();
        }
      })
      .catch((err) => {
        toast.error(err.message || "Failed to remove document, please try again");
      });
  };

  const { data: documentRequestList = [], refetch: refetchRequest } = useQuery({
    queryKey: ["getProjectRequestedDocuments"],
    queryFn: () => getProjectRequestedDocuments(data?._id),
    enabled: !!data?._id,
    select: (response) => response?.data ?? [],
  });
  console.log("🚀 ~ DocumentsDetails ~ documentRequestList:", documentRequestList);

  return (
    <>
      <div className="general-card-section ">
        <h2 className="text-[14px]">Documents</h2>
        <p className="text-[11px]">Project Files & Agreements</p>
      </div>
      <div className="vertical-line"></div>
      <div className="general-card-section overflow-x-scroll text-[11px]">
        <div className="flex flex-col w-full">
          <div className="min-w-[540px]">
            <div className="grid grid-cols-3 gap-2 text-monochrome-30 body-small-regular py-3 border-b border-monochrome-10">
              <span className="text-start">Type</span>
              <span className="text-start">Document</span>
              <span className="text-start">Status</span>
            </div>
            <div className="">
              {documentRequestList.length === 0 && <p className="flex items-center justify-center m-[20px]">No documents requested</p>}
              {documentRequestList.map((item: any, index: number) => (
                <div key={"document-" + index} className="w-full grid grid-cols-3 gap-2   items-center py-3 border-b border-b-monochrome-10">
                  {/* <div className="flex gap-2 text-black sm:gap-4 text-sm items-start font-medium truncate">
                    <span className="body-medium flex gap-1 items-center truncate whitespace-pre-wrap">
                      {formatDocumentName(item.documentId.documentName)}
                      <CustomTooltip content={DOCUMENT_INFO[item.documentId.documentName] || 'No information available'} />
                    </span>
                  </div> */}
                  <LabelWithTooltip
                    label={formatDocumentName(item.documentId.documentName)}
                    tooltip={DOCUMENT_INFO[item.documentId.documentName] || "No information available"}
                  />

                  {(item.status === "Not Submitted" || item.status === "Rejected" || !item?.documentName) && (
                    <div className="flex justify-start">
                      {isLoading[item._id] ? (
                        <div className="flex items-left justify-left w-full">
                          <Loader isLoading={true} />
                        </div>
                      ) : (
                        <div className="w-full">
                          <div
                            className="flex flex-col xl:flex-row xl:justify-between gap-2"
                            onMouseEnter={() => setHoveredIndex(index)} // Set hover state to current index
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <div
                              className="w-fit gap-2 items-center cursor-pointer !justify-start border border-monochrome-10 p-2 rounded-lg flex bg-transperent !px-4"
                              onClick={() => {
                                setSelectedDocumentId(item._id); // Set the selected document ID
                                setShowAddDocument(true); // Open modal
                                setDocumentName(item.documentId.documentName);
                                setDocumentText(item.documentText);
                              }}
                            >
                              <FaPlus />
                              Add Document
                            </div>
                            {hoveredIndex === index && item.documentText && (
                              <Button
                                primary
                                rounded
                                classNames="px-[0.7rem] "
                                onClick={() => {
                                  setSelectedDocumentId(item._id); // Set the selected document ID
                                  handleEditClick(item);
                                  setDocumentName(item.documentId.documentName);
                                }} // Pass the current item to edit

                                // className="ml-2 p-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                              >
                                <FaPencilAlt className="flex text-[#00C798]" /> Edit
                              </Button>
                            )}
                          </div>
                          {item?.documentText && <div className="mt-2 text-xs text-monochrome-50 max-h-[100px] overflow-y-auto">{item?.documentText}</div>}
                        </div>
                      )}
                    </div>
                  )}

                  {(item.status === "Pending" || item.status === "Accepted") && !isLoading[item._id] && item?.documentName && (
                    <div className="flex justify-between ">
                      <div className="flex  gap-2">
                        <div className="flex flex-col  justify-start gap-2">
                          <div
                            className="flex flex-col xl:flex-row xl:justify-between gap-2"
                            onMouseEnter={() => setHoveredIndex(index)} // Set hover state to current index
                            onMouseLeave={() => setHoveredIndex(null)}
                          >
                            <span className="flex flex-row gap-4 items-center p-2 px-1 bg-monochrome-5 rounded-lg">
                              <Link
                                to={item?.documentPath}
                                target="_blank"
                                className="underline font-medium text-xs items-start text-nowrap justify-between rounded-lg"
                              >
                                <span className="flex flex-row gap-2 text-wrap items-center">
                                  <img src="../assets/fileIcon.svg" alt="file" />
                                  {item?.documentName?.length > 17 ? `${item.documentName.slice(0, 17)}...` : item.documentName}
                                </span>
                              </Link>
                              <img src="../assets/cross.svg" alt="cross" className="flex font-bold cursor-pointer" onClick={() => removeDocument(item._id)} />
                            </span>

                            {/* Conditionally render the Edit button only for the hovered item */}
                            {hoveredIndex === index && (
                              <div className="hidden lg:block">
                                <Button
                                  primary
                                  rounded
                                  classNames="px-[0.7rem] flex flex-row " // Show only on hover on laptops and larger screens
                                  onClick={() => {
                                    setSelectedDocumentId(item._id);
                                    handleEditClick(item);
                                    setDocumentName(item.documentId.documentName);
                                  }}
                                >
                                  <FaPencilAlt className="flex text-[#00C798]" /> Edit
                                </Button>
                              </div>
                            )}

                            {/* Always visible on smaller screens */}
                            <Button
                              primary
                              rounded
                              classNames="px-[0.7rem] block lg:hidden" // Always visible on tablets and smaller devices
                              onClick={() => {
                                setSelectedDocumentId(item._id);
                                handleEditClick(item);
                                setDocumentName(item.documentId.documentName);
                              }}
                            >
                              <FaPencilAlt className="flex text-[#00C798]" /> Edit
                            </Button>

                          </div>
                          <div className="text-xs text-monochrome-50 max-h-[100px] overflow-y-auto">{item?.documentText}</div>
                        </div>
                      </div>
                      <div className="rounded-lg p-1 mx-1 cursor-pointer" onClick={() => removeDocument(item._id)}>
                        {/* Icon to remove document */}
                      </div>
                    </div>
                  )}

                  <div className="flex gap-4 items-center">
                    <div
                      className={`w-fit bg-status-10 !text-xs rounded-lg py-0 px-3 caption-medium flex justify-center gap-2 h-[33px] items-center ${item.status === "Not Submitted"
                        ? "project-status-notSubmitted"
                        : item.status === "Rejected"
                          ? "project-status-issued"
                          : item.status === "Not Accepted"
                            ? "project-status-notApproved"
                            : item.status === "Pending"
                              ? "project-status-pending"
                              : "project-status-approved"
                        }`}
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-status-10"></div>
                      {item.status}
                    </div>
                    {item.rejectionReason && item.rejectionReason}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {showAddDocument && (
        <AddDocumentModal
          show={showAddDocument}
          onClose={() => {
            setShowAddDocument(false);
            setDocumentName("");
          }}
          onConfirm={handleSubmissionFromModal}
          documentName={documentName}
          existingDocumentText={documentText}
        />
      )}
      {isEditModalOpen && (
        <EditDocumentModal
          show={isEditModalOpen}
          documentName={documentName}
          onClose={() => {
            setIsEditModalOpen(false);
            setDocumentName("");
          }}
          onConfirm={handleSubmissionFromModal}
          documentData={documentToEdit} // Pass the document data to edit
        />
      )}
    </>
  );
};

export default DocumentsDetails;
