export const revenueGeneratingAssetArray = [
    {
        value: "BATTERY",
        label: "Battery Storage"
    },
    {
        value: "SOLAR",
        label: "Solar/PV"
    },
    {
        value: "WIND",
        label: "Wind Turbine"
    },
    {
        value: "EVCHARGEPOINT",
        label: "EV Charging Station"
    },
    {
        value: "OTHER",
        label: "Other"
    }
]