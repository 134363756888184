import { Container, Row, Col } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import "./InvestmentCard.css";
import { countryNameRecord, CountryCode } from "../../../utils/countryFlag";

const countryCodeRecord = Object.fromEntries(Object.entries(countryNameRecord).map(([code, name]) => [name, code])) as Record<
  string,
  keyof typeof countryNameRecord
>;

const OverviewHeader = ({ data }: any) => {
  const { name = "", status = "", assetLocation = "", assetType = "" } = { ...data }

  const statusTypes = {
    Pending: "pending",
    Accepted: "approved",
    Issued: "issued",
    Rejected: "rejected",
    Listed: "listed"
  };

  // Attempt to get the country code from the asset location (which may be a country name)
  const countryCode = countryCodeRecord[assetLocation] || (assetLocation.toUpperCase() as CountryCode);

  return (

    <div className="!w-full investment-card mb-4 bg-black">
      {/* <Row className="header tab-container" style={{ gap: '10px' }}>
                <Col className="tab">Energy Sales PPA</Col>
                <Col className="tab">Battery Storage</Col>
                <Col className="tab">Carbon Credits</Col>
            </Row> */}
      <Row className="content">
        <Col className="w-full">
          <div className="title break-words">{name}</div>
          <div className="flex items-center mt-[16px] mb-[16px] gap-[40px]">
            <div className="country text-gray flex items-center">
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                style={{
                  width: "26px",
                  height: "26px",
                  marginRight: "8px",
                }}
                aria-label={countryNameRecord[countryCode]}
              />
              {assetLocation}
            </div>
            <div className="country text-gray flex items-center" style={{ gap: "10px" }}>
              <div className={`bg-status-10 rounded-lg py-1 px-3 caption-medium flex items-center project-status-${statusTypes[status]} !bg-[#00c79817]`}>
                <div className="rounded-full w-2 h-2 mr-2"></div>
                {status}
              </div>

            </div>
          </div>

        </Col>
      </Row>
    </div>
  );
};

export default OverviewHeader;
