import { ENDPOINT } from "@/constants";
import { http } from "./http";

const fetchUser = (id: string) => http.get(`${ENDPOINT.USER}/${id}`).then((res) => res.data);
const deleteUser = (id: string) => http.delete(`${ENDPOINT.USER}/${id}`).then((res) => res.data);
const fetchTransactions = (cid: string, networkType: string) => http.get(`${ENDPOINT.TRANSACTIONS}/${cid}/${networkType}`).then((res) => res.data);
export const fetchTransactionsUsingWalletId = (id: string) => http.get(`/api/transactions/address/${id}`).then((res) => res.data);
export const fetchTransactionsUsingUserId = (id: string) => http.get(`/api/transactions/user/${id}`).then((res) => res.data);
const fetchCompany = (cid: string) => http.get(`${ENDPOINT.GET_COMPANY}/${cid}`).then((res) => res.data);
const fetchProjects = (cid: string) => http.get(`${ENDPOINT.GET_COMPANY_PROJECTS}/${cid}`).then((res) => res.data);
const fetchTotalFinancingReceived = (cid: string) => http.get(`${ENDPOINT.GET_TOTAL_FINANCING_RECEIVED}/${cid}`).then((res) => res.data);
const fetchProjectById = (id: string) => http.get(`${ENDPOINT.GET_COMPANY_PROJECTS_BY_ID}/${id}`).then((res) => res.data);
const getInvestorDetails = (id: string) => http.get(`${ENDPOINT.GET_INVESTOR}/${id}`).then((res) => res.data);
const fetchNotificationsByProjectId = (id: string) => http.get(`${ENDPOINT.GET_NOTIFICATIONS_PROJECTS_BY_ID}/${id}`).then((res) => res.data);
const updateProjectDetails = (cid: string, data: any) => http.patch(`${ENDPOINT.PROJECT}/${cid}`, data).then((res) => res.data);
const removeProjectImage = (cid: string) => http.put(`${ENDPOINT.PROJECT}/removeProjectImage/${cid}`).then((res) => res.data);
const updateProjectImage = (projectId: string, data: any) =>
  http
    .patch(`${ENDPOINT.PROJECT}/uploadProjectImage/${projectId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
const updateAssetsById = (cid: string, data: any) => http.patch(`${ENDPOINT.ASSET_BY_ID}/${cid}`, data).then((res) => res.data);
const updateBankDetails = (cid: string, data: any) => http.patch(`${ENDPOINT.GET_COMPANY}/${cid}`, data).then((res) => res.data);
const createNewProject = (cid: string, data: any) =>
  http
    .post(`${ENDPOINT.PROJECT}/new/${cid}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
const deleteProject = (id: string) => http.delete(`${ENDPOINT.PROJECT}/${id}`).then((res) => res.data);
const createNewAsset = (cid: string, data: any) => http.post(`${ENDPOINT.ASSET_NEW}/${cid}`, data).then((res) => res.data);

const requestForListing = (pid: string, data: any) => http.patch(`${ENDPOINT.REQUEST_FOR_LISTING}/${pid}`, data).then((res) => res.data);
const fetchTokenById = (pid: string) => http.get(`${ENDPOINT.GET_TOKEN_BY_ID}/${pid}`).then((res) => res.data);
const getAllPurchaseRequests = (tid: string) => http.get(`${ENDPOINT.GET_PURCAHSE_REQUESTS}/${tid}`).then((res) => res.data);
const getAllTokenHolders = (tid: string) => http.get(`${ENDPOINT.GET_ALL_TOKEN_HOLDERS}/${tid}`).then((res) => res.data);
const acceptTokenPurchase = (pid: string) => http.post(`${ENDPOINT.ACCEPT_PURCAHSE_REQUEST}/${pid}`).then((res) => res.data);
const confirmFundsReceived = (pid: string) => http.post(`${ENDPOINT.CONFIRM_FUNDS_RECIEVED}/${pid}`).then((res) => res.data);
const declineTokenPurcahse = (pid: string) => http.post(`${ENDPOINT.DECLINE_PURCAHSE_REQUEST}/${pid}`).then((res) => res.data);
const refundFunds = (pid: string) => http.post(`${ENDPOINT.REFUND_DONE}/${pid}`).then((res) => res.data);
const transferAfd = (pid: string) => http.post(`${ENDPOINT.TRANSFER_AFD}/${pid}`).then((res) => res.data);

const fetchAllNotifications = (id: string) => http.get(`${ENDPOINT.NOTIFICATION}/${id}`).then((res) => res.data);
const readNotification = (id: string) => http.patch(`${ENDPOINT.READ_NOTIFICATION}/${id}`).then((res) => res.data);
const readAllNotifications = (id: string) => http.patch(`${ENDPOINT.READ_ALL_NOTIFICATIONS}/${id}`).then((res) => res.data);

const updateProjectDocumentRequest = (projectId: string, documentRequestId: string, data: any) =>
  http
    .post(`${ENDPOINT.PROJECT}/${projectId}/${documentRequestId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
const getProjectRequestedDocuments = (projectId: string) => http.get(`${ENDPOINT.PROJECT}/${projectId}/requested-documents`).then((res) => res.data);
const changeDocumentRequestStatus = (documentRequestId: string) =>
  http.put(`${ENDPOINT.PROJECT}/project-documents/${documentRequestId}`).then((res) => res.data);

const createNewRevenue = (tid: string, data: FormData) =>
  http
    .post(`${ENDPOINT.REVENUE_NEW}/${tid}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
const removeRevenueDoc = (revenueId: string, documentId: string) =>
  http.put(`${ENDPOINT.REMOVE_REVENUE_DOCS}/${revenueId}/${documentId}`).then((res) => res.data);
const investorTransferComplete = (tid: string,data:any) => http.patch(`${ENDPOINT.UPDATE_ALL_INVESTOR_STATUS}/${tid}`,data).then((res) => res.data);
const getRevenueData = (tid: string, quarter: string, year: string) => http.get(`${ENDPOINT.GET_REVENUE}/${tid}/${quarter}/${year}`).then((res) => res.data);

const requestRemainingAmountApi = (tid: string, data: any) => http.post(`${ENDPOINT.REQUEST_REMAINING_AMOUNT}/${tid}`, data).then((res) => res.data);
const refundAmountApi = (tid: string, data: any) => http.post(`${ENDPOINT.REFUND_AMOUNT}/${tid}`, data).then((res) => res.data);

const sendTransactionHash = (data:any) => http.post(`${ENDPOINT.SEND_TRANSACTION}`, data).then((res) => res.data);

export {
  getInvestorDetails,
  updateAssetsById,
  fetchUser,
  deleteUser,
  fetchProjectById,
  fetchTransactions,
  fetchCompany,
  fetchProjects,
  updateBankDetails,
  createNewProject,
  createNewAsset,
  updateProjectDetails,
  removeProjectImage,
  fetchAllNotifications,
  readNotification,
  readAllNotifications,
  fetchNotificationsByProjectId,
  updateProjectDocumentRequest,
  getProjectRequestedDocuments,
  changeDocumentRequestStatus,
  requestForListing,
  fetchTokenById,
  getAllPurchaseRequests,
  acceptTokenPurchase,
  confirmFundsReceived,
  declineTokenPurcahse,
  getAllTokenHolders,
  createNewRevenue,
  removeRevenueDoc,
  getRevenueData,
  investorTransferComplete,
  requestRemainingAmountApi,
  refundAmountApi,
  refundFunds,
  transferAfd,
  fetchTotalFinancingReceived,
  updateProjectImage,
  deleteProject,
  sendTransactionHash
};
