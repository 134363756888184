import { useState, useEffect } from "react";
import Button from "@/components/UI/Button";
import { formatNumberWithCommas, removeCommas } from "@/utils/commanFunctions";
import { createNewRevenue, getRevenueData, removeRevenueDoc } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import ConfirmSubmitModal from "./ConfirmSubmitModal";
import ConfirmTransactionModal from "./ConfirmTransactionModal";
import InvestorDistribution from "./InvestorDistribution";
import SubmitModal from "./SubmitModal";
import SuccessConfirmTransferModal from "./SuccessConfirmTransferModal";
import RevenueSummaryBanner from "./RevenueSummaryBanner";
import { z } from "zod";


interface Document {
  title: string;
  amount: string;
  description: string;
  documents: any[];
}


const revenueAndExpensesSchema = z.object({
  title: z.string().nonempty("Title is required"),
  amount: z.string().regex(/^\d+(\.\d{1,2})?$/, "Amount should be a valid number"),
  description: z.string().nonempty("Title is required"),
  documents: z.array(z.any()).min(1, "At least one document is required"),
});

export default function RevenueQ3({ quarter, year, tokenId, revenueSharePercentage, fixedIntrestRatePercentage, fundingReceived, fundingGoal }: any) {
  const [revenues, setRevenues] = useState<Document[]>([{ title: "", amount: "", description: "", documents: [] }]);

  const [deductableExpenses, setDeductableExpenses] = useState<Document[]>([{ title: "", amount: "", description: "", documents: [] }]);
  const [loading, setLoading] = useState<boolean>(false);
  const [revenueErrors, setRevenueErrors] = useState<any[]>([]);
  const [expenseErrors, setExpenseErrors] = useState<any[]>([]);
  console.log("🚀 ~ RevenueQ3 ~ expenseErrors:", expenseErrors);

  const initialState = {
    submit: false,
    submitted: false,
    confirmTransaction: false,
    SuccessConfirmTransaction: false,
  };
  const [open, setOpen] = useState(initialState);

  const {
    data: revenueData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getRevenueData", tokenId, quarter, year],
    queryFn: () => getRevenueData(tokenId, quarter, year),
    enabled: !!tokenId && !!quarter && !!year,
    select: (res) => res.data,
  });

  const revenueId = revenueData?._id;

  useEffect(() => {
    if (revenueData) {
      setRevenues(revenueData?.revenues || []);
      setDeductableExpenses(revenueData?.deductableExpenses || []);
    } else {
      setRevenues([]);
      setDeductableExpenses([]);
    }
  }, [revenueData, quarter]);

  const handleAddRevenue = () => {
    setRevenues([...revenues, { title: "", amount: "", description: "", documents: [] }]);
  };

  const deleteRevenue = (clickedIndex: number) => {
    setRevenues((prevRevenues) => {
      const newRevenues = [...prevRevenues];
      newRevenues.splice(clickedIndex, 1);
      return newRevenues;
    });
  };

  const handleAddExpense = () => {
    setDeductableExpenses([...deductableExpenses, { title: "", amount: "", description: "", documents: [] }]);
  };

  const deleteExpense = (clickedIndex: number) => {
    setDeductableExpenses((prevExpenses) => {
      const newExpenses = [...prevExpenses];
      newExpenses.splice(clickedIndex, 1);
      return newExpenses;
    });
  };

  const handleCloseModal = async () => {
    setOpen(initialState);
    await refetch();
  };

  // Generalized change handler for both title and amount
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, field: string, type = "revenues") => {
    const { value } = e.target;

    const updater = type === "revenues" ? setRevenues : setDeductableExpenses;
    const documents = type === "revenues" ? revenues : deductableExpenses;

    const updatedDocuments = documents.map((doc, i) => {
      if (i === index) {
        return {
          ...doc,
          [field]: field === "amount" ? removeCommas(value) : value,
        };
      }
      return doc;
    });

    updater(updatedDocuments);

    if (type == "revenues") {
      validateRevenueField();
    } else {
      validateExpenseField();
    }
  };

  const validateRevenueField = () => {
    const newErrors = revenues.map((doc: any) => {
      const validationResults = revenueAndExpensesSchema.safeParse(doc);
      if (!validationResults.success) {
        const errors = validationResults.error.errors;
        const errorObject = {};
        errors.forEach((error: any) => {
          errorObject[error.path[0]] = error.message;
        });
        return errorObject;
      }
      return {};
    });

    setRevenueErrors(newErrors);

    const isEachFieldHasValue = revenues.every((doc) => Object.keys(doc).every((key) => (key !== "documents" ? doc[key] !== "" : doc[key].length > 0)));
    if (isEachFieldHasValue) {
      return true;
    }
    return false;
  };

  const validateExpenseField = () => {
    const newErrors = deductableExpenses.map((doc: any) => {
      const validationResults = revenueAndExpensesSchema.safeParse(doc);
      if (!validationResults.success) {
        const errors = validationResults.error.errors;
        const errorObject = {};
        errors.forEach((error: any) => {
          errorObject[error.path[0]] = error.message;
        });
        return errorObject;
      }
      return {};
    });

    setExpenseErrors(newErrors);

    const isEachFieldHasValue = deductableExpenses.every((doc) =>
      Object.keys(doc).every((key) => (key !== "documents" ? doc[key] !== "" : doc[key].length > 0))
    );
    if (isEachFieldHasValue) {
      return true;
    }
    return false;
  };

  const validateFields = () => {
    const revenueFieldValidationResult = validateRevenueField();
    const expenseFieldValidationResult = validateExpenseField();


    if (!revenueFieldValidationResult || !expenseFieldValidationResult) {
      return false;
    } else {
      setOpen({ ...open, submit: true });
    }
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("tokenId", tokenId);
    formData.append("quarter", quarter);
    formData.append("year", year);
    formData.append("revenueSharePercentage", revenueSharePercentage);
    formData.append("fixedIntrestRatePercentage", fixedIntrestRatePercentage);
    formData.append("fundingReceived", fundingReceived);
    formData.append("fundingGoal", fundingGoal);


    formData.append("revenues", JSON.stringify(revenues));
    formData.append("deductableExpenses", JSON.stringify(deductableExpenses));

    revenues.forEach((revenue, index) => {
      revenue.documents?.forEach((file) => formData.append(`REVENUES[${index}]`, file));
    });

    deductableExpenses.forEach((expense, index) => {
      expense.documents?.forEach((file) => formData.append(`DEDUCTIBLE_EXPENSES[${index}]`, file));
    });

    try {
      setLoading(true);
      await createNewRevenue(tokenId, formData);
      setOpen({ ...initialState, submitted: true });
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setLoading(false); // Set loading to false after the process is completed or an error occurs
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, index: number, type = "revenues") => {
    const files = Array.from(event.target.files || []);

    if (files.length === 0) return;

    const updateDocuments = (docs: Document[]) => {
      return docs.map((doc, i) => {
        if (i === index) {
          return { ...doc, documents: [...doc.documents, ...files] };
        }
        return doc;
      });
    };

    if (type === "revenues") {
      setRevenues(updateDocuments(revenues));
      validateRevenueField();
    } else {
      setDeductableExpenses(updateDocuments(deductableExpenses));
      validateExpenseField();
    }
  };

  const handleRemoveFile = async (index: number, fileIndex: number, type = "revenues") => {
    // Update the specific revenue object
    const updater = type === "revenues" ? setRevenues : setDeductableExpenses;
    const documents = type === "revenues" ? revenues : deductableExpenses;

    const updatedDocuments = await Promise.all(
      documents.map(async (doc, i) => {
        if (i === index) {
          let remove = doc.documents[fileIndex]._id;
          const updatedDoc = doc.documents.filter((_, fIndex) => fIndex !== fileIndex);

          try {
            await removeRevenueDoc(revenueId, remove);
          } catch (error) {
            console.error("Error submitting data:", error);
          }

          return { ...doc, documents: updatedDoc };
        }
        return doc;
      })
    );
    updater(updatedDocuments);
  };

  return (
    <>
      <div className="mx-auto p-6 bg-white rounded-lg shadow-md space-y-8 sm:p-8">
        <div className="space-y-2">
          <h2 className="text-base font-medium">
            Revenue for Q{quarter} {year}
          </h2>
        </div>
        <div className="">
          <div>
            <h3 className="text-sm font-medium mb-2">Revenues</h3>
            {revenues?.map((revenue: any, index: any) => (
              <div key={index} className="space-y-4 border border-gray-200 p-4 rounded-lg mb-4">
                <div className="flex justify-between items-center">
                  <p className="text-xs font-medium">Revenue {index + 1}</p>
                  <button className="text-gray-500 hover:text-ternary" onClick={() => deleteRevenue(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col sm:flex-row sm:space-x-4 gap-5 sm:gap-0">
                  <div className="w-full sm:w-1/2">
                    <label className="block text-[11px] leading-3 font-normal text-black mb-1">Title</label>
                    <input
                      type="text"
                      placeholder="Sales Revenue"
                      name="title"
                      className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 w-full"
                      value={revenue.title}
                      onChange={(e) => handleInputChange(e, index, "title")}
                    />
                    {revenueErrors[index]?.title && <p className="text-xs mt-1 text-red-500">{revenueErrors[index].title}</p>}
                  </div>
                  <div className="w-full sm:w-1/2">
                    <label className="block text-[11px] leading-3 font-normal text-black mb-1">Amount</label>
                    <div className="flex relative">
                      <span className="text-black absolute left-0 p-2"></span>
                      <input
                        type="text"
                        name="amount"
                        className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 w-full"
                        value={formatNumberWithCommas(String(revenue.amount ?? 0))}
                        onChange={(e) => handleInputChange(e, index, "amount")}
                      />
                      <span className="text-xs font-medium text-[#d1bdbd] absolute right-0 p-2">USD</span>
                    </div>
                    {revenueErrors[index]?.amount && <p className="text-xs mt-1 text-red-500">{revenueErrors[index].amount}</p>}
                  </div>
                  <div className="w-full sm:w-1/2">
                    <label className="block text-[11px] leading-3 font-normal text-black mb-1">Description</label>
                    <textarea
                      placeholder="Description"
                      name="description"
                      className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 w-full"
                      value={revenue.description}
                      onChange={(e) => handleInputChange(e, index, "description")}
                    />
                    {revenueErrors[index]?.description && <p className="text-xs mt-1 text-red-500">{revenueErrors[index].description}</p>}
                  </div>
                </div>
                <div>
                  <div className="flex flex-col sm:flex-row gap-5">
                    <div className="w-full sm:w-[20%] mt-4 sm:mt-0">
                      <label className="block text-[11px] leading-3 font-normal text-black mb-1">Upload Documents</label>
                      <div className="flex space-x-4">
                        <button className="hover:bg-white" onClick={() => document.getElementById(`fileInput-${index}`).click()}>
                          <span className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 text-[11px] leading-3 font-normal text-black inline-block">
                            Upload file
                          </span>
                        </button>
                        <input
                          id={`fileInput-${index}`}
                          name={`fileInput-${index}`}
                          type="file"
                          multiple
                          className="hidden"
                          onChange={(e) => handleFileChange(e, index)}
                        />
                      </div>
                    </div>

                    <div className="mt-4 sm:mt-0 flex-1 w-[75%]">
                      <label className="block text-[11px] leading-3 font-normal text-black mb-1">Attached Files</label>
                      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                        <div className="flex flex-wrap gap-2">
                          {revenue?.documents?.map((file: any, fileIndex: any) => (
                            <div
                              key={fileIndex}
                              className="flex items-center justify-between rounded-lg border-[0.5px] border-[#DFDFDF] px-3 py-2 text-[11px] leading-3 font-normal text-black "
                            >
                              <a
                                className="flex-grow text-[11px] leading-3 font-normal text-black flex items-center"
                                href={file.documentPath}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.documentName || file.name}
                              </a>
                              <button className="text-red-500 ml-2" onClick={(e) => handleRemoveFile(index, fileIndex)}>
                                ×
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {revenueErrors[index]?.documents && <p className="text-xs mt-1 text-red-500">{revenueErrors[index].documents}</p>}
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center mb-4 hover:bg-white">
              <button className="text-sm font-medium text-[#00C798] mt-4 hover:bg-white underline" onClick={handleAddRevenue}>
                + Add Revenue
              </button>
            </div>
            <p className="border-dashed border-[1px] border-grey-500 my-6"></p>
          </div>

          <div>
            <h3 className="text-sm font-medium mb-6">Deductable costs</h3>
            {deductableExpenses?.map((expense: any, index: any) => (
              <div key={index} className="space-y-4 border border-gray-200 p-4 rounded-md mb-4">
                <div className="flex justify-between items-center">
                  <p className="text-xs font-medium">Deductable cost {index + 1}</p>
                  <button className="text-gray-500 hover:text-ternary" onClick={() => deleteExpense(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col sm:flex-row sm:space-x-4 gap-5 sm:gap-0">
                  <div className="w-full sm:w-1/2">
                    <label className="block text-[11px] leading-3 font-normal text-black mb-1">Title</label>
                    <input
                      type="text"
                      placeholder="Salaries"
                      name="title"
                      className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 w-full"
                      value={expense.title}
                      onChange={(e) => handleInputChange(e, index, "title", "deductableExpenses")}
                    />
                    {expenseErrors[index]?.title && <p className="text-xs mt-1 text-red-500">{expenseErrors[index].title}</p>}
                  </div>
                  <div className="w-full sm:w-1/2">
                    <label className="block text-[11px] leading-3 font-normal text-black mb-1">Amount</label>
                    <div className="flex relative">
                      <span className="text-black absolute left-0 p-2"></span>
                      <input
                        type="text"
                        name="amount"
                        className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 w-full"
                        value={formatNumberWithCommas(String(expense.amount ?? 0))}
                        onChange={(e) => handleInputChange(e, index, "amount", "deductableExpenses")}
                      />
                      <span className="text-xs font-medium text-[#d1bdbd] absolute right-0 p-2">USD</span>
                    </div>
                    {expenseErrors[index]?.amount && <p className="text-xs mt-1 text-red-500">{expenseErrors[index].amount}</p>}
                  </div>
                  <div className="w-full sm:w-1/2">
                    <label className="block text-[11px] leading-3 font-normal text-black mb-1">Description</label>
                    <textarea
                      placeholder="Description"
                      name="description"
                      className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 w-full"
                      value={expense.description}
                      onChange={(e) => handleInputChange(e, index, "description", "deductableExpenses")}
                    />
                    {expenseErrors[index]?.description && <p className="text-xs mt-1 text-red-500">{expenseErrors[index].description}</p>}
                  </div>
                </div>
                <div>
                  <div className="flex flex-col sm:flex-row gap-5">
                    <div className="w-full sm:w-[20%] mt-4 sm:mt-0">
                      <label className="block text-[11px] leading-3 font-normal text-black mb-1">Upload Documents</label>
                      <div className="flex space-x-4">
                        <button className="hover:bg-white" onClick={() => document.getElementById(`deductableFileInput-${index}`).click()}>
                          <span className="border-[0.5px] border-[#DFDFDF] rounded-lg px-3 py-2 text-[11px] leading-3 font-normal text-black inline-block">
                            Upload file
                          </span>
                        </button>
                        <input
                          id={`deductableFileInput-${index}`}
                          type="file"
                          multiple
                          className="hidden"
                          onChange={(e) => handleFileChange(e, index, "deductable")}
                        />
                      </div>
                    </div>

                    <div className="mt-4 sm:mt-0 flex-1 w-[75%]">
                      <label className="block text-[11px] leading-3 font-normal text-black mb-1">Attached Files</label>
                      <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                        <div className="flex flex-wrap gap-2">
                          {expense?.documents?.map((file: any, fileIndex: any) => (
                            <div
                              key={fileIndex}
                              className="flex items-center justify-between rounded-lg border-[0.5px] border-[#DFDFDF] px-3 py-2 text-[11px] leading-3 font-normal text-black "
                            >
                              <a className="flex-grow text-[11px] leading-3 font-normal text-black  flex items-center" target="_blank">
                                {file.documentName || file.name}
                                <button className="text-red-500 ml-2" onClick={() => handleRemoveFile(index, fileIndex, "deductable")}>
                                  ×
                                </button>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {revenueErrors[index]?.documents && <p className="text-xs mt-1 text-red-500">{revenueErrors[index].documents}</p>}
                </div>
              </div>
            ))}
            <div className="flex justify-center items-center mb-4 boder-b boder-green-500 hover:none">
              <button className="text-sm font-medium text-green-500 mt-4 hover:bg-white underline	" onClick={handleAddExpense}>
                + Add Deductable Cost
              </button>
            </div>
            <p className="border-dashed border-[1px] border-grey-500 my-6"></p>
          </div>
        </div>
        <div>
          <h3 className="text-sm font-medium mb-4">Revenue Summary</h3>
          <div className="flex flex-col md:flex-row md:justify-between md:items-left mt-8 pl-4 pr-6 pb-3 pt-4 bg-white rounded-lg border-[1px] border-grey-500">
            <div className="mb-2 md:mb-0 md:mr-4 flex-1 text-left md:text-left">
              <span className="block text-[#8E8E8E] text-xs font-normal">Net Revenue</span>
              <br />
              <span className="font-medium text-base">{formatNumberWithCommas(String(revenueData?.netRevenue ?? 0))} USD</span>
            </div>
            <div className="mb-2 md:mb-0 md:mr-4 flex-1 text-left md:text-left">
              <span className="block text-[#8E8E8E] text-xs font-normal">Revenue Share Percentage</span>
              <br />
              <span className="font-medium text-base">{revenueSharePercentage || 0}%</span>
            </div> 
            <div className="mb-2 md:mb-0 md:mr-4 flex-1 text-left md:text-left">
              <span className="block text-[#8E8E8E] text-xs font-normal">Fixed Interest Rate Percentage</span>
              <br />
              <span className="font-medium text-base">{fixedIntrestRatePercentage || 0}%</span>
            </div>
            <div className="mb-2 md:mb-0 flex-1 text-left md:text-left">
              <span className="block text-[#8E8E8E] text-xs font-normal">Total Revenue Share to Investors</span>
              <br />
              <span className="font-medium text-base">{formatNumberWithCommas(String(revenueData?.totalRevenueShares))} USD</span>
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <Button
            secondary
            rounded
            onClick={() => {
              validateFields();
            }}
            disabled={!revenues?.length || !deductableExpenses?.length}
          >

            Submit
          </Button>
        </div>
      </div>

      {/* <RevenueSummaryBanner quarter={quarter} year={year} revenueData={revenueData} open={open.confirmTransaction} setOpen={setOpen} /> */}

      {/* Investor Distribution Component  */}
      <InvestorDistribution revenueData={revenueData} open={open.confirmTransaction} setOpen={setOpen} />

      {/* Modals */}
      <SubmitModal open={open.submit} setOpen={setOpen} handleCloseModal={handleCloseModal} handleSubmit={handleSubmit} loading={loading} />
      <ConfirmSubmitModal open={open.submitted} setOpen={setOpen} handleCloseModal={handleCloseModal} />
      <ConfirmTransactionModal
        open={open.confirmTransaction}
        setOpen={setOpen}
        handleCloseModal={handleCloseModal}
        revenueId={revenueId}
        quarter={quarter}
        year={year}
        refetch={refetch}
      />

      <SuccessConfirmTransferModal open={open.SuccessConfirmTransaction} setOpen={setOpen} handleCloseModal={handleCloseModal} />
    </>
  );
}
