import { useContext } from "react";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "@/services/AuthContext";

const useAuth = () => {
  const { authToken } = useContext(AuthContext);
  
  try {
    if (!authToken) return { userId: null, companyId: null };
    const { userId, companyId } = jwtDecode(authToken);
    return { userId, companyId };
  } catch (err) {
    return { userId: null, companyId: null };
  }
};

export default useAuth;
