export const formatNumber = (values) => {
  if (!values) return values;
  const parts = `${values}`.split(".");
  const value = parts[0].replace(/\D/g, "");
  const decimal = parts[1];
  let newValue = new Intl.NumberFormat("en-EN").format(value);

  // Prevent non-numeric decimal
  if (!isNaN(decimal)) {
    newValue = `${newValue}.${decimal}`;
  }

  // Prevent placing 0 when empty
  return value === "" && newValue === "0" ? "" : newValue;
};

// format currency input
export function currencyFormat() {
  document.querySelectorAll("input[data-format=currency]").forEach((element) => {
    element.addEventListener("input", function (e) {
      const val = e.target.value;
      formatNumber(val);
    });
  });
}



// format currency input on load
currencyFormat();
