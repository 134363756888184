const defaultState = {
  assetIds: [
    {
      model: "",
      brand: "",
      assetType: "",
      technology: "",
      powerCapacity: "",
      serialNumber: "",
    },
  ],
  general: {
    name: "",
    projectDescription: "",
    spvLocation: "",
    assetLocation: "",
    hasRevenueGeneratingAssets: false,
  },
  financing: {
    tokenPrice: "",
    tokenAmount: "",
    symbol: "",
    contractTerm: "",
    currencyForTokenSale: "",
    fundingGoal: "",
    currencyForRevenueDistribution: "",
    revenueSharePercentage: 0,
    fundingReceived: "",
    fundingUsage: [{ financingDescription: "", amount: "", title: "", currency: "" }],
  },
  valuation: {
    npv: "",
    irr: "",
    lcoe: "",
    paybackPeriod: "",
    capacityFactor: "",
    totalInitialCost: "",
    operationLifetime: "",
    financingStructure: "",
    ownershipStructure: "",
    commercialOperationDate: "",
    annualOperationCosts: "",
  },
};

const defaultTypeOfRevenue =
  [
    {
      name: "Energy Sales PPA",
      value: "PPA",
      status: false,
      projectedRevenue: "",
      yearlyOrMonthly: "",
      tooltipInfo: "Power Purchase Agreements (PPAs) for energy sales.",
    },
    {
      name: "Energy Sales Grid",
      value: "GRID",
      status: false,
      projectedRevenue: "",
      yearlyOrMonthly: "",
      tooltipInfo: "Energy sold directly to the grid.",
    },
    {
      name: "RECs",
      value: "RECS",
      status: false,
      projectedRevenue: "",
      yearlyOrMonthly: "",
      tooltipInfo: "Renewable Energy Certificates generated.",
    },
    {
      name: "Carbon Credits",
      value: "CARBONCREDITS",
      status: false,
      projectedRevenue: "",
      yearlyOrMonthly: "",
      tooltipInfo: "Credits earned for reducing carbon emissions.",
    },
    {
      name: "Rental Fees",
      value: "RENTAL",
      status: false,
      projectedRevenue: "",
      yearlyOrMonthly: "",
      tooltipInfo: "Fees collected from rental agreements.",
    },
    {
      name: "Sales",
      value: "SALES",
      status: false,
      projectedRevenue: "",
      yearlyOrMonthly: "",
      tooltipInfo: "Revenue generated from product sales.",
    },
    {
      name: "Other",
      value: "OTHER",
      status: false,
      projectedRevenue: "",
      yearlyOrMonthly: "",
      tooltipInfo: "Any other type of revenue not listed.",
    },
  ]

export {
  defaultState,
  defaultTypeOfRevenue
}