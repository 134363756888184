interface ListingCardProps {
    balance: any;
    title: string;
    Icon?: any;
    currency?: any;
}

const ListingCard = ({ balance, title, Icon, currency }: ListingCardProps) => {
    return (
        <div className="bg-white p-4 grow h-full rounded-lg">
            <div className="block sm:flex flex-row gap-4 items-start">
                <div className="mb-2 sm:mb-0">{Icon}</div>
                <div className="flex flex-column gap-2 flex-[0_0_70%] w-[70%]">
                    <span className="body-small-regular text-monochrome-30">{title}</span>
                    <h3>{balance}&nbsp;<span className="text-monochrome-30 leading-6 text-xs font-normal">{currency}</span></h3>
                </div>
            </div>
        </div>
    );
};

export default ListingCard;
