import React from "react";

const IcDashboard = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4787 8.1417V8.14169H16.4749H13.1082C12.4904 8.14169 12.2114 8.02755 12.0798 7.91113C11.9671 7.81141 11.8499 7.60738 11.8499 7.09169V3.31669C11.8499 2.80641 11.9666 2.601 12.0807 2.49959C12.2129 2.38211 12.4926 2.26669 13.1082 2.26669H16.4749C17.0905 2.26669 17.3702 2.38211 17.5024 2.49959C17.6165 2.601 17.7332 2.80641 17.7332 3.31669V7.10002C17.7332 7.61074 17.6163 7.81524 17.5033 7.9153C17.3724 8.03116 17.094 8.14557 16.4787 8.1417Z"
        stroke="#5B5B5B"
        strokeWidth="1.2"
      />
      <path
        d="M17.7332 13.1083V16.475C17.7332 17.0774 17.6084 17.345 17.4767 17.4768C17.3449 17.6086 17.0773 17.7333 16.4749 17.7333H13.1082C12.5058 17.7333 12.2382 17.6086 12.1064 17.4768C11.9747 17.345 11.8499 17.0774 11.8499 16.475V13.1083C11.8499 12.5059 11.9747 12.2383 12.1064 12.1066C12.2382 11.9748 12.5058 11.85 13.1082 11.85H16.4749C17.0773 11.85 17.3449 11.9748 17.4767 12.1066C17.6084 12.2383 17.7332 12.5059 17.7332 13.1083Z"
        stroke="#5B5B5B"
        strokeWidth="1.2"
      />
      <path
        d="M6.89534 8.1417V8.14169H6.89157H3.5249C2.9071 8.14169 2.62805 8.02755 2.4965 7.91113C2.38383 7.81141 2.26656 7.60738 2.26656 7.09169V3.31669C2.26656 2.80641 2.38325 2.601 2.49738 2.49959C2.6296 2.38211 2.90924 2.26669 3.5249 2.26669H6.89157C7.50723 2.26669 7.78686 2.38211 7.91909 2.49959C8.03322 2.601 8.1499 2.80641 8.1499 3.31669V7.10002C8.1499 7.61074 8.03302 7.81524 7.91996 7.9153C7.78905 8.03116 7.51067 8.14557 6.89534 8.1417Z"
        stroke="#5B5B5B"
        strokeWidth="1.2"
      />
      <path
        d="M8.1499 13.1083V16.475C8.1499 17.0774 8.02512 17.345 7.89334 17.4768C7.76157 17.6086 7.49397 17.7333 6.89157 17.7333H3.5249C2.92249 17.7333 2.65489 17.6086 2.52312 17.4768C2.39135 17.345 2.26656 17.0774 2.26656 16.475V13.1083C2.26656 12.5059 2.39135 12.2383 2.52312 12.1066C2.65489 11.9748 2.92249 11.85 3.5249 11.85H6.89157C7.49397 11.85 7.76157 11.9748 7.89334 12.1066C8.02512 12.2383 8.1499 12.5059 8.1499 13.1083Z"
        stroke="#5B5B5B"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default IcDashboard;
