const BegdeIcon = (props: any) => {
    return (
        <div {...props}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" />
                <path d="M28.2424 11.7656C28.0804 11.6018 27.8875 11.4719 27.6749 11.3833C27.4622 11.2948 27.2341 11.2495 27.0037 11.25H22.2018C21.8868 11.2506 21.5847 11.3754 21.3611 11.5973L11.7615 21.1949C11.434 21.5232 11.25 21.9679 11.25 22.4316C11.25 22.8954 11.434 23.3401 11.7615 23.6684L16.3318 28.2387C16.6601 28.5664 17.1051 28.7504 17.5689 28.7504C18.0328 28.7504 18.4777 28.5664 18.8061 28.2387L28.4018 18.6449C28.6241 18.4217 28.7493 18.1197 28.7502 17.8047V13C28.7516 12.7709 28.7075 12.5437 28.6203 12.3318C28.5331 12.1199 28.4046 11.9274 28.2424 11.7656ZM25.0002 16.25C24.753 16.25 24.5113 16.1767 24.3057 16.0393C24.1002 15.902 23.94 15.7068 23.8453 15.4784C23.7507 15.25 23.726 14.9986 23.7742 14.7561C23.8224 14.5137 23.9415 14.2909 24.1163 14.1161C24.2911 13.9413 24.5139 13.8223 24.7563 13.774C24.9988 13.7258 25.2501 13.7505 25.4785 13.8452C25.707 13.9398 25.9022 14.1 26.0395 14.3055C26.1769 14.5111 26.2502 14.7528 26.2502 15C26.2502 15.3315 26.1185 15.6495 25.8841 15.8839C25.6497 16.1183 25.3317 16.25 25.0002 16.25Z" fill="#00C798" />
            </svg>
        </div>
    )
}

export default BegdeIcon