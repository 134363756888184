import React, { useState, useMemo, useEffect, Dispatch, SetStateAction } from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "@components/Loader";
import useAuth from "@/hooks/useAuth";
import TransactionItem from "../../components/Transactions/TransactionItem";
import { IcNextPage, IcPreviousPage } from "@/assets";
import { fetchProjects, fetchTransactions, fetchTransactionsUsingUserId, fetchTransactionsUsingWalletId, fetchUser } from "@/services/api";
import { useNetwork } from "@/provider/NetworkContext";
import StyledDropdown from "@/components/UI/Dropdown/StyledDropdown";
import DateRangePicker from "@/components/UI/DateRangePicker";
import { ITransaction } from "@/types";

const sortingOptions = [
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
];

const transactionCategories = [
  { value: "all", label: "All Transactions" },
  { value: "buy", label: "Buy Transactions" },
  { value: "sell", label: "Sell Transactions" },
  // { value: "flipCard", label: "FlipCard Transactions" },
  { value: "SECURITY_TOKEN_PURCHASE", label: "Security Token Purchase" },
  { value: "SECURITY_TOKEN_SALE", label: "Security Token Sale" },
  { value: "SECURITY_TOKEN_TRANSFER", label: "Security Token Transfer" },
  { value: "DIVIDEND_PAYMENT", label: "Dividend Payment" },
  { value: "PAYMENT_TOKEN_TOP_UP", label: "Payment Token Top-Up" },
  { value: "PAYMENT_TOKEN_WITHDRAW", label: "Payment Token Withdraw" },
];

interface IProject {
  _id: string; // Assuming the ID is a string
  name: string; // Assuming each project has a name
}

interface IError {
  message: string; // Define the structure of your error object
}

interface ITransactionsProps {
  selectedTransaction: ITransaction | null;
  setSelectedTransaction: Dispatch<SetStateAction<ITransaction | null>>;
}

const perPageItems = [10, 25, 50, 100];

const TransactionsPage = ({ selectedTransaction, setSelectedTransaction }: ITransactionsProps) => {
  const { userId } = useAuth();
  const [perPageCount, setPerPageCount] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortOrder, setSortOrder] = useState<string>("newest");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { companyId } = useAuth();
  const { selectedNetwork } = useNetwork();
  const [selectedPlantSelection, setSelectedPlantSelection] = useState([]);
  const [selectedTransactionCategory, setSelectedTransactionCategory] = useState([{ value: "all", label: "All Transactions" }]);
  const [projects, setProjects] = useState<IProject[]>([]); // State to hold the projects
  const [error, setError] = useState<IError | null>(null);
  const [dateRange, setDateRange] = useState<string>("currentActivity");
  const [customDate, setCustomDate] = useState<[Date, Date]>([new Date(), new Date()]);
  const [allTransactions, setAllTransactions] = useState<any>([]);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const response = await fetchProjects(companyId);
        const allProjects = Array.isArray(response.data) ? response.data.flat() : response.data;
        setProjects(allProjects);
      } catch (error: any) {
        setError({ message: error.message });
      }
    };
    getProjects();
  }, []);

  const clearAllFilters = () => {
    setSelectedTransactionCategory([]);
    setSelectedPlantSelection([]);
  };

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const { data: transactions = [], isLoading } = useQuery({
    queryKey: ["getTransactions", companyId],
    queryFn: () => fetchTransactions(companyId, selectedNetwork),
    enabled: !!companyId,
    select: (data) => data.data ?? [],
  });

  const {
    data: transactionsUsingWalletId = [],
    isLoading: isLoadingTransactionsUsingWalletId,
    error: transactionsError2,
  } = useQuery({
    queryKey: ["getTransactionsUsingWalletId", userData?.walletDetails?.web3authWalletAddress],
    queryFn: () => fetchTransactionsUsingWalletId(userData?.walletDetails?.web3authWalletAddress),
    enabled: !!userData,
    select: (data) => data.data ?? [],
  });

  const {
    data: transactionsUsingUserId = [],
    isLoading: isLoadingTransactionsUsingUserId,
    error: transactionsError3,
  } = useQuery({
    queryKey: ["getTransactionsUsingUserId", userId],
    queryFn: () => fetchTransactionsUsingUserId(userId),
    // queryFn: () => fetchTransactionsUsingUserId("66e057713ad854af4ba58fa0"),
    enabled: !!userId,
    select: (data) => data.data ?? [],
  });

  useEffect(() => {
    setAllTransactions(transactions.concat(transactionsUsingWalletId.transactions, transactionsUsingUserId.transactions));
  }, [transactions, transactionsUsingWalletId, transactionsUsingUserId]);

  const REWARD_FROM_ADDRESS = "0xa7d36B87D814257ADCa79cFe521f2a3fdcDdfDDa".toLowerCase();
  const PENOMO_TOKEN_SYMBOL = "PNMO";

  const getTransactionType = (transaction: any): string => {
    const { from, tokenSymbol } = transaction;
    if (tokenSymbol === PENOMO_TOKEN_SYMBOL && from?.toLowerCase() === REWARD_FROM_ADDRESS) {
      return "reward transaction";
    }
    return transaction.transactionType;
  };

  const getCardClass = (transaction: any): string => {
    const transactionType = getTransactionType(transaction);
    return transactionType === "reward transaction" ? "cards reward-transaction" : "cards";
  };

  const sortedTransactions = useMemo(() => {
    return allTransactions.sort((a: any, b: any) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return sortOrder === "newest" ? dateB - dateA : dateA - dateB;
    });
  }, [allTransactions, sortOrder]);

  // const totalPages = useMemo(() => {
  //   return Math.ceil(sortedTransactions.length / perPageCount);
  // }, [sortedTransactions.length, perPageCount]);

  // const displayedTransactions = useMemo(() => {
  //   const startIndex = (currentPage - 1) * perPageCount;
  //   return sortedTransactions.slice(startIndex, startIndex + perPageCount);
  // }, [sortedTransactions, currentPage, perPageCount]);

  const displayedTransactions = useMemo(() => {
    const startIndex = (currentPage - 1) * perPageCount;
    return sortedTransactions.slice(startIndex, startIndex + perPageCount);
  }, [sortedTransactions, currentPage, perPageCount]);

  const filteredTransactions = useMemo(() => {
    if (!displayedTransactions || displayedTransactions.length === 0) return [];

    const searchText = searchQuery.toLowerCase();
    const currentDate = new Date();

    return displayedTransactions.filter((transaction: any) => {
      const transactionDate = new Date(transaction?.updatedAt);

      const matchesSearch =
        transaction?.updatedAt?.toLowerCase().includes(searchText) ||
        transaction?.transactionType?.toLowerCase().includes(searchText) ||
        transaction?.tokenSymbol?.toLowerCase().includes(searchText) ||
        transaction?.tokenAmount?.toString().toLowerCase().includes(searchText);

      const matchesCategory =
        selectedTransactionCategory.length === 0 || // Show all transactions if no category is selected
        selectedTransactionCategory.some((category: any) => {
          if (category.value === "all") {
            return true; // Include all transactions if "all" is selected
          }
          if (category.value === "buy" && transaction.fromUserId === userId) {
            return true; // Include transactions where the current user is the sender
          }
          if (category.value === "sell" && transaction.toUserId === userId) {
            return true; // Include transactions where the current user is the receiver
          }
          if (transaction?.transactionPurpose === category.value) {
            return true; // Include transactions matching the selected transactionPurpose
          }
          return false;
        });

      const matchesProject =
        selectedPlantSelection.length === 0 || selectedPlantSelection.some((project: any) => transaction?.tokenId?.projectId?._id == project.value);

      let matchesDateRange = true;

      if (dateRange === "custom") {
        if (customDate && customDate.length === 2) {
          const [start, end] = customDate;
          matchesDateRange = transactionDate >= start && transactionDate <= end;
        } else {
          matchesDateRange = false;
        }
      } else if (dateRange === "currentActivity") {
        const last24Hours = new Date();
        last24Hours.setHours(currentDate.getHours() - 24);
        matchesDateRange = transactionDate >= last24Hours && transactionDate <= currentDate;
      } else if (dateRange === "thisWeek") {
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        startOfWeek.setHours(0, 0, 0, 0);
        matchesDateRange = transactionDate >= startOfWeek && transactionDate <= currentDate;
      } else if (dateRange === "lastWeek") {
        const startOfLastWeek = new Date(currentDate);
        startOfLastWeek.setDate(currentDate.getDate() - currentDate.getDay() - 7);
        startOfLastWeek.setHours(0, 0, 0, 0);
        const endOfLastWeek = new Date(startOfLastWeek);
        endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
        endOfLastWeek.setHours(23, 59, 59, 999);
        matchesDateRange = transactionDate >= startOfLastWeek && transactionDate <= endOfLastWeek;
      } else if (dateRange === "thisMonth") {
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        matchesDateRange = transactionDate >= startOfMonth && transactionDate <= endOfMonth;
      } else if (dateRange === "previousMonth1") {
        const startOfPreviousMonth1 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        const endOfPreviousMonth1 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        endOfPreviousMonth1.setHours(23, 59, 59, 999); // Include the full last day
        matchesDateRange = transactionDate >= startOfPreviousMonth1 && transactionDate <= endOfPreviousMonth1;
      } else if (dateRange === "previousMonth2") {
        const startOfPreviousMonth2 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);
        const endOfPreviousMonth2 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 0);
        endOfPreviousMonth2.setHours(23, 59, 59, 999); // Include the full last day
        matchesDateRange = transactionDate >= startOfPreviousMonth2 && transactionDate <= endOfPreviousMonth2;
      } else if (dateRange === "previousMonth3") {
        const startOfPreviousMonth3 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
        const endOfPreviousMonth3 = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 0);
        endOfPreviousMonth3.setHours(23, 59, 59, 999); // Include the full last day
        matchesDateRange = transactionDate >= startOfPreviousMonth3 && transactionDate <= endOfPreviousMonth3;
      }

      return matchesSearch && matchesCategory && matchesProject && matchesDateRange;
    });
  }, [displayedTransactions, searchQuery, selectedTransactionCategory, selectedPlantSelection, dateRange, customDate]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredTransactions.length / perPageCount);
  }, [filteredTransactions.length, perPageCount]);

  const transactionUrl = selectedNetwork === "polygon" ? process.env.REACT_APP_POLYGONSCAN_TRANSACTION_URL : process.env.REACT_APP_PEAQ_SUBSCAN_TRANSACTION_URL;

  const handleRemoveCategory = (categoryToRemove: any) => {
    setSelectedTransactionCategory((prevCategories) => prevCategories.filter((category) => category.value !== categoryToRemove.value));
  };

  const handleRemovePlant = (plantToRemove: any) => {
    setSelectedPlantSelection((prevPlants) => prevPlants.filter((plant) => plant.value !== plantToRemove.value));
  };

  const handleDownloadCSV = () => {
    const csvData = [
      ["Date", "Time", "Type", "Symbols", "Amount"],
      ...displayedTransactions.map((transaction: any) => [
        transaction?.updatedAt,
        transaction.paymentTokenName,
        transaction.securityTokenName,
        transaction.paymentTokensTransferred,
      ]),
    ];
    const csvContent = "data:text/csv;charset=utf-8," + csvData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "transactions.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="transactions-page">
      <div className="mb-4 flex flex-col xl:flex-row justify-between xl:gap-4 relative">
        <div className="flex flex-col xl:w-3/5 w-full gap-4">
          <div className="flex gap-4 flex-col md:flex-row">
            <div className="flex flex-col w-full md:max-w-64">
              <label className="text-xs font-medium text-black pb-1">Transaction Period</label>
              <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} customDate={customDate} setCustomDate={setCustomDate} />
            </div>
            <div className="w-full sm:max-w-xl flex md:flex-row gap-4 flex-col [&>div]:w-full md:[&>div]:max-w-64">
              <div className="flex flex-col">
                <label className="text-xs font-medium text-black pb-1">Transaction Category</label>
                <StyledDropdown
                  options={transactionCategories}
                  onChange={(selectedValues) => setSelectedTransactionCategory(selectedValues)}
                  value={selectedTransactionCategory}
                  isSearchable={false}
                  isMulti={true}
                  placeholder="Select transactions"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-xs font-medium text-black pb-1">Project</label>
                <StyledDropdown
                  options={projects.map((project) => ({ value: project._id, label: project.name }))}
                  onChange={(selectedValues) => setSelectedPlantSelection(selectedValues)}
                  value={selectedPlantSelection}
                  isSearchable={true}
                  isMulti={true}
                  placeholder="Select Projects"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-start items-center flex-wrap gap-5">
            {selectedTransactionCategory.length > 0 &&
              selectedTransactionCategory.map((category, index) => (
                <span key={index} className="border border-green-500 bg-[#d6f3ed] text-black px-2 py-1 rounded-lg flex items-center gap-2 text-xs">
                  {category.label}
                  <button className="text-black hover:text-green-500 text-sm" onClick={() => handleRemoveCategory(category)}>
                    ✕
                  </button>
                </span>
              ))}

            {selectedPlantSelection.length > 0 &&
              selectedPlantSelection.map((plant, index) => (
                <span key={index} className="border border-green-500 bg-[#d6f3ed] text-black px-2 py-1 rounded-lg flex items-center gap-2 text-xs">
                  {plant.label}
                  <button className="text-black hover:text-green-500 text-sm" onClick={() => handleRemovePlant(plant)}>
                    ✕
                  </button>
                </span>
              ))}

            {(selectedTransactionCategory.length > 0 || selectedPlantSelection.length > 0) && (
              <button className="flex items-center gap-2 text-black underline text-xs" onClick={clearAllFilters}>
                <span className="text-black text-xs">× Clear all filters</span>
              </button>
            )}
          </div>
        </div>
        {/* <button
          className={`flex items-center md:justify-center py-[12px] max-w-max px-6 text-sm bg-[#ffffff] border-none rounded-lg text-black h-fit ${
            selectedPlantSelection.length > 0 || selectedTransactionCategory.length > 0 ? "lg:absolute lg:bottom-0 xl:mt-0 mt-5 lg:right-4" : "xl:mt-5"
          }`}
          onClick={handleDownloadCSV}
        >
          Download CSV
        </button> */}
      </div>
      {/* <div className="bg-white rounded-lg p-4"> */}
      <div className="min-h-[250px] lg:h-[80vh] overflow-x-auto sm:overflow-hidden rounded-lg bg-white">
        <div className="relative flex flex-col min-h-[250px] lg:h-full w-[200%] sm:w-full">
          <div className="text-[0.688rem] py-[10px] px-4 grid grid-cols-5 border-b border-monochrome-10 text-monochrome-30">
            <span>Date</span>
            <span>Type</span>
            <span>Symbol</span>
            <span>Time</span>
            <span>Amount</span>
          </div>
          <div className="overflow-y-auto grow min-h-[calc(100vh-280px)]">
            {filteredTransactions && filteredTransactions.length > 0 ? (
              filteredTransactions.map((transaction: any) => {
                return (
                  <TransactionItem
                    key={transaction._id}
                    item={transaction}
                    onClick={() => setSelectedTransaction(transaction)}
                    isSelected={selectedTransaction?._id === transaction._id}
                    uniqueId={`transaction-${transaction._id}`}
                    currentUserId={userId}
                    currentWalletAddress={userData.walletDetails.web3authWalletAddress}
                  />
                );
              })
            ) : (
              <div className="center text-sm min-h-40">No transactions available.</div>
            )}
          </div>
          <div className="w-full flex justify-end pt-12 pb-5 px-5 text-[14px]">
            {/* <div className="xl:absolute xl:bottom-0 w-full flex justify-between pt-[16px] text-[14px]"> */}
            {/* <div className="flex gap-3 items-center">
              <ul className="flex">
                {perPageItems.map((item: number, index: number) => (
                  <li
                    key={`page-items-${index}`}
                    onClick={() => {
                      setPerPageCount(item);
                      setCurrentPage(1); // Reset current page on per page count change
                    }}
                    className={`py-2 px-3 body-small-regular border-2 first:rounded-l-lg last:rounded-r-lg cursor-pointer ${
                      item === perPageCount ? "border-green-100 bg-green-50 text-green-100" : "border-monochrome-10 text-monochrome-30 bg-monochrome-5 "
                    }`}
                  >
                    {item}
                  </li>
                ))}
              </ul>
              <span className="text-monochrome-20 body-small-regular">Items per page</span>
            </div> */}
            <div className="flex items-center gap-2 body-small-regular">
              <span className="text-monochrome-20">Page</span>
              <input
                className="!bg-monochrome-5 !text-[12px] !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto]"
                type="text"
                value={currentPage}
                min={1}
                onChange={(e) => setCurrentPage(Number(e.target.value))}
              />
              <span className="text-monochrome-20">of {totalPages}</span>
              <div className="grid grid-cols-2 border-2 border-monochrome-10 divide-monochrome-10 divide-x rounded-lg items-center">
                <button
                  className="p-2 bg-monochrome-5 rounded-l-lg"
                  onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                  disabled={currentPage === 1}
                >
                  <IcPreviousPage />
                </button>
                <button
                  className="p-2 bg-monochrome-5 rounded-r-lg"
                  onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
                  disabled={currentPage === totalPages}
                >
                  <IcNextPage />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default TransactionsPage;
