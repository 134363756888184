import { useContext, useEffect, useState } from "react";
import KycVerificationBanner from "./KycVerificationBanner";
import useStore from "@/store/useStore";
import { IcLock } from "@/assets/Icons";
import Button from "../UI/Button";
import Loader from "../Loader";
import Switch from "react-switch";
import { z } from "zod";
import toast from "react-hot-toast";
import { http } from "@/services/http";
import { ENDPOINT } from "@/constants";
import useAuth from "@/hooks/useAuth";
import { deleteUser, fetchUser } from "@/services/api";
import KycStatus from "./KycStatus";
import useSocketIO from "@/hooks/useSocketIO";
import { AuthContext } from "@/services/AuthContext";
import { useNavigate } from "react-router-dom";
import { useWeb3Auth, web3authInstance } from "@/services/Web3AuthService";
import { Modal } from "flowbite-react";
import IcCross from "@/assets/ic_cross";
import { FaTrash } from 'react-icons/fa'; // Import the trash icon


// Zod schema for user data validation
const userDataSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  middleNames: z.string().optional(),
  email: z.string().email("Invalid email format"),
  dateOfBirth: z.string().optional(), // You might want to validate the date format
  position: z.string().min(1, "Position is required"),
  role: z.string().optional(),
});

interface IErros {
  firstName?: string;
  lastName?: string;
  middleNames?: string;
  email?: string;
  dateOfBirth?: string;
  position?: string;
  role?: string;
}

const UserBasicDetails = ({ updateUserFormStatus }) => {
  const [isSubmittingNotification, setIsSubmittingNotification] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { userData, setUser } = useStore();
  const { userId } = useAuth();
  const [formData, setFormData] = useState({
    firstName: userData.firstName ?? "",
    lastName: userData.lastName ?? "",
    middleNames: userData.middleNames ?? "",
    email: userData.email ?? "",
    dateOfBirth: userData.dateOfBirth ?? "",
    position: userData.position ?? "",
    role: userData.role ?? "",
  });

  const [isEmailNotificationEnabled, setIsEmailNotificationEnabled] = useState(userData?.emailNotifications ?? false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState<IErros>({});
  const [kycStatus, setKycStatus] = useState("Not Submitted");
  const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const { web3auth } = useWeb3Auth();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { reset } = useStore();
  const [loading, setLoading] = useState(false);

  useSocketIO(userId, (data) => {
    if (data.kycStatus) {
      setKycStatus(data.kycStatus); // Update the kycStatus state dynamically
      setFormData((prevFormData) => ({
        ...prevFormData,
        firstName: data.firstName,
        lastName: data.lastName,
      }));
      toast.success(`KYC status updated to ${data.kycStatus}`);
    } else {
      toast.error(data.message);
    }
  });

  const isVerified = kycStatus === "Verified";

  useEffect(() => {
    const fetchEmailNotifyStatus = async () => {
      try {
        const response = await fetchUser(userId);
        if (response.code === 200 && response.data) {
          const userDetails: any = response.data;
          setUser(userDetails); // Update user details
          setKycStatus(userDetails.kycStatus);
          setIsEmailNotificationEnabled(userDetails.emailNotifications);
        }
      } catch (error) {
        console.error("Error fetching email notification status:", error);
      }
    };

    fetchEmailNotifyStatus();
  }, [userId, setUser]);

  useEffect(() => {
    const allFieldsFilled =
      formData.firstName.trim() !== "" && formData.lastName.trim() !== "" && formData.email.trim() !== "" && formData.position.trim() !== "";
    updateUserFormStatus(!allFieldsFilled);
  }, [formData, updateUserFormStatus]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const fieldValidationResult = userDataSchema.pick({ [name]: true }).safeParse({ [name]: value });
    if (!fieldValidationResult.success) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldValidationResult.error.flatten().fieldErrors[name][0],
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!isEditMode) return;

    const result = userDataSchema.safeParse(formData);
    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors);
      toast.error("Please correct the errors before submitting.");
      return;
    }

    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      toast.error("You must be logged in to submit this form.");
      return;
    }

    setIsSubmittingForm(true);
    try {
      const response: any = await http.patch(`${ENDPOINT.USER}/${userData._id}`, formData);
      if (response.status === 200 && response.data.data) {
        setUser(response.data.data);
        toast.success(response.data.message);
        setIsEditMode(false);
      } else {
        toast.error("Failed to update user details.");
      }
    } catch (error) {
      toast.error("Failed to update user details.");
    } finally {
      setIsSubmittingForm(false);
    }
  };

  const handleToggleChange = async () => {
    const endpoint = `${ENDPOINT.USER}/${userId}`;

    setIsSubmittingNotification(true);
    try {
      await http.patch(endpoint, {
        emailNotifications: !isEmailNotificationEnabled,
      });
      setIsEmailNotificationEnabled(!isEmailNotificationEnabled);
      toast.success("Email notification setting updated successfully!");
    } catch (error) {
      console.error("Error updating email notification setting:", error);
      toast.error("Failed to update email notification setting.");
    } finally {
      setIsSubmittingNotification(false);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    deleteUser(userData?._id)
      .then((res: any) => {
        if (res.code == 200) {
          toast.success("User deleted successfully");
          handleLogout();
        } else {
          toast.error("Failed to delete user");
        }
      })
      .catch((err) => {
        console.error("Error deleting user:", err);
        toast.error("Failed to delete user");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogout = async () => {
    try {
      if (web3authInstance.connected) {
        // Check if the user is connected before logging out
        await web3auth.logout();
      }
    } catch (error) {
      console.error("Web3Auth logout error:", error);
    }

    logout(); // Log out from your application
    reset(); // reset store data
    localStorage.removeItem("authToken");
    localStorage.removeItem("web3AuthToken");
    navigate("/"); // Redirect to the home page
    window.location.reload(); //ensure a fresh state
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col sm:flex-row bg-white p-4 rounded divide-y sm:divide-y-0 sm:divide-x divide-monochrome-10">
        <div className="flex flex-col gap-2 sm:w-4/12 pb-4 md:pb-0">
          <h6>Basic Details</h6>
          <p className="text-monochrome-20 body-small-regular">Manage essential account information</p>
        </div>
        <form className="flex flex-col body-small-regular gap-6 w-full sm:pl-8 pt-4 lg:pt-0 sm:pr-6 sm:w-8/12" onSubmit={handleSubmit}>
          <div className="flex gap-4">
            <div className="relative flex flex-col gap-3 w-3/6">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="Enter your first name here"
                disabled={!isEditMode}
                className={`w-full px-0 ${isEditMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <p className="text-red-500 error">{errors.firstName}</p>}
            </div>
            <div className="relative flex flex-col gap-3 w-3/6">
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Enter your last name here"
                disabled={!isEditMode}
                className={`w-full px-0 ${isEditMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <p className="text-red-500 error">{errors.lastName}</p>}
            </div>
          </div>
          <div className="relative flex flex-col gap-3">
            <label>Position</label>
            <input
              type="text"
              name="position"
              placeholder="Enter your position here"
              disabled={!isEditMode}
              className={`w-full px-0 ${isEditMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
              value={formData.position}
              onChange={handleChange}
            />
            {errors.position && <p className="text-red-500 error">{errors.position}</p>}
          </div>
          <div className="relative flex flex-col gap-3">
            <label>Email</label>
            <div className="flex flex-row items-center border-b">
              <IcLock className="text-monochrome-30 mr-3" />
              <input
                type="text"
                name="email"
                placeholder="Enter your email here"
                disabled
                className={`w-full px-0 bg-transparent !border-none rounded-none`}
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="relative flex flex-col gap-3">
            <label className="mt-3 text-sm">KYC Status</label>
            <KycStatus status={kycStatus} />
          </div>
          <div className="flex gap-4 justify-end">
            <Button primary rounded type="button" classNames="h-10" onClick={() => setIsEditMode(!isEditMode)} disabled={isSubmittingForm}>
              {isEditMode ? "Cancel" : "Edit"}
            </Button>
            {isEditMode && (
              <Button
                secondary
                rounded
                type="submit"
                classNames={`h-10 ${isSubmittingForm ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={isSubmittingForm}
              >
                {isSubmittingForm ? <Loader isLoading={true} /> : "Save"}
              </Button>
            )}
          </div>
        </form>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row bg-white p-4 rounded divide-y md:divide-y-0 md:divide-x divide-monochrome-40">
          <div className="flex flex-col gap-2 md:w-6/12 pb-2 md:pb-0">
            <h6>Email Notification</h6>
            <p className="text-monochrome-20 body-small-regular">Manage preferences for receiving email notifications</p>
          </div>
          <div className="flex items-center gap-2 md:w-6/12 md:pl-6 pt-2 md:pt-0 body-small-regular mobile-space">
            <Switch
              offColor="#fff"
              onColor="#00cc9c"
              activeBoxShadow={""}
              offHandleColor={"#9E9E9E"}
              onHandleColor={"#fff"}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={18}
              disabled={isSubmittingNotification}
              borderRadius={9}
              className={`overflow-hidden border-2 rounded-full border-monochrome-10 ${isEmailNotificationEnabled ? "border-green-500" : ""}`}
              onChange={handleToggleChange}
              checked={isEmailNotificationEnabled}
            />
            Receive Email Notifications
            {isSubmittingNotification && <Loader isLoading={true} />}
          </div>
        </div>
      </div>
      <KycVerificationBanner isVerified={isVerified} customerId={userId} />

      <div className="flex justify-center">
        <Button
          classNames="md:w-[250px] lg:w-[200px] hover:bg-monochrome-10 flex xl:w-auto text-red-500 !text-[0.729rem] font-normal !mx-auto !border-danger-100"
          primary={true}
          rounded={true}
          onClick={() => setDeleteAccountModalOpen(true)}
        >
          Delete Account        <FaTrash /> {/* Trash icon */}

        </Button>
      </div>

      <Modal
        show={isDeleteAccountModalOpen}
        onClose={() => {
          setDeleteAccountModalOpen(false);
        }}
        position={"center"}
        size={"2xl"}
      >
        <Modal.Body className="p-6 ">
          <div className={"flex justify-between items-center pb-5"}>
            <h3 className="mb-0 font-semibold">Delete Account</h3>
            <Button
              classNames="w-[32px] border border-monochrome-40 !px-2 !rounded-xl"
              rounded
              onClick={() => {
                setDeleteAccountModalOpen(false);
              }}
            >
              <IcCross />
            </Button>
          </div>

          <hr className="border-monochrome-10" />

          <section className="pt-4">
            <p className="text-base">Are you sure you want to delete your account? This action cannot be undone.</p>
            <div className="flex justify-end gap-4 pt-4">
              <Button onClick={() => setDeleteAccountModalOpen(false)} disabled={loading} primary classNames="flex rounded-lg font-normal">
                Cancel
              </Button>
              <Button onClick={handleDelete} disabled={loading} secondary classNames="flex rounded-lg font-medium bg-danger-100">
                {loading ? <Loader isLoading={true} /> : "Delete"}
              </Button>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserBasicDetails;
