import React, { createContext, useState, useContext } from "react";

// Create context
const NetworkContext = createContext({
  selectedNetwork: "peaq",
  setSelectedNetwork: (network: string) => { },
  showAllNotification: false,
  setShowAllNotifications: (show: boolean) => { },
});

// Custom hook to use the context
export const useNetwork = () => useContext(NetworkContext);

// Provider component
export const NetworkProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedNetwork, setSelectedNetwork] = useState<string>(() => {
    // Get the stored network from localStorage if available, default to "polygon"
    return localStorage.getItem("selectedNetwork") || "peaq";
  });
  const [showAllNotification, setShowAllNotifications] = useState<boolean>(false);

  // Function to update the selected network and store it in localStorage
  const updateNetwork = (network: string) => {
    setSelectedNetwork(network);
    localStorage.setItem("selectedNetwork", network);
  };

  return (
    <NetworkContext.Provider
      value={{
        selectedNetwork,
        setSelectedNetwork: updateNetwork,
        showAllNotification,
        setShowAllNotifications,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};
