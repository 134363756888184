import { useQuery } from "@tanstack/react-query";
import useAuth from "@/hooks/useAuth";
import Loader from "./Loader";
import { fetchUser } from "@services/api";
import { Modal } from "flowbite-react";
import Button from "./UI/Button";
import { FaRegCopy } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { useState } from "react";
import { useNetwork } from "@/provider/NetworkContext";
import { getNetworkImageSrc } from "@/constants";

const TopUpWallet = ({ closeModal }: { closeModal: () => void }) => {
  const { userId } = useAuth();
  const [showTick, setShowTick] = useState<boolean>(false);
  const { selectedNetwork } = useNetwork();
  console.log("🚀 ~ TopUpWal ~ selectedNetwork:", selectedNetwork)

  const { data: userData, isLoading: isLoadingUser } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data.data,
  });

  const handleCopy = (value: string | undefined) => {
    if (value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          console.log("Text copied to clipboard");
          setShowTick(true);
          // Hide the tick icon after 2 seconds
          setTimeout(() => {
            setShowTick(false);
          }, 2000);
        })
        .catch(err => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  return (
    <Modal show={true} onClose={closeModal} position={"center"}>
      {/* <Modal.Header data-testid="top-up-wallet-modal-1" className="p-4 bg-monochrome-100 text-white"><h3 className="flex text-white text-xl font-semibold	">Top Up </h3></Modal.Header> */}

      <Modal.Body className="p-6 bg-white text-black flex flex-col gap-3">
        {/* header title and cross icon  */}
        <div className="flex flex-row justify-between ">
          <h3 className="flex text-black text-xl font-semibold	">Top Up </h3>
          <img src="./assets/modalCross.svg " alt="cross" onClick={closeModal} className="flex cursor-pointer" />
        </div>
        {/* divider line  */}

        <hr className="w-full border-t border-[#dad1d1]" />
        {/* Top Headings  */}
        <div className="flex flex-row gap-2 py-6">
          <img
            src= "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035"
            alt="currency icon"
            className="flex w-10 h-10"
          />
          <div className="flex flex-col gap-3">
            <h3 className="leading-6	">Top Up <span className="d-flex uppercase">{selectedNetwork === "polygon" ? "USDC" : "USDC"}</span> via <span className="d-flex uppercase">{selectedNetwork}</span> Mainnet</h3>
            <span className="text-monochrome-20 text-xs">Send only <span className="d-flex uppercase">{selectedNetwork === "polygon" ? "USDC" : "USDC"}</span> on the <span className="d-flex uppercase">{selectedNetwork}</span> Mainnet</span>
          </div>
        </div>



        <div className="flex flex-col flex-wrap py-4 gap-2 bg-gray-100 p-5 rounded-lg">
          <p className="text-black text-xs">Send to this wallet</p>
          <div className="flex w-full">
            <Loader isLoading={isLoadingUser} />
            <strong className="flex flex-row whitespace-pre-wrap">
              {userData?.walletDetails?.web3authWalletAddress && (
                <>
                  {/* For mobile (up to 768px), show sliced address */}
                  <span className="block lg:hidden text-sm">
                    {`${userData.walletDetails.web3authWalletAddress.slice(0, 25)}...${userData.walletDetails.web3authWalletAddress.slice(-4)}`}
                  </span>

                  {/* For tablet and larger (768px and above), show full address */}
                  <span className="hidden lg:block">
                    {userData.walletDetails.web3authWalletAddress}
                  </span>
                </>
              )}
            </strong>

          </div>


        </div>
        {/* footer buttons  */}
        <div className="flex flex-col md:flex-row justify-center gap-4">
          <Button
            rounded
            primary
            onClick={() => handleCopy(userData.walletDetails?.web3authWalletAddress)}
            classNames="flex w-full"
          >


            {showTick ? 'Copied' : 'Copy Wallet Address'} {/* Conditionally render the tick icon */}
            {showTick ? <TiTick className="ml-2" /> : <FaRegCopy className="ml-2" />} {/* Conditionally render the tick icon */}
          </Button>
          <Button rounded primary onClick={closeModal} classNames="flex w-full">
            Close
          </Button>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="p-6 bg-monochrome-100 text-white flex justify-center"> */}
      {/* </Modal.Footer> */}
    </Modal>
  );
};

export default TopUpWallet;
