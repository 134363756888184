const IcCopy = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1114 7.10254H8.6128C8.19898 7.10387 7.8027 7.25176 7.51107 7.51368C7.21944 7.77561 7.05634 8.13012 7.05762 8.4993V15.7699C7.05762 16.5411 7.75402 17.1667 8.6128 17.1667H15.1114C15.5252 17.1653 15.9215 17.0175 16.2132 16.7555C16.5048 16.4936 16.6679 16.1391 16.6666 15.7699V8.4993C16.6666 7.72812 15.9702 7.10254 15.1114 7.10254Z" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
        <path d="M12.9422 7.10269V5.23025C12.9422 4.8598 12.7782 4.50449 12.4866 4.24287C12.3427 4.11328 12.1714 4.01039 11.9827 3.94013C11.794 3.86988 11.5915 3.83363 11.3871 3.8335L4.88843 3.8335C4.47641 3.8335 4.08055 3.98052 3.78971 4.24287C3.49746 4.50536 3.33339 4.86028 3.33325 5.23025L3.33325 12.5009C3.33325 12.8713 3.49725 13.2266 3.7889 13.4883C4.08054 13.7506 4.4756 13.8976 4.88843 13.8976H7.0576" stroke="#8E8E8E" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  )
}

export default IcCopy