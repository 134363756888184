const IcDropdownChecked = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 8.25L7.49305 14.5L17.5 2" stroke="#00CC9C" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </div>
  )
}

export default IcDropdownChecked;