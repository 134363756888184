const IcWallet = (props: any) => {
    return (
        <div {...props}>
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="40" height="40" rx="8" fill="#E8FFF9" />
                <path d="M22.8748 13.6502C22.8414 13.8586 22.8248 14.0669 22.8248 14.2752C22.8248 16.1502 24.3414 17.6661 26.2081 17.6661C26.4164 17.6661 26.6164 17.6419 26.8248 17.6086V24.3327C26.8248 27.1586 25.1581 28.8336 22.3248 28.8336H16.1673C13.3331 28.8336 11.6664 27.1586 11.6664 24.3327V18.1669C11.6664 15.3336 13.3331 13.6502 16.1673 13.6502H22.8748ZM23.0423 18.7169C22.8164 18.6919 22.5923 18.7919 22.4581 18.9752L20.4423 21.5836L18.1331 19.7669C17.9914 19.6586 17.8248 19.6161 17.6581 19.6336C17.4923 19.6586 17.3423 19.7494 17.2414 19.8827L14.7756 23.0919L14.7248 23.1669C14.5831 23.4327 14.6498 23.7744 14.8998 23.9586C15.0164 24.0336 15.1414 24.0836 15.2831 24.0836C15.4756 24.0919 15.6581 23.9911 15.7748 23.8336L17.8664 21.1411L20.2414 22.9252L20.3164 22.9744C20.5831 23.1161 20.9164 23.0502 21.1081 22.7994L23.5164 19.6919L23.4831 19.7086C23.6164 19.5252 23.6414 19.2919 23.5498 19.0836C23.4589 18.8752 23.2581 18.7336 23.0423 18.7169ZM26.3249 12.1667C27.4332 12.1667 28.3332 13.0667 28.3332 14.175C28.3332 15.2833 27.4332 16.1833 26.3249 16.1833C25.2165 16.1833 24.3165 15.2833 24.3165 14.175C24.3165 13.0667 25.2165 12.1667 26.3249 12.1667Z" fill="#00C798" />
            </svg>
        </div>
    )
}

export default IcWallet