import React, { useRef, useState } from "react";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";

const AddDocumentModal = ({ show, onClose, onConfirm, documentToAdd, documentName, existingDocumentText }: any) => {
  // Use state to store file and text input
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [documentText, setDocumentText] = useState(existingDocumentText || "");
  console.log("🚀 ~ AddDocumentModal ~ documentText:", documentText);

  // Reference for the file input
  const fileInputRef = useRef<any>(null);

  // Function to trigger file input click
  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file selection
  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file); // Store the selected file in state
    }
  };

  // Function to handle file drop
  const handleDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file); // Store the dropped file in state
    }
  };

  // Function to handle drag over event
  const handleDragOver = (e: any) => {
    e.preventDefault(); // Prevent default behavior to allow drop
  };

  // Function to handle the "Save" button click
  const handleConfirm = () => {
    if (onConfirm) {
      // Pass the file and textarea data to the onConfirm function
      onConfirm({ file: selectedFile, text: documentText });
    }
  };

  return (
    <Modal show={show} onClose={onClose} position={"center"} size={"2xl"}>
      <Modal.Body className="p-4">
        <section className="flex flex-col gap-5">
          <div className="flex flex-row justify-between items-center">
            <div>
              <h5>
                {documentName
                  ?.split("_")
                  ?.join(" ")
                  ?.toLowerCase()
                  ?.replace(/\b\w/g, (char: any) => char.toUpperCase())}
              </h5>
              <p className="text-monochrome-30 text-xs mt-1">Upload a document or enter the content manually</p>
            </div>
            <div>
              <img alt="cross" src="../assets/modalCross.svg" className="flex cursor-pointer" onClick={onClose} />
            </div>
          </div>
          <hr className="w-full h-1 p-0" />

          <div className="flex flex-col gap-1">
            <p className="text-xs font-normal">Upload File</p>
            {selectedFile && (
              <div className="flex flex-row w-fit gap-2 items-center p-2 px-2 bg-monochrome-5 rounded-lg">
                <span className="flex flex-row gap-2 items-center">
                  <img src="../assets/fileIcon.svg" alt="file" /> <p className="flex text-xs">{selectedFile?.name}</p>
                </span>

                <img src="../assets/cross.svg" alt="cross" className="flex font-bold cursor-pointer" onClick={() => setSelectedFile(null)} />
              </div>
            )}
            {!selectedFile && (
              <div
                className="flex flex-col p-4 gap-4 justify-center w-full items-center border border-spacing-x-4 rounded-lg border-dashed border-monochrome-10"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <img src="../assets/upload.svg" alt="upload" className="w-[32px] h-[32px]" />
                <div className="flex flex-col gap-2 items-center">
                  <p className="text-xs font-semibold">Choose a file or drag & drop it here</p>
                  <p className="text-xs text-monochrome-30">Please upload a single file only</p>
                </div>

                {/* Hidden file input */}
                <input type="file" ref={fileInputRef} onChange={handleFileChange} className="hidden" />

                <Button
                  primary={true}
                  rounded={true}
                  classNames="gap-2 border-monochrome-30 items-center cursor-pointer !justify-start border p-2 rounded-lg flex bg-transparent !px-4"
                  onClick={handleBrowseClick}
                >
                  Browse File
                </Button>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <p className="text-xs font-normal">Document Text</p>
            <div className="flex flex-col p-1 gap-4 justify-center w-full items-center border border-spacing-x-4 rounded-lg border-monochrome-10">
              <textarea
                className="w-full text-xs border-none"
                rows={10}
                defaultValue={documentText}
                onChange={(e) => setDocumentText(e.target.value)} // Update textarea content
                placeholder="Enter document text"
              />
            </div>

            <div className="flex flex-col md:flex-row gap-2 justify-end mt-2">
              <Button
                primary={true}
                rounded={true}
                classNames="gap-2 border-monochrome-30 items-center cursor-pointer !justify-start border p-2 rounded-lg flex bg-transparent !px-4"
                onClick={onClose} // Handle cancel click
              >
                Cancel
              </Button>
              <Button
                secondary={true}
                rounded={true}
                // classNames="gap-2 border-monochrome-30 items-center cursor-pointer !justify-start border p-2 rounded-lg flex bg-transparent !px-4"
                onClick={handleConfirm} // Trigger handleConfirm to pass data to onConfirm
              >
                Save
              </Button>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default AddDocumentModal;
