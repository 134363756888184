import React from 'react'
import ProjectModal from '../Modal'
import { ProjectWarning } from '@/assets/Icons'
import Button from '@/components/UI/Button'
import { investorTransferComplete } from '@/services/api'

const ConfirmTransactionModal = ({open, setOpen,handleCloseModal,revenueId,refetch,quarter,year}:any) => {
    const initialState = { submit: false, submitted: false, confirmTransaction: false, SuccessConfirmTransaction: false };

    const handleTransfer = async()=>{
        try {
            const reqBody = {
                quarter:quarter,
                year:year
            }
            await investorTransferComplete(revenueId,reqBody);
            setOpen({...open, SuccessConfirmTransaction:true})
            await refetch();
        } catch (error) {
            console.error('Error submitting status of Investors:', error);
        }
        
    }


  return (
    <div>
        <ProjectModal open={open} onClose={handleCloseModal}>
                <section className="p-4 flex flex-col gap-7 items-center">
                    <ProjectWarning />
                    <div className="flex flex-col gap-2 items-center w-full">
                        <h3>Are you sure you want to confirm the transfer ?</h3>
                        <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
                            Please ensure you have transferred the revenue share to all investors
                        </p>
                    </div>
                    <div className='flex items-center w-full gap-[10px]'>
                        <Button primary rounded classNames="w-full" onClick={handleTransfer}>
                            Yes
                        </Button>
                        <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
                            No
                        </Button>
                    </div>
                </section>
            </ProjectModal>
      
    </div>
  )
}

export default ConfirmTransactionModal
