const IcTotalFinancingReceived = (props: any) => {
    return (
        <div {...props}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="8" fill="#E8FFF9" />
                <path d="M28.3334 20.5166V22.2333C28.3334 22.7 27.95 23.0833 27.475 23.0833H25.8667C24.9667 23.0833 24.1417 22.425 24.0667 21.525C24.0167 21 24.2167 20.5083 24.5667 20.1666C24.875 19.85 25.3 19.6666 25.7667 19.6666H27.475C27.95 19.6666 28.3334 20.05 28.3334 20.5166Z" fill="#00C798" />
                <path d="M22.8166 21.6333C22.7416 20.7583 23.0582 19.9 23.6916 19.275C24.2249 18.7333 24.9666 18.4167 25.7666 18.4167H26.2416C26.4749 18.4167 26.6666 18.225 26.6332 17.9917C26.4082 16.375 25.0082 15.125 23.3332 15.125H14.9999C13.1582 15.125 11.6666 16.6167 11.6666 18.4583V24.2917C11.6666 26.1333 13.1582 27.625 14.9999 27.625H23.3332C25.0166 27.625 26.4082 26.375 26.6332 24.7583C26.6666 24.525 26.4749 24.3333 26.2416 24.3333H25.8666C24.2832 24.3333 22.9499 23.15 22.8166 21.6333ZM20.8332 19.9167H15.8332C15.4916 19.9167 15.2082 19.6417 15.2082 19.2917C15.2082 18.9417 15.4916 18.6667 15.8332 18.6667H20.8332C21.1749 18.6667 21.4582 18.95 21.4582 19.2917C21.4582 19.6333 21.1749 19.9167 20.8332 19.9167Z" fill="#00C798" />
                <path d="M21.8417 13.3166C22.0584 13.5416 21.8667 13.875 21.55 13.875H15.025C14.1167 13.875 13.2667 14.1416 12.5584 14.6C12.2334 14.8083 11.7917 14.5833 11.95 14.225C12.4167 13.1333 13.5084 12.375 14.7667 12.375H19.45C20.4167 12.375 21.275 12.7166 21.8417 13.3166Z" fill="#00C798" />
            </svg>
        </div>
    )
}

export default IcTotalFinancingReceived