import { useState, useMemo } from "react";
import { IcNextPage, IcPreviousPage } from "@/assets";
import FundingRecievedBanner from "../../Overview/FundingRecievedBanner";
import ListingCard from "./ListingCard";
import { BegdeIcon, IcCopy, IcWallet } from "@/assets/Icons";
import { formatNumberWithCommas } from "@/utils/commanFunctions";
import { IcDropdownChecked, IcFillDashboard, IcFillProjects } from "@/assets/menu";
import CheckBox from "@/components/UI/CheckBox";
import { useQuery } from "@tanstack/react-query";
import { fetchTokenById, getAllTokenHolders } from "@/services/api";
import toast from "react-hot-toast";
const perPageItems = [10, 25, 50, 100];

export default function ListingPerformance({ projectData, refetch }: any) {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPageItems[0]);

  const projectId = projectData?._id;

  const { data: tokenData, isLoadingProjects } = useQuery({
    queryKey: ["fetchTokenById", projectId],
    queryFn: () => fetchTokenById(projectId),
    enabled: !!projectId,
    select: (res) => res.data,
  });

  const tokenId = projectData?.tokenIds[0]?._id;

  const { data: holdersData, isLoading } = useQuery({
    queryKey: ["getAllTokenHolders", tokenId],
    queryFn: () => getAllTokenHolders(tokenId),
    enabled: !!tokenId,
    select: (res) => res.data,
  });
  console.log("Holders Data: ", holdersData);

  const filteredItems = useMemo(() => {
    return (holdersData || []).filter((item) =>
      `${item.investorDetails.firstName} ${item.investorDetails.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [holdersData, searchQuery]);

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredItems.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredItems, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePageChange = (direction: string) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Copy to clipboard function
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!"); // Optional toast notification
  };

  const redirectToContract = (link: any) => {
    window.open(`${process.env.REACT_APP_POLYGONSCAN_TRANSACTION_TOKEN}/${link}`, "_blank");
  };

  return (
    <div className="flex flex-col gap-[20px] w-full">
      <div className="flex items-center justify-between">
        <h3>Listing Performance</h3>
        {/* <div className="flex items-center justify-center gap-[10px]">
                    <h4> Advance Info</h4>
                    <CheckBox />
                </div> */}
      </div>
      <section className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
        <div className="">
          <ListingCard
            balance={`${projectData?.tokenPrice ? formatNumberWithCommas(String(projectData?.tokenPrice ?? 0)) : "N/A"}`}
            Icon={<BegdeIcon className={"h-10 w-10 bg-[#E8FFF9] rounded-full flex aligns-center"} />}
            title="Token Price"
            currency="USD"
          />
        </div>
        <div className="">
          <ListingCard
            balance={`${projectData?.tokenAmount ? formatNumberWithCommas(String(projectData?.tokenAmount ?? 0)) : "0"}`}
            Icon={<IcFillProjects className={"h-10 w-10 bg-[#E8FFF9] rounded-full p-2 flex aligns-center justify-center"} />}
            title="Token Amount"
          />
        </div>
        <div className="">
          <ListingCard
            balance={`${tokenData?.tokenSold ? formatNumberWithCommas(String(tokenData?.tokenSold ?? 0)) : "0"}`}
            Icon={<IcDropdownChecked className={"h-10 w-10 bg-[#E8FFF9] rounded-full p-2 flex items-center justify-center"} />}
            title="Token Sold"
          />
        </div>
        <div className="">
          <ListingCard
            balance={`${tokenData?.numberOfInvestors ? formatNumberWithCommas(String(tokenData?.numberOfInvestors ?? 0)) : "0"}`}
            Icon={<IcFillDashboard className={"h-10 w-10 bg-[#E8FFF9] rounded-full p-2 flex items-center justify-center"} />}
            title="Number of Investor"
          />
        </div>
      </section>
      {/* Token Contract Section */}
      {/* Token Contract Section */}
      <div className="w-full flex">
        <div className="w-full bg-white rounded-lg p-4 flex items-center">
          <div className="flex-shrink-0 mr-4 ">
            <IcWallet className="h-10 w-10 bg-[#E8FFF9] rounded-full p-2 flex items-center justify-center" />
          </div>
          <div className="flex-grow w-[85%]">
            <h4 className="text-left text-sm font-medium text-gray-500 mb-1">Token Contract</h4>
            <div className="flex items-center space-x-2 w-full">
              {tokenData?.tokenContract ? (
                <div className="flex items-center gap-2">
                  <p
                    onClick={() => redirectToContract(tokenData.tokenContract)}
                    className="w-full cursor-pointer text-lg text-green-500 hover:underline !break-all"
                  >
                    {tokenData.tokenContract}
                  </p>
                  <button
                    onClick={() => copyToClipboard(tokenData.tokenContract)}
                    className="text-lg text-white hover:text-white focus:outline-none hover:bg-slate-200"
                    title="Copy to clipboard"
                  >
                    <IcCopy className="h-5 w-5 hover:fill-current text-white" />
                  </button>
                </div>
              ) : (
                <span className="text-lg">N/A</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <FundingRecievedBanner projectData={projectData} refetch={refetch} />
      <div className="bg-white rounded-lg p-4">
        <div className="grid sm:grid-cols-2 justify-between items-center gap-4 sm:gap-0 mb-4">
          <h4>Last Transactions</h4>
          <div className="relative w-[210px] sm:w-[230px] md:w-[300px]">
            {/* <input
                            type="text"
                            placeholder="Search investors..."
                            className="!w-full !border-none body-small-regular !text-[12px] !m-0 !bg-monochrome-5 !rounded-lg"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        /> */}
          </div>
        </div>
        <div className="overflow-x-auto lg:overflow-hidden">
          <div className="xl:relative w-[220%] lg:w-full">
            <div className="grid grid-cols-4 body-small-regular text-monochrome-30 py-3 border-b border-monochrome-10">
              <span className="text-start">Investor</span>
              <span className="text-start">Investor's Holdings</span>
              <span className="text-start">Amount</span>
              <span className="text-start">Percentage of Funding</span>
            </div>
            <div className="">
              {isLoading ? (
                <p>Loading...</p>
              ) : paginatedItems.length > 0 ? (
                paginatedItems.map((item, index) => (
                  <div key={index} className={`grid grid-cols-4 py-3 border-b border-monochrome-10 ${index === paginatedItems.length - 1 ? "mb-[55px]" : ""}`}>
                    <span className="text-monochrome-60 body-regular text-start my-auto">
                      {item.investorDetails.firstName} {item.investorDetails.lastName}
                    </span>
                    <span className="text-monochrome-60 body-regular text-start my-auto">{formatNumberWithCommas(String(item.totalAmount ?? 0))} tokens</span>
                    <span className="text-monochrome-60 body-regular text-start my-auto">
                      {formatNumberWithCommas(String((item.totalAmount ?? 0) * 500))} USD
                    </span>
                    <span className="text-monochrome-60 body-regular text-start my-auto">{((item.totalAmount * 500) / projectData.fundingGoal) * 100} %</span>
                  </div>
                ))
              ) : (
                <p className="text-monochrome-60 text-[0.75rem] text-start my-auto pt-4">No Token Holders.</p>
              )}
            </div>
            <div className="xl:absolute xl:bottom-[-55px] w-full flex justify-between pt-[16px] text-[14px]">
              <div className="flex gap-3 items-center">
                {/* <ul className="flex">
                                    {perPageItems.map((item: number, index: number) => (
                                        <li
                                            key={`page-items-${index}`}
                                            onClick={() => {
                                                setItemsPerPage(item);
                                                setCurrentPage(1);
                                            }}
                                            className={`py-2 px-3 body-small-regular border-2 first:rounded-l-lg last:rounded-r-lg cursor-pointer bg-monochrome-5 ${item === itemsPerPage
                                                ? "border-green-100 bg-status-approved text-white"
                                                : "border-monochrome-10 text-monochrome-30"
                                                }`}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                                <span className="text-monochrome-20 body-small-regular">Items per page</span> */}
              </div>
              <div className="flex items-center gap-2 body-small-regular">
                <span className="text-monochrome-20">Page</span>
                <input
                  className="!bg-monochrome-5 !text-[12px] !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto] !border-0 p-2 rounded-l-lg !leading-[20px]"
                  type="text"
                  min={1}
                  value={currentPage}
                  onChange={(e) => {
                    const page = Math.max(1, Math.min(totalPages, Number(e.target.value)));
                    setCurrentPage(page);
                  }}
                />
                <span className="text-monochrome-20">of {totalPages}</span>
                <div className="grid grid-cols-2 border-2 border-monochrome-10 divide-monochrome-10 divide-x rounded-lg items-center">
                  <button className="p-2 bg-monochrome-5 rounded-l-lg" onClick={() => handlePageChange("prev")} disabled={currentPage === 1}>
                    <IcPreviousPage />
                  </button>
                  <button className="p-2 bg-monochrome-5 rounded-r-lg" onClick={() => handlePageChange("next")} disabled={currentPage === totalPages}>
                    <IcNextPage />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
