
// // import React, { useContext, useEffect, useState } from "react";
// // import toast from "react-hot-toast";
// // import Web3 from "web3";
// // import { AuthContext } from "@/provider/AuthContext";
// // import { Modal } from "flowbite-react";
// // import Button from "./UI/Button";
// // import IcDropdownChecked from "@/assets/menu/ic_dropdown_checked";
// // import USDC_ABI from "./usdc_abi.json"; 

// // interface IProps {
// //   closeModal: () => void;
// //   symbol: string;
// // }

// // const USDC_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"; // USDC contract on Polygon Mainnet
// // const POL_ADDRESS = "0x0000000000000000000000000000000000001010"; // Native POL/MATIC address

// // const WithdrawWallet = ({ closeModal }: IProps) => {
// //   const { provider } = useContext(AuthContext);
// //   const [selectedToken, setSelectedToken] = useState<any | null>(null);
// //   const [usdcBalance, setUsdcBalance] = useState<string>("0.0");
// //   const [polBalance, setPolBalance] = useState<string>("0.0");
// //   const [amount, setAmount] = useState<string>("");
// //   const [destinationAddress, setDestinationAddress] = useState<string>("");
// //   const [transactionHash, setTransactionHash] = useState<string>("");
// //   const [isTransferring, setIsTransferring] = useState<boolean>(false);

// //   useEffect(() => {
// //     if (provider) {
// //       const web3 = new Web3(provider);
// //       const fetchBalances = async () => {
// //         try {
// //           const accounts = await web3.eth.getAccounts();
// //           if (accounts.length === 0) return;

// //           const account = accounts[0];

// //           // Fetch USDC balance
// //           const usdcContract = new web3.eth.Contract(USDC_ABI as any, USDC_ADDRESS);
// //           const usdcBalance = await usdcContract.methods.balanceOf(account).call();
// //           const formattedUSDCBalance = web3.utils.fromWei(usdcBalance, "mwei"); // USDC uses 6 decimals

// //           // Fetch POL balance (native MATIC/POL)
// //           const polBalance = await web3.eth.getBalance(account);
// //           const formattedPOLBalance = web3.utils.fromWei(polBalance, "ether"); // POL uses 18 decimals

// //           setUsdcBalance(formattedUSDCBalance);
// //           setPolBalance(formattedPOLBalance);

// //         } catch (error) {
// //           console.error("Error fetching balances:", error);
// //           toast.error("Error Fetching Balances");
// //         }
// //       };

// //       fetchBalances();
// //     }
// //   }, [provider]);

// //   const handleTransfer = async () => {
// //     if (!provider || !selectedToken) return;
// //     setIsTransferring(true);
// //     const web3 = new Web3(provider);

// //     try {
// //       const accounts = await web3.eth.getAccounts();
// //       const account = accounts[0];

// //       if (parseFloat(amount) > parseFloat(selectedToken.balance)) {
// //         toast.error("Insufficient balance");
// //         setIsTransferring(false);
// //         return;
// //       }

// //       const amountString = amount.trim();
// //       if (isNaN(Number(amountString)) || Number(amountString) <= 0) {
// //         toast.error("Invalid amount");
// //         setIsTransferring(false);
// //         return;
// //       }

// //       // Suggested gas fees (replace this with your fetched gas fee object)
// //       const suggestedGasFees = {
// //         low: {
// //           suggestedMaxPriorityFeePerGas: "30",
// //           suggestedMaxFeePerGas: "30.000000034",
// //         },
// //         medium: {
// //           suggestedMaxPriorityFeePerGas: "30",
// //           suggestedMaxFeePerGas: "30.000000046",
// //         },
// //         high: {
// //           suggestedMaxPriorityFeePerGas: "30",
// //           suggestedMaxFeePerGas: "30.000000058",
// //         },
// //       };

// //       const selectedGasFees = suggestedGasFees.medium; // Choose from low, medium, high

// //       const maxPriorityFeePerGas = web3.utils.toWei(selectedGasFees.suggestedMaxPriorityFeePerGas, "gwei");
// //       const maxFeePerGas = web3.utils.toWei(selectedGasFees.suggestedMaxFeePerGas, "gwei");

// //       if (selectedToken.symbol === "USDC") {
// //         const usdcContract = new web3.eth.Contract(USDC_ABI as any, USDC_ADDRESS);
// //         const amountInMwei = web3.utils.toWei(amountString, "mwei");

// //         const estimatedGas = await usdcContract.methods
// //           .transfer(destinationAddress, amountInMwei)
// //           .estimateGas({ from: account });

// //         const txReceipt = await usdcContract.methods
// //           .transfer(destinationAddress, amountInMwei)
// //           .send({
// //             from: account,
// //             maxPriorityFeePerGas: maxPriorityFeePerGas,
// //             maxFeePerGas: maxFeePerGas,
// //             gas: estimatedGas,
// //           });

// //         setTransactionHash(txReceipt.transactionHash);

// //       } else if (selectedToken.symbol === "POL") {
// //         const amountInEther = web3.utils.toWei(amountString, "ether");

// //         const estimatedGas = await web3.eth.estimateGas({
// //           from: account,
// //           to: destinationAddress,
// //           value: amountInEther,
// //         });

// //         const txReceipt = await web3.eth.sendTransaction({
// //           from: account,
// //           to: destinationAddress,
// //           value: amountInEther,
// //           maxPriorityFeePerGas: maxPriorityFeePerGas,
// //           maxFeePerGas: maxFeePerGas,
// //           gas: estimatedGas,
// //         });

// //         setTransactionHash(txReceipt.transactionHash);
// //       }

// //       toast.success("Transaction successful. You can check it out on the explorer.");
// //     } catch (error) {
// //       console.error("Transaction error:", error);
// //       toast.error("Transaction failed: " + error?.message);
// //     } finally {
// //       setIsTransferring(false);
// //     }
// //   };



// //   const explorerLink = `${process.env.REACT_APP_POLYGONSCAN_TRANSACTION_URL}${transactionHash}`;

// //   return (
// //     <Modal show={true} onClose={closeModal} data-bs-theme="dark" position={"center"} data-testid="withdraw-wallet-modal">
// //       <Modal.Header className="p-6 bg-monochrome-100 text-white">
// //         <h3 className="text-white">Withdraw Tokens</h3>
// //       </Modal.Header>
// //       <Modal.Body className="p-6 bg-monochrome-100 text-white">
// //         {!transactionHash && (
// //           <>
// //             <div className="mb-4">
// //               <h4 className="mb-2 text-sm">Select Token</h4>
// //               <div className="flex gap-4">
// //                 <Button
// //                   secondary={!selectedToken || selectedToken.symbol !== "USDC"}
// //                   primary={selectedToken && selectedToken.symbol === "USDC"}
// //                   onClick={() => setSelectedToken({ symbol: "USDC", balance: usdcBalance })}
// //                 >
// //                   USDC: {usdcBalance}
// //                 </Button>
// //                 <Button
// //                   secondary={!selectedToken || selectedToken.symbol !== "POL"}
// //                   primary={selectedToken && selectedToken.symbol === "POL"}
// //                   onClick={() => setSelectedToken({ symbol: "POL", balance: polBalance })}
// //                 >
// //                   POL: {polBalance}
// //                 </Button>
// //               </div>
// //             </div>

// //             {selectedToken && (
// //               <div className="flex flex-col gap-4 font-bold text-base">
// //                 <p>{selectedToken.symbol} Balance: {selectedToken.balance}</p>
// //                 <input
// //                   type="text"
// //                   placeholder={`Amount (${selectedToken.symbol})`}
// //                   value={amount}
// //                   onChange={(e) => setAmount(e.target.value)}
// //                   disabled={isTransferring}
// //                 />
// //                 <input
// //                   type="text"
// //                   placeholder="Destination Address"
// //                   value={destinationAddress}
// //                   onChange={(e) => setDestinationAddress(e.target.value)}
// //                   disabled={isTransferring}
// //                 />
// //                 <div className="flex flex-col gap-4 mt-3">
// //                   <Button
// //                     rounded secondary classNames="!py-0 w-full"
// //                     onClick={handleTransfer}
// //                     disabled={!amount || !destinationAddress || parseFloat(selectedToken.balance) < parseFloat(amount) || isTransferring}
// //                   >
// //                     {isTransferring ? "Transferring..." : `Transfer ${selectedToken.symbol}`}
// //                   </Button>
// //                 </div>
// //               </div>
// //             )}
// //           </>
// //         )}

// //         {transactionHash && (
// //           <section className="p-4 flex flex-col gap-7 items-center">
// //             <IcDropdownChecked className="h-10 w-10 bg-status-approved rounded-full p-2" />
// //             <div className="flex flex-col gap-2 items-center">
// //               <h3>Withdrawal Successful</h3>
// //               <p className="text-sm w-[90%] text-center">Your transaction was successful. You can view the transaction details on the block explorer.</p>
// //             </div>
// //             <Button secondary rounded onClick={() => window.open(explorerLink, "_blank")} classNames="w-full">
// //               View Transaction
// //             </Button>
// //             {/* <Button primary rounded onClick={closeModal} classNames="w-full">
// //               Close
// //             </Button> */}
// //           </section>
// //         )}
// //       </Modal.Body>
// //     </Modal>
// //   );
// // };

// // export default WithdrawWallet;



// import React, { useContext, useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import Web3 from "web3";
// import { AuthContext } from "@/provider/AuthContext";
// import { Modal } from "flowbite-react";
// import Button from "./UI/Button";
// import IcDropdownChecked from "@/assets/menu/ic_dropdown_checked";

// interface IProps {
//   closeModal: () => void;
// }

// const PEAQ_ADDRESS = "0xYourPeaqTokenAddress"; // Replace with the PEAQ token address

// const WithdrawWallet = ({ closeModal }: IProps) => {
//   const { provider } = useContext(AuthContext);
//   const [peaqBalance, setPeaqBalance] = useState<string>("0.0");
//   const [amount, setAmount] = useState<string>("");
//   const [destinationAddress, setDestinationAddress] = useState<string>("");
//   const [transactionHash, setTransactionHash] = useState<string>("");
//   const [isTransferring, setIsTransferring] = useState<boolean>(false);

//   useEffect(() => {
//     if (provider) {
//       const web3 = new Web3(provider);
//       const fetchBalance = async () => {
//         try {
//           const accounts = await web3.eth.getAccounts();
//           if (accounts.length === 0) return;

//           const account = accounts[0];

//           // Fetch PEAQ balance
//           const peaqBalance = await web3.eth.getBalance(account); // Assuming PEAQ is native token like POL
//           const formattedPeaqBalance = web3.utils.fromWei(peaqBalance, "ether"); // PEAQ uses 18 decimals

//           setPeaqBalance(formattedPeaqBalance);
//         } catch (error) {
//           console.error("Error fetching balance:", error);
//           toast.error("Error Fetching Balance");
//         }
//       };

//       fetchBalance();
//     }
//   }, [provider]);

//   const handleTransfer = async () => {
//     if (!provider) return;
//     setIsTransferring(true);
//     const web3 = new Web3(provider);

//     try {
//       const accounts = await web3.eth.getAccounts();
//       const account = accounts[0];

//       if (parseFloat(amount) > parseFloat(peaqBalance)) {
//         toast.error("Insufficient balance");
//         setIsTransferring(false);
//         return;
//       }

//       const amountString = amount.trim();
//       if (isNaN(Number(amountString)) || Number(amountString) <= 0) {
//         toast.error("Invalid amount");
//         setIsTransferring(false);
//         return;
//       }

//       const amountInEther = web3.utils.toWei(amountString, "ether");

//       // Suggested gas fees (replace with your fetched gas fee object)
//       const suggestedGasFees = {
//         medium: {
//           suggestedMaxPriorityFeePerGas: "30",
//           suggestedMaxFeePerGas: "30.000000046",
//         },
//       };

//       const maxPriorityFeePerGas = web3.utils.toWei(suggestedGasFees.medium.suggestedMaxPriorityFeePerGas, "gwei");
//       const maxFeePerGas = web3.utils.toWei(suggestedGasFees.medium.suggestedMaxFeePerGas, "gwei");

//       const estimatedGas = await web3.eth.estimateGas({
//         from: account,
//         to: destinationAddress,
//         value: amountInEther,
//       });

//       const txReceipt = await web3.eth.sendTransaction({
//         from: account,
//         to: destinationAddress,
//         value: amountInEther,
//         maxPriorityFeePerGas,
//         maxFeePerGas,
//         gas: estimatedGas,
//       });

//       setTransactionHash(txReceipt.transactionHash);

//       toast.success("Transaction successful. You can check it out on the explorer.");
//     } catch (error) {
//       console.error("Transaction error:", error);
//       toast.error("Transaction failed: " + error?.message);
//     } finally {
//       setIsTransferring(false);
//     }
//   };

//   const explorerLink = `${process.env.REACT_APP_PEAQ_SUBSCAN_TRANSACTION_URL}${transactionHash}`;

//   return (
//     <Modal show={true} onClose={closeModal} data-bs-theme="dark" position={"center"} data-testid="withdraw-wallet-modal">
//       <Modal.Header className="p-6 bg-monochrome-100 text-white">
//         <h3 className="text-white">Withdraw PEAQ</h3>
//       </Modal.Header>
//       <Modal.Body className="p-6 bg-monochrome-100 text-white">
//         {!transactionHash && (
//           <>
//             <div className="flex flex-col gap-4 font-bold text-base">
//               <p>PEAQ Balance: {peaqBalance}</p>
//               <input
//                 type="text"
//                 placeholder="Amount (PEAQ)"
//                 value={amount}
//                 onChange={(e) => setAmount(e.target.value)}
//                 disabled={isTransferring}
//               />
//               <input
//                 type="text"
//                 placeholder="Destination Address"
//                 value={destinationAddress}
//                 onChange={(e) => setDestinationAddress(e.target.value)}
//                 disabled={isTransferring}
//               />
//               <div className="flex flex-col gap-4 mt-3">
//                 <Button
//                   rounded secondary classNames="!py-0 w-full"
//                   onClick={handleTransfer}
//                   disabled={!amount || !destinationAddress || parseFloat(peaqBalance) < parseFloat(amount) || isTransferring}
//                 >
//                   {isTransferring ? "Transferring..." : "Transfer PEAQ"}
//                 </Button>
//               </div>
//             </div>
//           </>
//         )}

//         {transactionHash && (
//           <section className="p-4 flex flex-col gap-7 items-center">
//             <IcDropdownChecked className="h-10 w-10 bg-status-approved rounded-full p-2" />
//             <div className="flex flex-col gap-2 items-center">
//               <h3>Withdrawal Successful</h3>
//               <p className="text-sm w-[90%] text-center">Your transaction was successful. You can view the transaction details on the block explorer.</p>
//             </div>
//             <Button secondary rounded onClick={() => window.open(explorerLink, "_blank")} classNames="w-full">
//               View Transaction
//             </Button>
//           </section>
//         )}
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default WithdrawWallet;


// import React, { useContext, useEffect, useState } from "react";
// import toast from "react-hot-toast";
// import Web3 from "web3";
// // import { AuthContext } from "@/provider/AuthContext";
// import { Modal } from "flowbite-react";
// import Button from "./UI/Button";
// import IcDropdownChecked from "@/assets/menu/ic_dropdown_checked";
// import USDC_ABI from "./usdc_abi.json";
// import { TiTick } from "react-icons/ti";
// import { getNetworkImageSrc } from "@/constants";
// import { AuthContext } from "@/services/AuthContext";
// import Loader from "./Loader";

// const POL_USDC_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359"; // USDC contract on Polygon Mainnet
// const POL_ADDRESS = "0x0000000000000000000000000000000000001010"; // Native POL/MATIC address
// const PEAQ_USDC_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3355"; //PEAQ usdc CONTRACT ADDRESS

// const WithdrawWallet = ({ closeModal, networkType }: any) => {
//   const { provider } = useContext(AuthContext);
//   const [selectedToken, setSelectedToken] = useState<any | null>({ symbol: "", balance: 0 });
//   console.log("🚀 ~ WithdrawWal ~ selectedToken:", selectedToken)
//   const [usdcBalance, setUsdcBalance] = useState<string>("0.0");
//   const [polBalance, setPolBalance] = useState<string>("0.0");
//   const [peaqBalance, setPeaqBalance] = useState<string>("0.0");
//   const [amount, setAmount] = useState<string>("");
//   const [destinationAddress, setDestinationAddress] = useState<string>("");
//   const [transactionHash, setTransactionHash] = useState<string>("");
//   const [isTransferring, setIsTransferring] = useState<boolean>(false);

//   useEffect(() => {
//     if (provider) {
//       const web3 = new Web3(provider);
//       const fetchBalances = async () => {
//         try {
//           const accounts = await web3.eth.getAccounts();
//           if (accounts.length === 0) return;

//           const account = accounts[0];

//           if (networkType === "polygon") {
//             // Fetch USDC balance
//             const usdcContract = new web3.eth.Contract(USDC_ABI as any, POL_USDC_ADDRESS);
//             const usdcBalance = await usdcContract.methods.balanceOf(account).call();
//             const formattedUSDCBalance = web3.utils.fromWei(usdcBalance, "mwei"); // USDC uses 6 decimals

//             // Fetch POL balance (native MATIC/POL)
//             const polBalance = await web3.eth.getBalance(account);
//             const formattedPOLBalance = web3.utils.fromWei(polBalance, "ether"); // POL uses 18 decimals

//             setUsdcBalance(formattedUSDCBalance);
//             setPolBalance(formattedPOLBalance);
//             setSelectedToken({ symbol: "USDC", balance: formattedUSDCBalance })

//           } else if (networkType === "peaq") {
//             const usdcContract = new web3.eth.Contract(USDC_ABI as any, PEAQ_USDC_ADDRESS);
//             const usdcBalance = await usdcContract.methods.balanceOf(account).call();
//             const formattedUSDCBalance = web3.utils.fromWei(usdcBalance, "mwei");

//             const peaqBalance = await web3.eth.getBalance(account);
//             const formattedPeaqBalance = web3.utils.fromWei(peaqBalance, "ether");

//             setUsdcBalance(formattedUSDCBalance);
//             setPeaqBalance(formattedPeaqBalance);
//             setSelectedToken({ symbol: "USDC", balance: formattedUSDCBalance });
//           }
//         } catch (error) {
//           console.error("Error fetching balances:", error);
//           toast.error("Error Fetching Balances");
//         }
//       };

//       fetchBalances();
//     }
//   }, [provider, networkType]);

//   const handleTransfer = async () => {
//     if (!provider || !selectedToken) return;
//     setIsTransferring(true);
//     const web3 = new Web3(provider);

//     try {
//       const accounts = await web3.eth.getAccounts();
//       const account = accounts[0];

//       if (parseFloat(amount) > parseFloat(selectedToken.balance)) {
//         toast.error("Insufficient balance");
//         setIsTransferring(false);
//         return;
//       }

//       const amountString = amount.trim();
//       if (isNaN(Number(amountString)) || Number(amountString) <= 0) {
//         toast.error("Invalid amount");
//         setIsTransferring(false);
//         return;
//       }

//       // Example gas fee object (you can fetch actual gas fees)
//       const suggestedGasFees = {
//         medium: {
//           suggestedMaxPriorityFeePerGas: "30",
//           suggestedMaxFeePerGas: "30.000000046",
//         },
//       };

//        // Fetch the current gas price
//       const currentGasPrice = await web3.eth.getGasPrice();


//       const selectedGasFees = suggestedGasFees.medium;
//       const maxPriorityFeePerGas = web3.utils.toWei(selectedGasFees.suggestedMaxPriorityFeePerGas, "gwei");
//       const maxFeePerGas = web3.utils.toWei(selectedGasFees.suggestedMaxFeePerGas, "gwei");

//       if (selectedToken.symbol === "USDC") {
//         const usdcAddress = networkType === "polygon" ? POL_USDC_ADDRESS : PEAQ_USDC_ADDRESS;
//         const usdcContract = new web3.eth.Contract(USDC_ABI as any, usdcAddress);
//         const amountInMwei = web3.utils.toWei(amountString, "mwei");

//         const estimatedGas = await usdcContract.methods
//           .transfer(destinationAddress, amountInMwei)
//           .estimateGas({ from: account });

//         const txReceipt = await usdcContract.methods
//           .transfer(destinationAddress, amountInMwei)
//           .send({
//             from: account,
//             maxPriorityFeePerGas: maxPriorityFeePerGas,
//             maxFeePerGas: maxFeePerGas,
//             gas: estimatedGas,
//           });

//         setTransactionHash(txReceipt.transactionHash);

//       } else if (selectedToken.symbol === "POL") {
//         const amountInEther = web3.utils.toWei(amountString, "ether");

//         const estimatedGas = await web3.eth.estimateGas({
//           from: account,
//           to: destinationAddress,
//           value: amountInEther,
//         });

//         const txReceipt = await web3.eth.sendTransaction({
//           from: account,
//           to: destinationAddress,
//           value: amountInEther,
//           maxPriorityFeePerGas: maxPriorityFeePerGas,
//           maxFeePerGas: maxFeePerGas,
//           gas: estimatedGas,
//         });

//         setTransactionHash(txReceipt.transactionHash);

//       } else if (selectedToken.symbol === "PEAQ") {
//         // Logic for PEAQ token transfer (on Peaq network)
//         const amountInEther = web3.utils.toWei(amountString, "ether");

//         const estimatedGas = await web3.eth.estimateGas({
//           from: account,
//           to: destinationAddress,
//           value: amountInEther,
//         });

//         const txReceipt = await web3.eth.sendTransaction({
//           from: account,
//           to: destinationAddress,
//           value: amountInEther,
//           // maxPriorityFeePerGas: maxPriorityFeePerGas,
//           // maxFeePerGas: maxFeePerGas,
//           gasPrice:currentGasPrice,
//           gas: estimatedGas,
//         });

//         setTransactionHash(txReceipt.transactionHash);
//       }

//       toast.success("Transaction successful. You can check it on the explorer.");
//     } catch (error) {
//       console.error("Transaction error:", error);
//       toast.error("Transaction failed: " + error?.message);
//     } finally {
//       setIsTransferring(false);
//     }
//   };

//   const explorerLink = networkType === 'polygon' ? `${process.env.REACT_APP_POLYGONSCAN_TRANSACTION_URL}${transactionHash}` : `${process.env.REACT_APP_PEAQ_SUBSCAN_TRANSACTION_URL}${transactionHash}`;

//   return (
//     <Modal show={true} onClose={closeModal} data-bs-theme="dark" position={"center"} data-testid="withdraw-wallet-modal">
//       {/* <Modal.Header className="p-6 bg-monochrome-100 text-white">
//         <h3 className="text-white">Withdraw Tokens</h3>
//       </Modal.Header> */}
//       <Modal.Body className="p-6 bg-white text-black flex flex-col gap-3">

//         {/* header title and cross icon  */}
//         <div className="flex flex-row justify-between ">
//           <h3 className="flex text-black text-xl font-semibold	">Withdraw</h3>
//           <img src="./assets/modalCross.svg " alt="cross" onClick={closeModal} className="flex cursor-pointer" />
//         </div>
//         {/* divider line  */}

//         <hr className="w-full border-t border-[#dad1d1]" />

//         {!transactionHash && (
//           <>
//             <div className="mb-4">
//               <h4 className="mb-2 text-sm">Select Token</h4>
//               <div className="flex flex-row border-[#dad1d1] border-b-[1px]">
//                 <Button
//                   primary={selectedToken && selectedToken.symbol === "USDC"}
//                   onClick={() => setSelectedToken({ symbol: "USDC", balance: usdcBalance })}
//                   classNames="flex w-full border-none"
//                 >
//                   <span className="flex flex-row items-center justify-between w-full">
//                     <div className="flex flex-row gap-2 items-center">
//                       <img
//                         src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035"
//                         alt="USDC icon"
//                         className="flex w-4 h-4"
//                       />
//                       USDC
//                     </div>
//                     {selectedToken?.symbol === "USDC" && <TiTick className="text-primary-100" />}
//                   </span>
//                 </Button>

//                 <Button
//                   primary={selectedToken && (selectedToken.symbol === "POL" || selectedToken.symbol === "PEAQ")}
//                   onClick={() => setSelectedToken({ 
//                     symbol: networkType === "polygon" ? "POL" : "PEAQ", 
//                     balance: networkType === "polygon" ? polBalance : peaqBalance 
//                   })}
//                   classNames="flex w-full border-none"
//                 >
//                   <span className="flex flex-row items-center justify-between w-full">
//                     <div className="flex flex-row gap-2 items-center">
//                       <img
//                         src={networkType === "polygon" ? "./assets/ic_polygon.svg" : "./assets/ic_peaq.svg"}
//                         alt="currency icon"
//                         className="flex w-4 h-4"
//                       />
//                       {networkType === "polygon" ? "POL" : "PEAQ"}
//                     </div>
//                     {(selectedToken?.symbol === "POL" || selectedToken?.symbol === "PEAQ") && (
//                       <TiTick className="text-primary-100" />
//                     )}
//                   </span>
//                 </Button>


//                   <Button
//                     // secondary={!selectedToken || selectedToken.symbol !== "POL"}
//                     primary={selectedToken && selectedToken.symbol === "POL"}
//                     onClick={() => setSelectedToken({ symbol: "POL", balance: polBalance })}
//                     classNames="flex w-full border-none"
//                   >
//                     <span className="flex flex-row items-center justify-between w-full">

//                       <div className="flex flex-row gap-2 items-center">
//                         <img
//                           src={"./assets/ic_polygon.svg"
//                           }
//                           alt="currency icon"
//                           className="flex w-4 h-4"
//                         />
//                         POL
//                       </div>

//                       {
//                         selectedToken?.symbol === "POL" &&
//                         <TiTick className="text-primary-100" />
//                       }
//                     </span>

//                   </Button>
//                 </div>
//               </div>
//             {networkType === "peaq" && (
//               <div className="mb-4">
//                 <h4 className="mb-2 text-sm">Selected Token</h4>
//                 <div className="flex flex-row border-[#dad1d1] border-b-[1px]">
//                   <Button
//                     primary
//                     classNames="flex w-[50%] border-none"
//                   >
//                     <span className="flex flex-row items-center justify-between w-full">
//                       <div className="flex flex-row gap-2 items-center">
//                         <img
//                           src={"./assets/ic_peaq.svg"
//                           }
//                           alt="currency icon"
//                           className="flex w-4 h-4"
//                         />
//                         PEAQ
//                       </div>
//                       <TiTick className="text-primary-100" />
//                     </span>
//                   </Button>
//                 </div>
//               </div>
//             )}
//             {networkType === "ethereum" && (
//               <div className="mb-4">
//                 <h4 className="mb-2 text-sm">Selected Token</h4>
//                 <div className="flex flex-row border-[#dad1d1] border-b-[1px]">
//                   <Button
//                     primary
//                     classNames="flex w-[50%] border-none"
//                   >
//                     <span className="flex flex-row items-center justify-between w-full">
//                       <div className="flex flex-row gap-2 items-center">
//                         <img
//                           src={"./assets/ic_ethereum.svg"
//                           }
//                           alt="currency icon"
//                           className="flex w-4 h-4"
//                         />
//                         Ethereum
//                       </div>
//                       <TiTick className="text-primary-100" />
//                     </span>
//                   </Button>
//                 </div>
//               </div>
//             )}

//             {selectedToken && (
//               // balance input details box 

//               <div className="flex flex-col gap-4 w-full">
//                 <h5>Amount</h5>

//                 <div className="flex flex-row justify-between w-full p-4 rounded-lg border-[#dad1d1] border-[1px] ">

//                   <div className="flex flex-col justify-between gap-6">
//                     <span className="text-[12px] text-xs text-monochrome-20 ">Balance : {selectedToken.balance} {selectedToken.symbol}</span>
//                     <input
//                       placeholder={`Amount (${selectedToken.symbol})`}
//                       value={amount}
//                       onChange={(e) => setAmount(e.target.value)}
//                       disabled={isTransferring}
//                       className=" bg-transparent text-sm focus:outline-none font-medium	"
//                     />
//                     <span className="text-xs text-monochrome-20 ">200 USD</span>
//                   </div>
//                   <div className="flex flex-col self-center">
//                     <div className="flex flex-row gap-2 items-center rounded-lg border-[#dad1d1] border-[1px] p-3 ">
//                       <img
//                         src={
//                           getNetworkImageSrc(selectedToken.symbol)
//                         }
//                         alt="currency icon"
//                         className="flex w-4 h-4"
//                       />
//                       {selectedToken.symbol || 'Ethereum'}
//                     </div>

//                   </div>
//                 </div>

//                 <h6>
//                   Destination Address
//                 </h6>

//                 <input
//                   className="p-4 text-xs focus:outline-none rounded-lg border-[#dad1d1] border-[1px] bg-transparent"
//                   placeholder="Enter your MetaMask wallet address"
//                   value={destinationAddress}
//                   onChange={(e) => setDestinationAddress(e.target.value)}
//                   disabled={isTransferring}
//                 />

//                 <div className="flex flex-col w-full justify-end md:flex-row gap-2 self-center md:self-end ">

//                   <Button
//                     onClick={closeModal}
//                     primary
//                     classNames="flex rounded-lg"

//                   >
//                     Cancel
//                   </Button>
//                   <Button
//                     onClick={handleTransfer}
//                     disabled={!amount || !destinationAddress || isTransferring}
//                     secondary
//                     classNames="flex rounded-lg text-black"
//                   >
//                     {isTransferring ? (
//                       <div className="flex items-center justify-center">
//                       <Loader isLoading={true}  /> {/* Use your Loader component here */}
//                     </div>
//                     ):("Withdraw")}
//                   </Button>
//                 </div>

//               </div>

//             )}
//           </>
//         )}

//         {transactionHash && (
//           <section className="p-4 flex flex-col gap-7 items-center">
//             {/* <img src="./assets/successIcon.svg" alt="success icon" /> */}
//             <IcDropdownChecked />
//             <div className="flex flex-col gap-2 items-center">
//               <h3>Withdrawal Successful</h3>
//               <p className="text-sm w-[90%] text-center text-monochrome-20">You can view the transaction details on the block explorer</p>
//             </div>
//             <div className="flex flex-col md:flex-row gap-2 w-full ">
//               <Button primary rounded onClick={() => window.open(explorerLink, "_blank")} classNames="w-full">
//                 View Transaction
//               </Button>
//               <Button primary rounded onClick={closeModal} classNames="w-full">
//                 Close
//               </Button>
//             </div>
//           </section>
//         )}
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default WithdrawWallet;



import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Web3 from "web3";
import { Modal } from "flowbite-react";
import Button from "./UI/Button";
import IcDropdownChecked from "@/assets/menu/ic_dropdown_checked";
import USDC_ABI from "./usdc_abi.json";
import { TiTick } from "react-icons/ti";
import { getNetworkImageSrc } from "@/constants";
import { AuthContext } from "@/services/AuthContext";
import Loader from "./Loader";
import { sendTransactionHash } from "@/services/api";

const POL_USDC_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
const POL_ADDRESS = "0x0000000000000000000000000000000000001010";
const PEAQ_USDC_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3355";
const PEAQ_ADDRESS = "0x0000000000000000000000000000000000001010"; // Assuming this is the native PEAQ token address

const WithdrawWallet = ({ closeModal, networkType }: any) => {
  const { provider } = useContext(AuthContext);
  const [selectedToken, setSelectedToken] = useState<any>(null);
  const [usdcBalance, setUsdcBalance] = useState("0.0");
  const [nativeBalance, setNativeBalance] = useState("0.0");
  const [amount, setAmount] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [isTransferring, setIsTransferring] = useState(false);

  useEffect(() => {
    if (provider) {
      const web3 = new Web3(provider);
      const fetchBalances = async () => {
        try {
          const accounts = await web3.eth.getAccounts();
          if (accounts.length === 0) return;

          const account = accounts[0];

          if (networkType === "polygon") {
            const usdcContract = new web3.eth.Contract(USDC_ABI, POL_USDC_ADDRESS);
            const usdcBalance = await usdcContract.methods.balanceOf(account).call();
            const formattedUSDCBalance = web3.utils.fromWei(usdcBalance, "mwei");
            setUsdcBalance(formattedUSDCBalance);
            setSelectedToken({ symbol: "USDC", balance: formattedUSDCBalance });
          }
          else if (networkType === "peaq") {
            setSelectedToken({ symbol: "USDC", balance: usdcBalance });
          }

          const nativeBalance = await web3.eth.getBalance(account);
          const formattedNativeBalance = web3.utils.fromWei(nativeBalance, "ether");
          setNativeBalance(formattedNativeBalance);

          if (networkType === "peaq") {
            // setSelectedToken({ symbol: "PEAQ", balance: formattedNativeBalance });
            setSelectedToken({ symbol: "USDC", balance: usdcBalance });

          }
        } catch (error) {
          console.error("Error fetching balances:", error);
          toast.error("Error Fetching Balances");
        }
      };

      fetchBalances();
    }
  }, [provider, networkType]);

  const handleTransfer = async () => {
    if (!provider || !selectedToken) return;
    setIsTransferring(true);
    const web3 = new Web3(provider);

    try {
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];

      if (parseFloat(amount) > parseFloat(selectedToken.balance)) {
        toast.error("Insufficient balance");
        setIsTransferring(false);
        return;
      }

      const amountString = amount.trim();
      if (isNaN(Number(amountString)) || Number(amountString) <= 0) {
        toast.error("Invalid amount");
        setIsTransferring(false);
        return;
      }

      const currentGasPrice = await web3.eth.getGasPrice();

      if (networkType === "polygon" && selectedToken.symbol === "USDC") {
        const usdcContract = new web3.eth.Contract(USDC_ABI, POL_USDC_ADDRESS);
        const amountInMwei = web3.utils.toWei(amountString, "mwei");

        const estimatedGas = await usdcContract.methods
          .transfer(destinationAddress, amountInMwei)
          .estimateGas({ from: account });

        const txReceipt = await usdcContract.methods
          .transfer(destinationAddress, amountInMwei)
          .send({
            from: account,
            gasPrice: currentGasPrice,
            gas: estimatedGas,
          });

        setTransactionHash(txReceipt.transactionHash);

        // Send transaction details to backend using the existing sendTransactionHash function
        const transactionData = {
          transactionPurpose: account === destinationAddress ? 'PAYMENT_TOKEN_TOP_UP' : 'PAYMENT_TOKEN_WITHDRAW',
          transactionMethod: 'ON_CHAIN',
          transactionHash: txReceipt.transactionHash,
          fromAddress: account,
          toAddress: destinationAddress,
          paymentTokenName: selectedToken.symbol,
          paymentTokensTransferred: parseFloat(amount),
          networkType
        };
        console.log("Transaction Data: ", transactionData)

        await sendTransactionHash(transactionData);
      } else {
        const amountInEther = web3.utils.toWei(amountString, "ether");

        const estimatedGas = await web3.eth.estimateGas({
          from: account,
          to: destinationAddress,
          value: amountInEther,
        });

        const txReceipt = await web3.eth.sendTransaction({
          from: account,
          to: destinationAddress,
          value: amountInEther,
          gasPrice: currentGasPrice,
          gas: estimatedGas,
        });

        setTransactionHash(txReceipt.transactionHash);

        // Send transaction details to backend using the existing sendTransactionHash function
        const transactionData = {
          transactionPurpose: account === destinationAddress ? 'PAYMENT_TOKEN_TOP_UP' : 'PAYMENT_TOKEN_WITHDRAW',
          transactionMethod: 'ON_CHAIN',
          transactionHash: txReceipt.transactionHash,
          fromAddress: account,
          toAddress: destinationAddress,
          paymentTokenName: selectedToken.symbol,
          paymentTokensTransferred: parseFloat(amount),
          networkType
        };
        console.log("Transaction Data: ", transactionData)

        await sendTransactionHash(transactionData);
        // await sendTransactionHash(account,destinationAddress,txReceipt.transactionHash)
      }

      toast.success("Transaction successful. You can check it on the explorer.");
    } catch (error) {
      console.error("Transaction error:", error);
      toast.error("Transaction failed: " + error?.message);
    } finally {
      setIsTransferring(false);
    }
  };

  const explorerLink = networkType === 'polygon'
    ? `${process.env.REACT_APP_POLYGONSCAN_TRANSACTION_URL}${transactionHash}`
    : `${process.env.REACT_APP_PEAQ_SUBSCAN_TRANSACTION_URL}${transactionHash}`;

  const nativeTokenSymbol = networkType === "polygon" ? "POL" : "PEAQ";

  useEffect(() => {
    setSelectedToken({ symbol: "USDC", balance: usdcBalance })
  }, [])

  return (
    <Modal show={true} onClose={closeModal} data-bs-theme="dark" position={"center"} data-testid="withdraw-wallet-modal">
      <Modal.Body className="p-6 bg-white text-black flex flex-col gap-3">
        <div className="flex flex-row justify-between ">
          <h3 className="flex text-black text-xl font-semibold">Withdraw</h3>
          <img src="./assets/modalCross.svg" alt="cross" onClick={closeModal} className="flex cursor-pointer" />
        </div>

        <hr className="w-full border-t border-[#dad1d1]" />

        {!transactionHash && (
          <>
            <div className="mb-4">
              <h4 className="mb-2 text-sm">Select Token</h4>
              <div className="flex flex-row border-[#dad1d1] border-b-[1px]">
                <Button
                  primary={selectedToken && selectedToken.symbol === "USDC"}
                  onClick={() => networkType === "polygon" && setSelectedToken({ symbol: "USDC", balance: usdcBalance })}
                  classNames={`flex w-full border-none `}
                // classNames={`flex w-full border-none ${networkType === "peaq" ? "opacity-50 cursor-not-allowed" : ""}`}
                // disabled={networkType === "peaq"}
                >
                  <span className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-row gap-2 items-center">
                      <img
                        src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035"
                        alt="USDC icon"
                        className="flex w-4 h-4"
                      />
                      USDC
                    </div>
                    {selectedToken?.symbol === "USDC" && <TiTick className="text-primary-100" />}
                  </span>
                </Button>
                {/* 
                <Button
                  primary={selectedToken && selectedToken.symbol === nativeTokenSymbol}
                  onClick={() => setSelectedToken({ symbol: nativeTokenSymbol, balance: nativeBalance })}
                  classNames="flex w-full border-none"
                >
                  <span className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-row gap-2 items-center">
                      <img
                        src={networkType === "polygon" ? "./assets/ic_polygon.svg" : "./assets/ic_peaq.svg"}
                        alt="currency icon"
                        className="flex w-4 h-4"
                      />
                      {nativeTokenSymbol}
                    </div>
                    {selectedToken?.symbol === nativeTokenSymbol && <TiTick className="text-primary-100" />}
                  </span>
                </Button> */}
              </div>
            </div>

            {selectedToken && (
              <div className="flex flex-col gap-4 w-full">
                <h5>Amount</h5>

                <div className="flex flex-row justify-between w-full p-4 rounded-lg border-[#dad1d1] border-[1px] ">
                  <div className="flex flex-col justify-between gap-6">
                    <span className="text-[12px] text-xs text-monochrome-20">Balance : {selectedToken.balance} {selectedToken.symbol}</span>
                    <input
                      placeholder={`Amount (${selectedToken.symbol})`}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      disabled={isTransferring}
                      className="bg-transparent text-sm focus:outline-none font-medium"
                    />
                    {/* <span className="text-xs text-monochrome-20">200 USD</span> */}
                  </div>
                  <div className="flex flex-col self-center">
                    <div className="flex flex-row gap-2 items-center rounded-lg border-[#dad1d1] border-[1px] p-3 ">
                      <img
                        src={getNetworkImageSrc(selectedToken.symbol)}
                        alt="currency icon"
                        className="flex w-4 h-4"
                      />
                      {selectedToken.symbol}
                    </div>
                  </div>
                </div>

                <h6>Destination Address</h6>

                <input
                  className="p-4 text-xs focus:outline-none rounded-lg border-[#dad1d1] border-[1px] bg-transparent"
                  placeholder="Enter your MetaMask wallet address"
                  value={destinationAddress}
                  onChange={(e) => setDestinationAddress(e.target.value)}
                  disabled={isTransferring}
                />

                <div className="flex flex-col w-full justify-end md:flex-row gap-2 self-center md:self-end ">
                  <Button
                    onClick={closeModal}
                    primary
                    classNames="flex rounded-lg"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleTransfer}
                    disabled={!amount || !destinationAddress || isTransferring}
                    secondary
                    classNames="flex rounded-lg text-black"
                  >
                    {isTransferring ? (
                      <div className="flex items-center justify-center">
                        <Loader isLoading={true} />
                      </div>
                    ) : (
                      "Withdraw"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}

        {transactionHash && (
          <section className="p-4 flex flex-col gap-7 items-center">
            <IcDropdownChecked />
            <div className="flex flex-col gap-2 items-center">
              <h3>Withdrawal Successful</h3>
              <p className="text-sm w-[90%] text-center text-monochrome-20">You can view the transaction details on the block explorer</p>
            </div>
            <div className="flex flex-col md:flex-row gap-2 w-full ">
              <Button primary rounded onClick={() => window.open(explorerLink, "_blank")} classNames="w-full">
                View Transaction
              </Button>
              <Button primary rounded onClick={closeModal} classNames="w-full">
                Close
              </Button>
            </div>
          </section>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawWallet;