import className from "classnames";
interface ButtonProps{
    children?: React.ReactNode;
    primary?: boolean;
    transparent?: boolean;
    secondary?: boolean;
    onClick?: () => void;
    rounded?: boolean;
    classNames?: string;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
}
const Button : React.FC<ButtonProps> = ({
    children,
    primary = false,
    transparent,
    secondary,
    onClick,
    rounded,
    classNames,
    disabled,
    type = 'button'
}: ButtonProps) => {
  const classes = className(`py-2 px-6 flex flex-row items-center cursor-pointer justify-center gap-2 ${classNames ? classNames : ''}`, {
    "bg-monochrome-5 text-black body-regular border-[1px] border-monochrome-10": primary,
    "bg-green-500 body-medium text-white min-h-[40px]": secondary,
    "p-0 gap-0 bg-transparent": transparent,
    "rounded-[8px]": rounded,
    "opacity-50 cursor-not-allowed": disabled
  });

  return <button className={classes} type={type} onClick={onClick} disabled={disabled}>{children}</button>;
}

export default Button;