import React from 'react'
import ProjectModal from '../Modal'
import { IcDropdownChecked } from '@/assets/menu'
import Button from '@/components/UI/Button'

const SuccessConfirmTransferModal = ({ open, setOpen, handleCloseModal }: any) => {
  return (
    <div>
        <ProjectModal open={open} onClose={handleCloseModal}>
                <section className="p-4 flex flex-col gap-7 items-center">
                    <IcDropdownChecked className={"h-10 w-10 bg-[#E8FFF9] rounded-full p-2 flex items-center justify-center"} />
                    <div className="flex flex-col gap-2 items-center w-full">
                        <h3>Confirmation Submitted</h3>
                        <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
                            All statuses will be marked as completed
                        </p>
                    </div>
                    <div className='flex items-center w-full gap-[10px]'>
                        <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </div>
                </section>
            </ProjectModal>
      
    </div>
  )
}

export default SuccessConfirmTransferModal
