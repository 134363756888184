const IcFillDashboard = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.3332 7.10002V3.31669C18.3332 2.14169 17.7999 1.66669 16.4749 1.66669L13.1082 1.66669C11.7832 1.66669 11.2499 2.14169 11.2499 3.31669V7.09169C11.2499 8.27502 11.7832 8.74169 13.1082 8.74169H16.4749C17.7999 8.75002 18.3332 8.27502 18.3332 7.10002Z"
          fill="#00C798"
        />
        <path
          d="M18.3332 16.475V13.1083C18.3332 11.7833 17.7999 11.25 16.4749 11.25H13.1082C11.7832 11.25 11.2499 11.7833 11.2499 13.1083V16.475C11.2499 17.8 11.7832 18.3333 13.1082 18.3333H16.4749C17.7999 18.3333 18.3332 17.8 18.3332 16.475Z"
          fill="#00C798"
        />
        <path
          d="M8.7499 7.10002V3.31669C8.7499 2.14169 8.21657 1.66669 6.89157 1.66669L3.5249 1.66669C2.1999 1.66669 1.66656 2.14169 1.66656 3.31669L1.66656 7.09169C1.66656 8.27502 2.1999 8.74169 3.5249 8.74169H6.89157C8.21657 8.75002 8.7499 8.27502 8.7499 7.10002Z"
          fill="#00C798"
        />
        <path
          d="M8.7499 16.475V13.1083C8.7499 11.7833 8.21657 11.25 6.89157 11.25H3.5249C2.1999 11.25 1.66656 11.7833 1.66656 13.1083L1.66656 16.475C1.66656 17.8 2.1999 18.3333 3.5249 18.3333H6.89157C8.21657 18.3333 8.7499 17.8 8.7499 16.475Z"
          fill="#00C798"
        />
      </svg>
    </div>
  );
};

export default IcFillDashboard;
