import React from "react";

const IcProjects = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66669 8.33332C2.31669 5.15332 4.72502 2.60832 7.83835 1.74166C8.10002 1.66916 8.23002 1.63249 8.30085 1.70999C8.37169 1.78832 8.31919 1.91666 8.21585 2.17499L7.50002 3.74999M11.6667 1.66666C14.8467 2.31666 17.3917 4.72499 18.2584 7.83832C18.3309 8.09999 18.3675 8.22999 18.29 8.30082C18.2117 8.37166 18.0834 8.31916 17.825 8.21582L16.25 7.49999M18.3334 11.6667C17.6834 14.8467 15.275 17.3917 12.1617 18.2583C11.9 18.3308 11.77 18.3675 11.6992 18.29C11.6284 18.2117 11.6809 18.0833 11.7842 17.825L12.5 16.25M8.33335 18.3333C5.15335 17.6833 2.60835 15.275 1.74169 12.1617C1.66919 11.9 1.63252 11.77 1.71002 11.6992C1.78835 11.6283 1.91669 11.6808 2.17502 11.7842L3.75002 12.5M7.17752 9.72082L10.0934 5.97749C10.3217 5.68499 10.7492 5.86749 10.7492 6.25749V9.15416C10.7492 9.38749 10.9159 9.57749 11.1234 9.57749H12.5417C12.8634 9.57749 13.035 10.0058 12.8225 10.2792L9.90669 14.0225C9.67835 14.315 9.25085 14.1325 9.25085 13.7425V10.8458C9.25085 10.6125 9.08419 10.4225 8.87669 10.4225H7.45835C7.13669 10.4225 6.96419 9.99416 7.17752 9.72082Z"
        stroke="#5B5B5B"
        strokeWidth="1.3"
      />
    </svg>
  );
};

export default IcProjects;
