import Loader from "@/components/Loader";
import { fetchNotificationsByProjectId } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import Button from "@/components/UI/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProjectLastNotificationsItems from "./ProjectLastNotificationsItems";
import { useNetwork } from "@/provider/NetworkContext";

const ProjectLastNotifications = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId") || "";
  const { setShowAllNotifications, showAllNotification } = useNetwork()

  const { data: notifications = {}, isLoading } = useQuery({
    queryKey: ["getNotification", projectId],
    queryFn: () => fetchNotificationsByProjectId(projectId),
    enabled: !!projectId,
    refetchOnWindowFocus: true,
  });

  //const notificationsList = notifications.data || [];

  // Changed: Debug/cp-0.3.0 Sort Notifications by Date/Time
  const notificationsList = (notifications.data || []).sort((a: any, b: any) => {
    return new Date(b.updatedAt || b.createdAt).getTime() - new Date(a.updatedAt || a.createdAt).getTime();
  });
  const navigate = useNavigate();
  const handleRedirectProjects = () => {
    navigate("/notifications");
  };

  // Handler to update the project name in the parent component
  const handleUpdateProject = (id: string, newName: string) => {
    // Logic to update project name in state or refetch projects
    // queryClient.invalidateQueries('getProjects'); // Invalidate the projects query to refetch the data
  };

  return (
    <div className="bg-white p-4 flex flex-col rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h5 className="font-medium text-monochrome-100 text-xl">Last Notifications</h5>
        <Button
          transparent
          classNames="text-monochrome-50 justify-end body-regular pr-0 hover:bg-white"
          onClick={() => {
            setShowAllNotifications(!showAllNotification);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          {showAllNotification ? 'Hide All' : 'View All'}
        </Button>

      </div>
      <div className="overflow-x-auto sm:overflow-hidden">
        <div className="w-[150%] sm:w-full">
          <div className="grid grid-cols-[70%_30%] text-monochrome-30 body-small-regular py-3 border-b border-monochrome-10">
            <span className="text-start">Title</span>
            <span className="text-end">Date</span>
          </div>
          {isLoading && (
            <div className="center min-h-20">
              <Loader isLoading={true} />
            </div>
          )}
          <div className="max-h-[calc(5*3.5rem)] overflow-y-auto">
            {notificationsList.map((project: any, index: number) => (
              <ProjectLastNotificationsItems
                key={`project-item-${index}`}
                item={project}
                onClick={handleRedirectProjects}
                onUpdateProject={handleUpdateProject}
              />
            ))}
          </div>
          {!isLoading && (!notificationsList || notificationsList.length === 0) && <div className="center min-h-40">No notifications are available.</div>}
        </div>
      </div>
    </div>
  );
};

export default ProjectLastNotifications;
