import { IcNextPage, IcPreviousPage, IcSearch } from "@/assets";
import Button from "@/components/UI/Button";
import { useEffect, useState } from "react";
import { IcDropdownChecked } from "@/assets/menu";
import ProjectModal from "../Modal";
import { BegdeIcon, ProjectWarning } from "@/assets/Icons";
import { useQuery, useMutation } from "@tanstack/react-query";
import { formatNumberWithCommas } from "@/utils/commanFunctions";
import {
  getAllPurchaseRequests,
  acceptTokenPurchase,
  confirmFundsReceived,
  declineTokenPurcahse,
  requestRemainingAmountApi,
  refundAmountApi,
  refundFunds,
  transferAfd,
} from "@/services/api";
import IcCross from "@/assets/ic_cross";
import Loader from "@/components/Loader";
import { z } from 'zod';


const perPageItems = [10, 25, 50, 100];

export default function PurchaseRequest({ projectData }: any) {
  const tokenId = projectData?.tokenIds[0]?._id;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Fixed to 10 items per page
  // const [itemsPerPage, setItemsPerPage] = useState(perPageItems[0]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amountTransferredByInvestor, setAmountTransferredByInvestor] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [selectedTab, setSelectedTab] = useState("all");

  const {
    data: purchaseData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["getAllPurchaseRequests", tokenId],
    queryFn: () => getAllPurchaseRequests(tokenId),
    enabled: !!tokenId,
    select: (res) => res.data,
  });
  console.log("Purchase Data: ", purchaseData);

  useEffect(() => {
    if (purchaseData) {
      // Filter holders based on the search term
      // const filtered = purchaseData.filter((item: any) => {
      //     const fullName = `${item.investorId.userId.firstName} ${item.investorId.userId.lastName}`.toLowerCase();
      //     return fullName.includes(searchTerm.toLowerCase());
      // });
      setFilteredData(purchaseData);
    }
  }, [
    purchaseData,
    // searchTerm
  ]);

  const acceptMutation = useMutation({
    mutationFn: (requestId: string) => acceptTokenPurchase(requestId),
    onSuccess: () => {
      refetch();
      setOpen({ ...open, acceptSuccessModal: true });
    },
  });

  const declineMutation = useMutation({
    mutationFn: (requestId: string) => declineTokenPurcahse(requestId),
    onSuccess: () => {
      refetch();
      setOpen({ ...open, declineSuccessModal: true });
    },
  });

  const confirmFundsMutation = useMutation({
    mutationFn: (requestId: string) => confirmFundsReceived(requestId),
    onSuccess: () => {
      refetch();
      setOpen({ ...open, confirmSuccessModal: true });
    },
  });

  const refundFundsMutation = useMutation({
    mutationFn: (requestId: string) => refundFunds(requestId),
    onSuccess: () => {
      refetch();
      setOpen({ ...open, refundSuccessModal: true });
    },
  });

  const transferAFDMutation = useMutation({
    mutationFn: () => transferAfd(projectData?._id),
    onSuccess: () => {
      refetch();
      setOpen({ ...open, transferSuccessModal: true });
    },
  });

  const requestRemainingAmount = useMutation({
    mutationFn: (requestId: string) => {
      // Implement the API call to request remaining amount
      console.log("RequestId in Request Remaining Amount: ", requestId);
      return requestRemainingAmountApi(requestId, { amountTransferredByInvestor });
    },
    onSuccess: () => {
      refetch();
      setOpen({ ...open, requestRemainingAmountSuccessModal: true });
    },
  });

  const refundAmount = useMutation({
    mutationFn: (requestId: string) => {
      // Implement the API call to refund amount
      return refundAmountApi(requestId, { amountTransferredByInvestor });
    },
    onSuccess: () => {
      refetch();
      setOpen({ ...open, refundAmountSuccessModal: true });
    },
  });

  const [open, setOpen] = useState({
    acceptModal: false,
    declineModal: false,
    confirmFundModal: false,
    refundModal: false,
    transferModal: false,
    noConfirmFundModal: false,
    errorModal: false,
    errorMessage: "",
    transferSuccessModal: false,
    acceptSuccessModal: false,
    declineSuccessModal: false,
    confirmSuccessModal: false,
    refundSuccessModal: false,
    requestRemainingAmountSuccessModal: false,
    refundAmountSuccessModal: false,
    selectedRequestId: "",
  });

  const handleCloseModal = () => {
    setOpen({
      acceptModal: false,
      declineModal: false,
      confirmFundModal: false,
      refundModal: false,
      transferModal: false,
      transferSuccessModal: false,
      refundSuccessModal: false,
      noConfirmFundModal: false,
      errorModal: false,
      errorMessage: "",
      acceptSuccessModal: false,
      declineSuccessModal: false,
      confirmSuccessModal: false,
      requestRemainingAmountSuccessModal: false,
      refundAmountSuccessModal: false,
      selectedRequestId: "",
    });
  };

  const handleAccept = (requestId: string) => {
    acceptMutation.mutate(requestId);
  };

  const handleDecline = (requestId: string) => {
    // Implement the decline logic here if needed
    declineMutation.mutate(requestId);
  };

  const handleConfirmFunds = (requestId: string) => {
    setLoading(true);
    confirmFundsMutation.mutate(requestId, {
      onSuccess: () => {
        setLoading(false);
        setOpen({ ...open, confirmSuccessModal: true });
        refetch();
      },
      onError: (error) => {
        setLoading(false);
        // Handle error if needed
        console.log("Error: ", error);
        const errorMessage = error.data?.message || "Error Processing Request";
        setOpen({ ...open, errorModal: true, selectedRequestId: requestId, errorMessage });
      },
    });
  };

  const handleRefundFunds = (requestId: string) => {
    setLoading(true);
    refundFundsMutation.mutate(requestId, {
      onSuccess: () => {
        setLoading(false);
        setOpen({ ...open, refundSuccessModal: true });
        refetch();
      },
      onError: () => {
        setLoading(false);
        // Handle error if needed
        // setOpen({ ...open, noConfirmFundModal: true, selectedRequestId: requestId });
      },
    });
  };

  const handleTransferAFD = async () => {
    setLoading(true);
    setErrorMessage(null); // Reset error message on new attempt
    transferAFDMutation.mutate(projectData?._id, {
      onSuccess: () => {
        setLoading(false);
        setOpen({ ...open, transferSuccessModal: true });
        refetch();
      },
      onError: (error) => {
        setLoading(false);
        console.log("Error: ", error);
        // setError(err)
        setErrorMessage(error.message || "Waiting for completion of third party investor ID verification."); // Set error message
        // Handle error if needed
        // setOpen({ ...open, noConfirmFundModal: true, selectedRequestId: requestId });
      },
    });
  };

  const handleNoConfirmFunds = (requestId: string) => {
    handleCloseModal();
    setOpen((prev) => ({ ...prev, noConfirmFundModal: true, selectedRequestId: requestId }));
  };

  const handleRequestRemainingAmount = () => {
    requestRemainingAmount.mutate(open.selectedRequestId);
  };

  const handleRefundAmount = () => {
    refundAmount.mutate(open.selectedRequestId);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  // const handleItemsPerPageChange = (item: number) => {
  //     setItemsPerPage(item);
  //     setCurrentPage(1); // Reset to first page on items per page change
  // };

  const requests = purchaseData || [];

  const tabList = [
    { id: "all", name: "All Purchase Requests" },
    { id: "pendingBank", name: "Pending Bank Transfers" },
    { id: "pendingCrypto", name: "Pending Crypto Transfers" },
    { id: "completedBank", name: "Completed Bank Transfers" },
    { id: "completedCrypto", name: "Completed Crypto Transfers" },
    { id: "declined", name: "Declined Requests" },
    { id: "refund", name: "Refund Requests" },
  ];

  const filterRequests = (requests: any) => {
    switch (selectedTab) {
      case "pendingBank":
        return requests.filter(
          (request) =>
            request.transferType === "BankTransfer" &&
            request.isPurchaseRequestAccepted &&
            !request.isTokenTransferred &&
            !request.isAmountRefunded &&
            request.isPurchaseRequestDeclined !== "Yes" &&
            !request.isTokenTransferredFailed
        );
      case "pendingCrypto":
        return requests.filter(
          (request) =>
            request.transferType === "CryptoTransfer" &&
            request.isPurchaseRequestAccepted &&
            !request.isTokenTransferred &&
            !request.isAmountRefunded &&
            request.isPurchaseRequestDeclined !== "Yes" &&
            !request.isTokenTransferredFailed
        );
      case "completedBank":
        return requests.filter(
          (request) => request.transferType === "BankTransfer" && request.isTokenTransferred && request.isFundsReceived && !request.isRequestedForRefund
        );
      case "completedCrypto":
        return requests.filter((request) => request.transferType === "CryptoTransfer" && request.isTokenTransferred);
      case "declined":
        return requests.filter((request) => request.isPurchaseRequestDeclined === "Yes" || request.isTokenTransferredFailed);
      case "refund":
        return requests.filter((request) => request.isAmountRefunded || (request.isRequestedForRefund && !request.isAmountRefunded));
      default:
        return requests;

    }
  };

  const displayedRequests = filterRequests(filteredData);

  return (
    <div className="min-h-screen flex flex-col">
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex flex-col gap-4 md:gap-0 md:flex-row justify-between items-center mb-7">
          <h4>Purchase Requests</h4>
          <Button secondary rounded onClick={() => setOpen({ ...open, transferModal: true })}>
            Transfer Tokens to Confirmed Investors
          </Button>
        </div>

        <div className="flex justify-end gap-4">
          <div className="relative w-[210px] sm:w-[230px] md:w-[300px]">
            {/* <input

                                type="text"
                                placeholder="Search investors..."
                                className="!w-full !border-none body-small-regular !text-[12px] !m-0 !bg-monochrome-5 !rounded-lg"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div className="absolute top-[9px] right-3 text-monochrome-20">
                                <IcSearch />
                            </div> */}
          </div>
        </div>
        <div className="overflow-auto pb-5">
          <div className="space-y-4 text-[12px] ">
            {isLoading ? (
              <p>Loading...</p>
            ) : requests.length > 0 ? (
              requests.map((request, index) => (
                <div
                  key={request._id}
                  className={`flex items-center justify-between p-0 sm:py-4 border-b border-monochrome-10 w-[600px] sm:w-[100%] ${
                    index === requests.length - 1 ? "!mb-[40px]" : ""
                  }`}
                >
                  <div className="flex-1">
                    <p className="font-semibold leading-[20px]">
                      {request.investorId.userId.firstName} {request.investorId.userId.lastName}
                    </p>
                    <p className="text-[#8E8E8E] leading-[20px] block md:flex">
                      <div className="pr-[2rem]">
                        {" "}
                        Total Price : <span className="text-black">{formatNumberWithCommas(String((request.tokenAmount ?? 0) * 500))} USD</span>
                      </div>{" "}
                      <div>
                        Token Amount : <span className="text-black">{request.tokenAmount}</span>
                      </div>
                    </p>
                  </div>
                  <div className="space-x-2 flex gap-[10px]">
                    {request?.isRequestedForPurchase && !request.isPurchaseRequestAccepted && (
                      <>
                        <Button primary rounded onClick={() => setOpen({ ...open, acceptModal: true, selectedRequestId: request._id })}>
                          Accept

                        </Button>
                        <Button primary rounded onClick={() => setOpen({ ...open, declineModal: true, selectedRequestId: request._id })}>
                          Decline
                        </Button>
                      </>
                    )}
                    {request.isPurchaseRequestAccepted &&
                      !request.isFundsReceived &&
                      !request?.transactionDetails?.isRequestedRemainingAmount &&
                      !request.isRequestedForRefund && (
                        <div className="flex items-center gap-[10px]">
                          <span>Have funds been received?</span>
                          <Button secondary rounded onClick={() => setOpen({ ...open, confirmFundModal: true, selectedRequestId: request._id })}>
                            Confirm Funds
                          </Button>
                        </div>
                      )}
                    {request?.transactionDetails?.isRequestedRemainingAmount &&
                      request?.transactionDetails?.remaingAmountToPay > 0 &&
                      !request.isRequestedForRefund && (
                        <div className="flex items-center gap-[10px]">
                          <span>Remaining Funds Received?</span>
                          <Button secondary rounded onClick={() => setOpen({ ...open, confirmFundModal: true, selectedRequestId: request._id })}>
                            Confirm Funds
                          </Button>
                        </div>
                      )}
                    {request.isRequestedForRefund && !request.isAmountRefunded && (
                      <div className="flex items-center gap-[10px]">
                        <span>Refund Done?</span>
                        <Button secondary rounded onClick={() => setOpen({ ...open, refundModal: true, selectedRequestId: request._id })}>
                          Confirm Refund
                        </Button>
                      </div>
                    )}
                    {/* {request.isFundsReceived && !request.isTokenTransferred && !request.isTokenTransferredFailed && (
                                        <p className="text-[#3F3F3F] font-semibold">Transfer Initiated</p>
                                    )} */}
                    {request.isPurchaseRequestDeclined === "Yes" && <p className="text-red-500 font-semibold">Purchase Request Declined</p>}
                    {request.isAmountRefunded && <p className="text-orange-400 font-semibold">Amount Refunded</p>}
                    {request.isTokenTransferred && request.isFundsReceived && !request.isRequestedForRefund && (
                      <p className="text-green-500 font-semibold">Transfer Completed</p>
                    )}
                    {request.isTokenTransferredFailed && <p className="text-red-500 font-semibold">Transfer Failed</p>}
                  </div>
                </div>
              ))
            ) : (
              <div className="center min-h-40">
                <p>No purchase requests available.</p>
              </div>
            )}
            {/* <div className="xl:relative w-[600px]  sm:w-[100%] lg:w-full">
              <div className="xl:absolute xl:bottom-[-12px] w-full flex sm:flex justify-between pt-[16px] text-[14px]">
                <div className="flex gap-3 items-center mb-4 sm:mb-0">
                  <ul className="flex">
                    {perPageItems.map((item: number, index: number) => (
                      <li
                        key={`page-items-${index}`}
                        onClick={() => handleItemsPerPageChange(item)}
                        className={`py-2 px-3 body-small-regular border-2 first:rounded-l-lg last:rounded-r-lg cursor-pointer bg-monochrome-5 ${
                          item === 10 ? "border-green-100 bg-status-approved text-white" : "border-monochrome-10 text-monochrome-30"
                        }`}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                  <span className="text-monochrome-20 body-small-regular">Items per page</span>
                </div>
                <div className="flex items-center gap-2 body-small-regular">
                  <span className="text-monochrome-20">Page</span>
                  <input
                    className="!bg-monochrome-5 !text-[12px] !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto] !border-0 p-2 rounded-l-lg !leading-[20px]"
                    type="text"
                    value={currentPage}
                    onChange={(e) => handlePageChange(Number(e.target.value))}
                  />
                  <span className="text-monochrome-20">of {totalPages}</span>
                  <div className="grid grid-cols-2 border-2 border-monochrome-10 divide-monochrome-10 divide-x rounded-lg items-center">
                    <button className="p-2 bg-monochrome-5 rounded-l-lg" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                      <IcPreviousPage />
                    </button>
                    <button
                      className="p-2 bg-monochrome-5 rounded-r-lg"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <IcNextPage />
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ProjectModal open={open.acceptModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <BegdeIcon />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Are you sure you want to accept?</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
              Confirm the sale of {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount || 0} tokens to{" "}
              <span className="text-green-500 font-semibold">
                {requests.find((request) => request._id === open.selectedRequestId)?.investorId.userId.firstName}
              </span>{" "}
              for {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount * 500} USD.
            </p>
          </div>
          <div className="flex items-center w-full gap-[10px]">
            <Button primary rounded classNames="w-full" onClick={() => handleAccept(open.selectedRequestId)}>
              Yes
            </Button>
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              No
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.declineModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <ProjectWarning />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Are you sure you want to decline?</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
              Decline the sale of {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount || 0} tokens to{" "}
              <span className="text-green-500 font-semibold">
                {requests.find((request) => request._id === open.selectedRequestId)?.investorId.userId.firstName}
              </span>{" "}
              for {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount * 500} USD
            </p>
          </div>
          <div className="flex items-center w-full gap-[10px]">
            <Button primary rounded classNames="w-full" onClick={() => handleDecline(open.selectedRequestId)}>
              Yes
            </Button>
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              No
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.confirmFundModal} onClose={handleCloseModal}>
        {/* Header with X button */}
        {!loading && (
          <div className="relative w-full p-4 border-b hover:bg-none">
            <h2 className="text-lg text-center w-full"></h2>
            <Button classNames="absolute top-0 right-2 text-lg hover:bg-transparent focus:outline-none" onClick={handleCloseModal}>
              <img src="../../assets/modalCross.svg" alt="cross" />
            </Button>
          </div>
        )}

        {/* Main Content */}
        <section className="p-4 flex flex-col gap-7 items-center justify-center">
          {loading ? (
            <div className="flex flex-col gap-y-4 justify-center items-center">
              <Loader isLoading={loading} />

              <p className="font-semibold">Your request is being processed.</p>
            </div>
          ) : (
            <>
              <ProjectWarning />
              <div className="flex flex-col gap-2 items-center w-full">
                <h3 className="text-center">Are you sure you've received the funds for this purchase?</h3>
                <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
                  Confirm receipt of {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount || 0} tokens from{" "}
                  <span className="text-green-500 font-semibold">
                    {requests.find((request) => request._id === open.selectedRequestId)?.investorId.userId.firstName}
                  </span>{" "}
                  for {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount * 500} USD.
                </p>
              </div>
              <div className="flex items-center w-full gap-[10px]">
                <Button primary rounded classNames="w-full" onClick={() => handleConfirmFunds(open.selectedRequestId)}>
                  Yes
                </Button>
                <Button primary rounded classNames="w-full" onClick={() => handleNoConfirmFunds(open.selectedRequestId)}>
                  No
                </Button>
              </div>
            </>
          )}
        </section>
      </ProjectModal>

      <ProjectModal open={open.refundModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          {loading ? (
            <div className="flex flex-col justify-center items-center">
              <Loader isLoading={loading} />
              <p className="font-semibold">Refund Initiated!</p>
            </div>
          ) : (
            <>
              <ProjectWarning />
              <div className="flex flex-col gap-2 items-center w-full">
                <h3 className="text-center">Are you sure you've refunded the funds for this investor?</h3>
                <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
                  Confirm refund of{" "}
                  <span className="text-green-500 font-semibold">
                    {requests.find((request) => request._id === open.selectedRequestId)?.investorId.userId.firstName}
                  </span>
                  .
                </p>
              </div>
              <div className="flex items-center w-full gap-[10px]">
                <Button primary rounded classNames="w-full" onClick={() => handleRefundFunds(open.selectedRequestId)}>
                  Yes
                </Button>
                <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
                  No
                </Button>
              </div>
            </>
          )}
        </section>
      </ProjectModal>

      <ProjectModal open={open.transferModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          {loading ? (
            <div className="flex flex-col gap-y-4 justify-center items-center">
              <Loader isLoading={loading} />
              <p className="font-semibold">Transfer Initiated! Tokens are being transferred to Investors</p>
            </div>
          ) : errorMessage ? ( // Display error message if present
            <div className="flex flex-col gap-y-4 justify-center items-center">
              <p className="font-semibold text-red-500">{errorMessage}</p>
              <p className="text-monochrome-20 text-sm">Please try again after some time</p>
              <Button primary rounded onClick={() => setErrorMessage(null)}>
                Close
              </Button>
            </div>
          ) : (
            <>
              <ProjectWarning />
              <div className="flex flex-col gap-2 items-center w-full">
                <h3 className="text-center">Are you sure you want to transfer the tokens to all investors?</h3>
                {/* <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
                                    Confirm Transaction AFD for All Opt-ins.
                                </p> */}
              </div>
              <div className="flex items-center w-full gap-[10px]">
                <Button primary rounded classNames="w-full" onClick={handleTransferAFD}>
                  Yes
                </Button>
                <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
                  No
                </Button>
              </div>
            </>
          )}
        </section>
      </ProjectModal>

      <ProjectModal open={open.noConfirmFundModal} onClose={handleCloseModal}>
        <Button classNames="text-lg" onClick={handleCloseModal}>
          x
        </Button>
        <section className="p-4 flex flex-col gap-7 items-center">
          <ProjectWarning />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Funds Not Confirmed</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">You have not confirmed the receipt of funds. What would you like to do next?</p>
            <input
              type="number"
              className="border p-2 rounded mb-4"
              value={amountTransferredByInvestor}
              onChange={(e) => setAmountTransferredByInvestor(Number(e.target.value))}
              placeholder="Enter amount transferred by investor"
            />
          </div>
          <div className="flex items-center w-full gap-[10px]">
            <Button primary rounded classNames="w-full" onClick={handleRequestRemainingAmount}>
              Request Remaining Amount
            </Button>
            <Button primary rounded classNames="w-full" onClick={handleRefundAmount}>
              Reject and Refund Amount
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.errorModal} onClose={handleCloseModal}>
        <Button classNames="text-lg" onClick={handleCloseModal}>
          x
        </Button>
        <section className="p-4 flex flex-col gap-7 items-center">
          <ProjectWarning />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Error Processing Your Request</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
              {/* You have not confirmed the receipt of funds. What would you like to do next? */}
              {open.errorMessage || "Error Message"}
            </p>
            {/* <input

                            type="number"
                            className="border p-2 rounded mb-4"
                            value={amountTransferredByInvestor}
                            onChange={(e) => setAmountTransferredByInvestor(Number(e.target.value))}
                            placeholder="Enter amount transferred by investor"
                        /> */}
          </div>
          <div className="flex items-center w-full gap-[10px]">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
            {/* <Button primary rounded classNames="w-full" onClick={handleRefundAmount}>
                            Reject and Refund Amount
                        </Button> */}
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.requestRemainingAmountSuccessModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <IcDropdownChecked className={"h-10 w-10 bg-green-200 rounded-full p-2"} />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Request Sent</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">The request for the remaining amount has been sent successfully.</p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.refundAmountSuccessModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <IcDropdownChecked className={"h-10 w-10 bg-white rounded-full p-2"} />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Refund Processed</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">The amount has been successfully refunded.</p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </section>
      </ProjectModal>

      {/* Success Modals */}
      <ProjectModal open={open.acceptSuccessModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <IcDropdownChecked className={"h-10 w-10 bg-green-200 rounded-full p-2"} />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Purchase Request Accepted</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">Confirm funds once you've received the funds.</p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.declineSuccessModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <IcDropdownChecked className={"h-10 w-10 bg-status-notSubmittedBorder rounded-full p-2"} />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Purchase Request Declined</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">You have Declined the Purchase Request.</p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.confirmSuccessModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <IcDropdownChecked className={"h-10 w-10 bg-green-200 rounded-full p-2"} />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Transfer Initiated</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
              <span className="text-green-500 font-semibold">
                {requests.find((request) => request._id === open.selectedRequestId)?.investorId.userId.firstName + `'s`}
              </span>{" "}
              {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount || 0} tokens transfer has been initiated and will be sent to the
              investor soon..
            </p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.refundSuccessModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <IcDropdownChecked className={"h-10 w-10 bg-green-200 rounded-full p-2"} />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Refund Completed</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
              <span className="text-green-500 font-semibold">
                {requests.find((request) => request._id === open.selectedRequestId)?.investorId.userId.firstName + `'s`}
              </span>{" "}
              {requests.find((request) => request._id === open.selectedRequestId)?.tokenAmount || 0} tokens transfer has been Refunded and will be sent to the
              investor soon.
            </p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </section>
      </ProjectModal>

      <ProjectModal open={open.transferSuccessModal} onClose={handleCloseModal}>
        <section className="p-4 flex flex-col gap-7 items-center">
          <IcDropdownChecked className={"h-10 w-10 bg-green-200 rounded-full p-2"} />
          <div className="flex flex-col gap-2 items-center w-full">
            <h3 className="text-center">Transfer Completed</h3>
            <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
              <span className="text-green-500 font-semibold">Transfer of Tokens to Whitelisted Investors was successfull</span>
            </p>
          </div>
          <div className="flex items-center w-full justify-center">
            <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
              Close
            </Button>
          </div>
        </section>
      </ProjectModal>
    </div>
  );

}
