const IcLock = (props: any) => {
    return (
        <div {...props}>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3333 6.49992V5.16658C11.3333 3.29992 9.86663 1.83325 7.99996 1.83325C6.13329 1.83325 4.66663 3.29992 4.66663 5.16658V6.49992C3.53329 6.49992 2.66663 7.36658 2.66663 8.49992L2.66663 13.1666C2.66663 14.2999 3.53329 15.1666 4.66663 15.1666H11.3333C12.4666 15.1666 13.3333 14.2999 13.3333 13.1666V8.49992C13.3333 7.36658 12.4666 6.49992 11.3333 6.49992ZM5.99996 5.16658C5.99996 4.03325 6.86663 3.16659 7.99996 3.16659C9.13329 3.16659 9.99996 4.03325 9.99996 5.16658V6.49992H5.99996V5.16658ZM8.73329 10.8333L8.66663 10.8999V11.8333C8.66663 12.2333 8.39996 12.4999 7.99996 12.4999C7.59996 12.4999 7.33329 12.2333 7.33329 11.8333V10.8999C6.93329 10.4999 6.86663 9.89992 7.26663 9.49992C7.66663 9.09992 8.26663 9.03325 8.66663 9.43325C9.06663 9.76658 9.13329 10.4333 8.73329 10.8333Z" fill="currentColor" />
            </svg>
        </div>
    )
}

export default IcLock;