const API_BASE_URL = process.env.REACT_APP_PENOMO_BACKEND_API;

export const ENDPOINT = {
  USER: `${API_BASE_URL}/api/users`,
  ASSET_BY_ID: `${API_BASE_URL}/api/assets`,
  ASSET_NEW: `${API_BASE_URL}/api/assets/new`,
  PROJECT: `${API_BASE_URL}/api/projects`,
  PROJECT_NEW: `${API_BASE_URL}/api/projects/new`,
  GET_COMPANY_PROJECTS: `${API_BASE_URL}/api/projects/company`,
  GET_TOTAL_FINANCING_RECEIVED: `${API_BASE_URL}/api/projects/totalFinancingReceived`,
  GET_COMPANY_PROJECTS_BY_ID: `${API_BASE_URL}/api/projects`,
  GET_INVESTOR: `${API_BASE_URL}/api/investors`,
  GET_NOTIFICATIONS_PROJECTS_BY_ID: `${API_BASE_URL}/api/projects/notifications`,
  GET_COMPANY: `${API_BASE_URL}/api/companies`,
  DOCS: `${API_BASE_URL}/api/documents`,
  TRANSACTIONS: `${API_BASE_URL}/api/transactions/company`,
  TRANSFER: `${API_BASE_URL}/api/companies/transfer`,
  WEB3_AUTH: `${API_BASE_URL}/api/companies/web3auth`,
  KYC_SUBMIT: `${API_BASE_URL}/api/users/submit/kyc`,
  KYB_SUBMIT: `${API_BASE_URL}/api/companies/kyb/submit`,
  CONTACT_SUPPORT: `${API_BASE_URL}/api/users/contactSupport`,
  NOTIFICATION: `${API_BASE_URL}/api/companies/statusMessages`,
  READ_NOTIFICATION: `${API_BASE_URL}/api/users/notifications/markRead`,
  READ_ALL_NOTIFICATIONS: `${API_BASE_URL}/api/companies/notifications/read-all`,
  REQUEST_FOR_LISTING: `${API_BASE_URL}/api/projects/requestListing`,
  GET_TOKEN_BY_ID: `${API_BASE_URL}/api/projects/getTokenInformation`,
  GET_PURCAHSE_REQUESTS: `${API_BASE_URL}/api/tokenTransfers/getAllPurchaseRequests`,
  GET_ALL_TOKEN_HOLDERS: `${API_BASE_URL}/api/tokenTransfers/getTokenHolders`,
  ACCEPT_PURCAHSE_REQUEST:`${API_BASE_URL}/api/tokenTransfers/acceptPurchaseRequest`,
  DECLINE_PURCAHSE_REQUEST:`${API_BASE_URL}/api/tokenTransfers/declinePurchaseRequest`,
  CONFIRM_FUNDS_RECIEVED:`${API_BASE_URL}/api/tokenTransfers/nyala/accpetAndOptin`,
  REVENUE_NEW:`${API_BASE_URL}/api/revenues/createNewRevenue`,
  REMOVE_REVENUE_DOCS:`${API_BASE_URL}/api/revenues/removeDocument`,
  GET_REVENUE:`${API_BASE_URL}/api/revenues/getRevenueInfoByQuarterAndYear`,
  UPDATE_ALL_INVESTOR_STATUS:`${API_BASE_URL}/api/revenues/updateAllInvestorDistribution`,
  REQUEST_REMAINING_AMOUNT:`${API_BASE_URL}/api/tokenTransfers/requestRemainingAmount`,
  REFUND_AMOUNT:`${API_BASE_URL}/api/tokenTransfers/rejectAndRefund`,
  REFUND_DONE:`${API_BASE_URL}/api/tokenTransfers/refundAmount`,
  TRANSFER_AFD:`${API_BASE_URL}/api/tokenTransfers/nyala/transferTokens`,
  SEND_TRANSACTION:`${API_BASE_URL}/api/transactions/createTWithdrawTransactions`
} as const;

export const ROUTES = {
  APP_ROOT: "/",
  DASHBOARD: "/dashboard",
  PROJECTS: "/projects",
  GETCAPITAL: "/projects/getCapital",
  OVERVIEW: "/projects/overview",
  TRANSACTIONS: "/transactions",
  SETTINGS: "/settings",
  SUPPORT: "/support",
  LOGOUT: "/logout",
  PROFILE_SETUP: "/setupProfile",
  WEBSITE: "https://penomo.io",
  DOCS: "https://penomo.notion.site",
  VERIFY_KYC: "/kyc-verification",
  NOTIFICATIONS: "/notifications",
} as const;


export const getNetworkImageSrc = (selectedNetwork: string): string => {
  switch (selectedNetwork) {
    case "peaq":
      return "./assets/ic_peaq.svg";
    case "PEAQ":
      return "./assets/ic_peaq.svg";
    case "polygon":
      return "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035";
    case "POL":
      return "./assets/ic_polygon.svg";
    case "USDC":
      return "https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035";
    case "ethereum":
      return "./assets/ic_ethereum.svg";
    default:
      return "./assets/default_icon.svg"; // Fallback for unknown networks
  }
};
