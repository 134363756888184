import { formatDDMMYY } from "@/utils";

const ProjectLastNotificationsItems = ({ item, onClick }: any) => {
    return (
        <div 
            onClick={onClick} 
            key={item._id} 
            className="grid grid-cols-[70%_30%] py-3 border-b border-b-monochrome-10 hover:bg-gray-100 cursor-pointer"
        >
            <div className="flex gap-2 sm:gap-4 items-start font-medium text-black">
                <span 
                    className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis body-medium" 
                    title={item.messages}
                >
                    {item.messages}
                </span>
            </div>
            <span className="text-monochrome-60 body-regular text-end my-auto">
                {formatDDMMYY(item.createdAt)}
            </span>
        </div>
    );
};

export default ProjectLastNotificationsItems;
