import React from "react";

const IcFillProjects = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2136_636)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM7.17725 9.72084L10.0931 5.97751C10.3214 5.68501 10.7489 5.86751 10.7489 6.25751V9.15417C10.7489 9.38751 10.9156 9.57751 11.1231 9.57751H12.5414C12.8631 9.57751 13.0347 10.0058 12.8222 10.2792L9.90641 14.0225C9.67808 14.315 9.25058 14.1325 9.25058 13.7425V10.8458C9.25058 10.6125 9.08391 10.4225 8.87641 10.4225H7.45808C7.13641 10.4225 6.96391 9.99417 7.17725 9.72084Z"
            fill="#00C798"
          />
          <path
            d="M1 8.2C1.702 4.7656 4.303 2.017 7.6654 1.081C7.948 1.0027 8.0884 0.9631 8.1649 1.0468C8.2414 1.1314 8.1847 1.27 8.0731 1.549L7.3 3.25M11.8 1C15.2344 1.702 17.983 4.303 18.919 7.6654C18.9973 7.948 19.0369 8.0884 18.9532 8.1649C18.8686 8.2414 18.73 8.1847 18.451 8.0731L16.75 7.3M19 11.8C18.298 15.2344 15.697 17.983 12.3346 18.919C12.052 18.9973 11.9116 19.0369 11.8351 18.9532C11.7586 18.8686 11.8153 18.73 11.9269 18.451L12.7 16.75M8.2 19C4.7656 18.298 2.017 15.697 1.081 12.3346C1.0027 12.052 0.9631 11.9116 1.0468 11.8351C1.1314 11.7586 1.27 11.8153 1.549 11.9269L3.25 12.7"
            stroke="white"
            strokeWidth="1.3"
          />
        </g>
        <defs>
          <clipPath id="clip0_2136_636">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default IcFillProjects;
