import { fetchTransactions, fetchTransactionsUsingWalletId, fetchTransactionsUsingUserId, fetchUser } from "@/services/api";
import Button from "../../UI/Button";
import Loader from "../../Loader";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { ITransaciton } from "@/types";
import { useNetwork } from "@/provider/NetworkContext";
import useAuth from "@/hooks/useAuth";
import TransactionItem from "./TransactionItem";

interface ITransactionsProps {
  selectedTransaction: ITransaciton | null;
  setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
}

const RecentTransactions = ({ selectedTransaction, setSelectedTransaction }: ITransactionsProps) => {
  const navigate = useNavigate();
  const { selectedNetwork } = useNetwork();
  const { userId, companyId } = useAuth();
  const [allTransactions, setAllTransactions] = useState<ITransaciton[]>([]);

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch,
  } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
    select: (data) => data?.data,
  });

  const {
    data: transactions = [],
    isLoading: isLoadingTransactions,
  } = useQuery({
    queryKey: ["getTransactions", companyId],
    queryFn: () => fetchTransactions(companyId, selectedNetwork),
    enabled: !!companyId,
    select: (data) => data?.data ?? [],
  });

  const {
    data: transactionsUsingWalletId = [],
    isLoading: isLoadingTransactionsUsingWalletId,
  } = useQuery({
    queryKey: ["getTransactionsUsingWalletId", userData?.walletDetails?.web3authWalletAddress],
    queryFn: () => fetchTransactionsUsingWalletId(userData?.walletDetails?.web3authWalletAddress),
    enabled: !!userData?.walletDetails?.web3authWalletAddress,
    select: (data) => data?.data ?? [],
  });

  const {
    data: transactionsUsingUserId = [],
    isLoading: isLoadingTransactionsUsingUserId,
  } = useQuery({
    queryKey: ["getTransactionsUsingUserId", userId],
    queryFn: () => fetchTransactionsUsingUserId(userId),
    enabled: !!userId,
    select: (data) => data?.data ?? [],
  });

  const isLoading = isLoadingUser || isLoadingTransactions || isLoadingTransactionsUsingWalletId || isLoadingTransactionsUsingUserId;

  useEffect(() => {
    const mergedTransactions = [
      ...(transactions || []),
      ...(transactionsUsingWalletId?.transactions || []),
      ...(transactionsUsingUserId?.transactions || [])
    ].filter(transaction => 
      transaction && 
      transaction._id && 
      transaction.updatedAt && 
      (transaction.paymentTokensTransferred !== undefined)
    );
    
    setAllTransactions(mergedTransactions);
  }, [transactions, transactionsUsingWalletId, transactionsUsingUserId]);

  // Compute and limit to the 5 most recent valid transactions
  const recentTransactions = useMemo(() => {
    if (!allTransactions.length) return [];
    
    return allTransactions
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
      .slice(0, 5);
  }, [allTransactions]);

  const hasValidTransactions = recentTransactions.length > 0;

  return (
    <div className="bg-white p-4 flex flex-col rounded-lg">
      <div className="flex justify-between">
        <h5 className="font-medium text-monochrome-100 text-xl">Recent Transactions</h5>
        {/* {hasValidTransactions && (
          <Link to="/transactions" className="text-monochrome-50 justify-end body-regular pr-0">
            View All
          </Link>
        )} */}
        <Link to="/transactions" className="text-monochrome-50 justify-end body-regular pr-0">
            View All
          </Link>
      </div>
      <div className="overflow-x-auto sm:overflow-hidden">
        <div className="mt-4 w-[200%] sm:w-full">
          {/* Always show column headers */}
          <div className="grid grid-cols-5 text-monochrome-20 py-3 border-b border-monochrome-10">
            <span className="text-[0.688rem] text-[#9E9E9E]">Type</span>
            <span className="text-end text-[0.688rem] text-[#9E9E9E]">Symbols</span>
            <span className="text-end text-[0.688rem] text-[#9E9E9E]">Date</span>
            <span className="text-end text-[0.688rem] text-[#9E9E9E]">Time</span>
            <span className="text-end text-[0.688rem] text-[#9E9E9E]">Amount</span>
          </div>
          
          {isLoading && (
            <div className="center min-h-20">
              <Loader isLoading={true} />
            </div>
          )}
          
          {!isLoading && hasValidTransactions && (
            recentTransactions.map((transaction) => (
              <TransactionItem
                key={transaction._id}
                item={transaction}
                onClick={() => setSelectedTransaction(transaction)}
                isSelected={selectedTransaction?._id === transaction._id}
                uniqueId={`transaction-${transaction._id}`}
                currentUserId={userId}
                currentWalletAddress={userData?.walletDetails?.web3authWalletAddress || ''}
              />
            ))
          )}
          
          {!isLoading && !hasValidTransactions && (
            <div className="grid grid-cols-5">
              <div className="col-span-5 flex justify-center items-center min-h-[160px] text-[0.75rem]">
                No Recent Transactions
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentTransactions;