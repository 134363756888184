import React from 'react';
import { Modal } from 'flowbite-react';
import Button from '@/components/UI/Button';

interface ExitConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ExitConfirmationModal = ({ show, onClose, onConfirm }: ExitConfirmationModalProps) => {
  return (
    <Modal show={show} onClose={onClose} position={'center'} size={'2xl'}>
      <Modal.Body className="p-4">
        <section className="p-4 flex flex-col gap-7 items-center">
          <h3>Are you sure you want to exit project creation?</h3>
          <p className="text-sm text-monochrome-30 w-[90%] text-center">All the data will be lost.</p>
          <div className="flex gap-4 w-full">
            <Button primary rounded onClick={onConfirm} classNames="w-full">Yes</Button>
            <Button secondary rounded onClick={onClose} classNames="w-full">No</Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ExitConfirmationModal;
