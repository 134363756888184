import React from 'react';

interface KycStatusProps {
  status: 'Not Submitted' | 'Pending' | 'Verified' | 'Rejected' | 'Manual Review' | 'Running';
}

const KycStatus: React.FC<KycStatusProps> = ({ status }) => {
  const getStatusStyles = () => {
    switch (status) {
      case 'Not Submitted':
        return 'text-gray-500';
      case 'Pending':
        return 'text-orange-500';
      case 'Verified':
        return 'text-green-500';
      case 'Rejected':
        return 'text-red-500';
      case 'Manual Review':
        return 'text-blue-500';
      case 'Running':
        return 'text-yellow-500';
      default:
        return '';
    }
  };

  return (
    <div className={`flex items-center gap-2 ${getStatusStyles()}`}>
      <span className="font-semibold text-sm">{status}</span>
    </div>
  );
};

export default KycStatus;
