import React from 'react'

export default function ProjectWarning() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="#FFF4F4" />
            <path d="M17.8842 11.0414C18.8036 9.37877 21.1934 9.37877 22.1128 11.0414L30.4326 26.0795C31.3241 27.6907 30.1587 29.667 28.3178 29.667H11.6804C9.83832 29.667 8.67295 27.6907 9.56443 26.0795L17.8842 11.0414ZM21.071 25.3707C21.0755 25.227 21.0512 25.0838 20.9993 24.9497C20.9475 24.8156 20.8692 24.6933 20.7692 24.5901C20.6691 24.4868 20.5493 24.4047 20.417 24.3487C20.2846 24.2926 20.1423 24.2637 19.9985 24.2637C19.8548 24.2637 19.7125 24.2926 19.5801 24.3487C19.4477 24.4047 19.3279 24.4868 19.2279 24.5901C19.1279 24.6933 19.0496 24.8156 18.9977 24.9497C18.9459 25.0838 18.9215 25.227 18.9261 25.3707C18.9349 25.6492 19.0517 25.9134 19.2519 26.1073C19.4521 26.3013 19.7198 26.4097 19.9985 26.4097C20.2772 26.4097 20.545 26.3013 20.7451 26.1073C20.9453 25.9134 21.0622 25.6492 21.071 25.3707ZM20.7928 16.936C20.765 16.7337 20.6614 16.5495 20.5029 16.4207C20.3445 16.2919 20.143 16.2281 19.9393 16.2421C19.7356 16.2562 19.5448 16.347 19.4056 16.4964C19.2663 16.6457 19.189 16.8424 19.1892 17.0466L19.1935 21.881L19.201 21.9905C19.2289 22.1928 19.3325 22.377 19.4909 22.5058C19.6493 22.6346 19.8508 22.6984 20.0545 22.6844C20.2582 22.6703 20.449 22.5794 20.5882 22.4301C20.7275 22.2808 20.8048 22.0841 20.8046 21.8799L20.8003 17.0444L20.7928 16.936Z" fill="#FF1E1E" />
        </svg>

    )
}
