const IcUser = (props: any) => {
    return (
        <div {...props}>
            <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.00002 9.61587C8.89244 9.61587 11.3334 10.0859 11.3334 11.8992C11.3334 13.7132 8.87643 14.1666 6.00002 14.1666C3.10827 14.1666 0.666687 13.6966 0.666687 11.8832C0.666687 10.0692 3.12361 9.61587 6.00002 9.61587ZM6.00002 0.833252C7.95942 0.833252 9.52934 2.4026 9.52934 4.36062C9.52934 6.31864 7.95942 7.88866 6.00002 7.88866C4.04129 7.88866 2.4707 6.31864 2.4707 4.36062C2.4707 2.4026 4.04129 0.833252 6.00002 0.833252Z" fill="currentColor" />
            </svg>
        </div>
    )
}

export default IcUser