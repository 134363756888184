import { IcWallet } from "@/assets/Icons";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

const GetCapitalBanner = () => {
  const navigate = useNavigate();
  const handleGetCapital = () => {
    navigate("/projects/getCapital");
  };
  return (
    <section className="col-span-2 bg-white py-5 px-4 flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 border-[0.5px] rounded-lg border-green-500 shadow-lg">
      <div className="flex flex-col gap-2 md:flex-row grow items-center">
        <Button classNames="md:ml-4 flex text-nowrap w-[100%] md:w-[250px] lg:w-[200px] xl:w-auto h-fit py-4" secondary rounded onClick={handleGetCapital}>
          Get Started
        </Button>
        <div className="flex flex-column ml-4 w-full">
          <h4 className="font-medium text-monochrome-100">Finance your project</h4>
          <p className="body-small-regular text-monochrome-30">Grow your sustainability project with liquid capital at flexible term.</p>
        </div>
      </div>
    </section>
);
};

export default GetCapitalBanner;
