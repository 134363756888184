import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";
import Select from "react-select"; // Import react-select
import currencyCodes from "currency-codes"; // Import currency-codes

import { ENDPOINT } from "@/constants";
import useAuth from "@/hooks/useAuth";
import { http } from "@/services/http";
import Button from "../UI/Button";
import Loader from "../Loader";
import { fetchCompany, updateBankDetails } from "@/services/api";
import useStore from "@/store/useStore";

// Fetch all currency options using currency-codes
const currencyOptions = currencyCodes.data.map(currency => ({
  value: currency.code,
  label: `${currency.code} - ${currency.currency}`
}));

// Zod schema for form validation
const bankDetailsSchema = z.object({
  bankAccountHolderName: z.string().min(1, "Account holder name is required"),
  bankName: z.string().min(1, "Bank name is required"),
  bankAccountNumber: z.string().min(1, "Account number is required"),
  bankSwiftBicCode: z.string().min(1, "Swift/BIC code is required"),
  bankIban: z.string().min(1, "IBAN is required"),
  bankDefaultCurrency: z.string().min(1, "Default currency is required"),
});

const BankDetails = ({updateBankFormStatus}) => {
  const { companyData, setCompany } = useStore();
  const [formData, setFormData] = useState({
    bankAccountHolderName: companyData.bankAccountHolderName ?? "",
    bankName:companyData.bankName ?? "",
    bankAccountNumber: companyData.bankAccountNumber ?? "",
    bankSwiftBicCode: companyData.bankSwiftBicCode ?? "",
    bankIban: companyData.bankIban ?? "",
    bankDefaultCurrency: companyData.bankDefaultCurrency ?? "",
  });
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [initialFormData, setInitialFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [editMode, setEditMode] = useState(false);
  const { companyId } = useAuth();

  useEffect(() => {
    fetchCompany(companyId)
      .then((response) => {
        if (response.code === 200 && response.data) {
          const companyDetails = response.data;
          const safeBankDetails = {
            bankAccountHolderName: companyDetails.bankAccountHolderName || "",
            bankName: companyDetails.bankName || "",
            bankAccountNumber: companyDetails.bankAccountNumber || "",
            bankSwiftBicCode: companyDetails.bankSwiftBicCode || "",
            bankIban: companyDetails.bankIban || "",
            bankDefaultCurrency: companyDetails.bankDefaultCurrency || "USD",
          };
          setFormData(safeBankDetails);
          setInitialFormData(safeBankDetails);
        }
      })
      .catch((error) => {
        console.error("Error fetching banking details:", error);
        toast.error("Failed to fetch banking details.");
      });
  }, [companyId]);

  useEffect(() => {
    const allFieldsFilled =
      formData.bankName.trim() !== "" &&
      formData.bankAccountHolderName.trim() !== "" &&
      formData.bankAccountNumber.trim() !== "" &&
      formData.bankIban.trim() !== "" &&
      formData.bankSwiftBicCode.trim() !== "" &&
      formData.bankDefaultCurrency.trim() !== "";
    updateBankFormStatus(!allFieldsFilled);
  }, [formData, updateBankFormStatus]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    validateField(name, value);
  };


 
  

  const handleCurrencyChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bankDefaultCurrency: selectedOption.value,
    }));
    validateField("bankDefaultCurrency", selectedOption.value);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setFormData(initialFormData);
    setErrors({});
  };

  const validateField = (name: string, value: any) => {
    const result = bankDetailsSchema.pick({ [name]: true }).safeParse({ [name]: value });
    if (!result.success) {
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: result.error.flatten().fieldErrors[name][0] }));
    } else {
      setErrors((prevErrors : any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editMode) return;

    const result = bankDetailsSchema.safeParse(formData);
    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors);
      toast.error("Please correct the errors before submitting.");
      return;
    }


    setIsSubmittingForm(true);
    try {
      await updateBankDetails(companyId, formData);
      toast.success("Banking details updated successfully!");
      setInitialFormData(formData);
      setEditMode(false);
      setIsSubmittingForm(false);
    } catch (error) {
      setIsSubmittingForm(false);
      console.error("Error updating banking details:", error);
      toast.error("Failed to update banking details.");
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col sm:flex-row bg-white p-4 rounded divide-y sm:divide-y-0 sm:divide-x divide-monochrome-10">
        <div className="flex flex-col gap-2 sm:w-4/12 pb-4  md:pb-0">
          <h6>Bank Details</h6>
          <p className="text-monochrome-20 body-small-regular">Provide your bank details</p>
        </div>
        <form className="flex flex-col body-small-regular gap-6 w-full sm:pl-8 pt-4 lg:pt-0 sm:pr-6 sm:w-8/12" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-6">
            <div className="relative flex flex-col gap-2">
              <label>Account Holder Name</label>
              <input
                type="text"
                name="bankAccountHolderName"
                placeholder="Enter your company name here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankAccountHolderName}
                onChange={handleChange}
              />
              {errors.bankAccountHolderName && <p className="text-red-500 error">{errors.bankAccountHolderName}</p>}
            </div>
            <div className="relative flex flex-col gap-2">
              <label>Bank Name</label>
              <input
                type="text"
                name="bankName"
                placeholder="Enter your registration number here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankName}
                onChange={handleChange}
              />
              {errors.bankName && <p className="text-red-500 error">{errors.bankName}</p>}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="relative flex flex-col gap-2">
              <label>Account Number</label>
              <input
                type="text"
                name="bankAccountNumber"
                placeholder="Enter your account number here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankAccountNumber}
                onChange={handleChange}
              />
              {errors.bankAccountNumber && <p className="text-red-500 error">{errors.bankAccountNumber}</p>}
            </div>
            <div className="relative flex flex-col gap-2">
              <label>SWIFT/BIC Code</label>
              <input
                type="text"
                name="bankSwiftBicCode"
                placeholder="Enter your SWIFT/BIC code here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankSwiftBicCode}
                onChange={handleChange}
              />
              {errors.bankSwiftBicCode && <p className="text-red-500 error">{errors.bankSwiftBicCode}</p>}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="relative flex flex-col gap-2">
              <label>IBAN</label>
              <input
                type="text"
                name="bankIban"
                placeholder="Enter your IBAN here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankIban}
                onChange={handleChange}
              />
              {errors.bankIban && <p className="text-red-500 error">{errors.bankIban}</p>}
            </div>
            <div className="relative flex flex-col gap-2">
              <label>Default Currency</label>
              <Select
                options={currencyOptions}
                value={currencyOptions.find((option) => option.value === formData.bankDefaultCurrency)}
                onChange={handleCurrencyChange}
                isDisabled={!editMode}
                className={`w-full ${editMode ? "border border-monochrome-10 rounded" : "border-none !rounded-none"}`}
              />
              {errors.bankDefaultCurrency && <p className="text-red-500 error">{errors.bankDefaultCurrency}</p>}
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-4">
            {editMode ? (
              <>
                <Button primary rounded classNames="h-10" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button secondary rounded classNames="h-10" type="submit" isLoading={isSubmittingForm}>
                  Save
                </Button>
              </>
            ) : (
              <Button primary rounded classNames="h-10" onClick={handleEdit}>
                Edit
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BankDetails;
