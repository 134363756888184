const IcKycNeeded = () => {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="40" height="40" rx="8" fill="#FFF4F4" />
            <g clipPath="url(#clip0_2174_1280)">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.585 12.3292L26.4183 14.5167C26.7361 14.6358 27.0099 14.8491 27.2032 15.128C27.3965 15.4069 27.5 15.7382 27.5 16.0775V20.5467C27.5 21.9395 27.1122 23.3049 26.3799 24.4897C25.6477 25.6746 24.6 26.6321 23.3542 27.255L20.5592 28.6517C20.3856 28.7385 20.1941 28.7837 20 28.7837C19.8059 28.7837 19.6144 28.7385 19.4408 28.6517L16.6458 27.2542C15.4 26.6313 14.3523 25.6737 13.6201 24.4889C12.8878 23.3041 12.5 21.9387 12.5 20.5459V16.0784C12.4998 15.7389 12.6033 15.4074 12.7966 15.1283C12.9899 14.8492 13.2638 14.6358 13.5817 14.5167L19.415 12.3292C19.7922 12.1878 20.2078 12.1878 20.585 12.3292ZM20 22.1667C19.779 22.1667 19.567 22.2545 19.4107 22.4108C19.2545 22.567 19.1667 22.779 19.1667 23C19.1667 23.221 19.2545 23.433 19.4107 23.5893C19.567 23.7456 19.779 23.8334 20 23.8334C20.221 23.8334 20.433 23.7456 20.5893 23.5893C20.7455 23.433 20.8333 23.221 20.8333 23C20.8333 22.779 20.7455 22.567 20.5893 22.4108C20.433 22.2545 20.221 22.1667 20 22.1667ZM20 16.3334C19.7959 16.3334 19.5989 16.4083 19.4464 16.5439C19.2938 16.6796 19.1964 16.8665 19.1725 17.0692L19.1667 17.1667V20.5C19.1669 20.7124 19.2482 20.9167 19.394 21.0712C19.5398 21.2256 19.7391 21.3185 19.9512 21.331C20.1632 21.3434 20.372 21.2745 20.5349 21.1381C20.6977 21.0018 20.8024 20.8084 20.8275 20.5975L20.8333 20.5V17.1667C20.8333 16.9457 20.7455 16.7337 20.5893 16.5774C20.433 16.4211 20.221 16.3334 20 16.3334Z" fill="#FF1E1E" />
            </g>
            <defs>
                <clipPath id="clip0_2174_1280">
                    <rect width="20" height="20" fill="white" transform="translate(10 10.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IcKycNeeded