import { IcAcArrowDown, IcAcArrowUp, IcFilePlaceholder } from "@/assets/Icons";
import Loader from "@/components/Loader";
import Button from "@/components/UI/Button";
import { revenueGeneratingAssetArray } from "@/constants/getCapitalConstants";
import dayjs from "dayjs";
import { Checkbox, Label } from "flowbite-react";
import { useState } from "react";
import countryList from "react-select-country-list";

interface Props {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  setFormDetails: (value: any) => void;
  formDetails: any;
  handleSubmit: () => void;
  isSubmitting: boolean;
}

const GetCapitalSummery = ({ currentStep, setCurrentStep, setFormDetails, formDetails, handleSubmit, isSubmitting }: Props) => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [accordionState, setAccordionState] = useState({
    capitalNeeds: true,
    revenueBreakdown: false,
    supportingDocuments: false,
  });

  const handleAccordion = (name: string) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const formatNumberWithCommas = (value: string | number) => {
    const num = typeof value === "number" ? value.toString() : value.replace(/\D/g, "");
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <div className="flex flex-column gap-4">
        <div className="flex flex-column">
          <h4>Summary</h4>
          <p className="text-monochrome-30 mt-2 body-regular">Verify Your Project Details</p>
        </div>
        <div className="flex flex-column">
          <div className="flex justify-between border-b py-3 cursor-pointer" onClick={() => handleAccordion("capitalNeeds")}>
            <span className="body-medium">Capital Needs</span>
            {accordionState.capitalNeeds ? <IcAcArrowUp /> : <IcAcArrowDown />}
          </div>
          <div
            className={`bg-[#FCFCFC] body-small-regular overflow-hidden transition-[max-height] duration-500 ease-in ${
              accordionState.capitalNeeds ? "max-h-max" : "max-h-0"
            }`}
          >
            <div className={`px-4 py-1 grid grid-cols-1 gap-2`}>
              <span className="text-monochrome-30 mt-text-sm">Capital needed ?</span>
              <p>$ {formatNumberWithCommas(formDetails.fundingGoal)} USD</p>
            </div>
            <div className={`px-4 py-1 grid grid-cols-1 gap-2`}>
              <span className="text-monochrome-30 mt-text-sm">Project Name</span>
              <p>{formDetails.name}</p>
            </div>
            <div className={`px-4 py-1 grid grid-cols-1 gap-2`}>
              <span className="text-monochrome-30 mt-text-sm">Project description</span>
              <p>{formDetails.projectDescription}</p>
            </div>
            <div className={`px-4 py-1 grid grid-cols-1 gap-2`}>
              <span className="text-monochrome-30 mt-text-sm">Website link</span>
              <p>{formDetails.websiteLink}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-column" id="accordion-collapse" data-accordion="collapse">
          <div className="flex justify-between border-b py-3 cursor-pointer" onClick={() => handleAccordion("revenueBreakdown")}>
            <span className="body-medium">Revenue Breakdown</span>
            {accordionState.revenueBreakdown ? <IcAcArrowUp /> : <IcAcArrowDown />}
          </div>
          <div
            className={`bg-[#FCFCFC] body-small-regular overflow-hidden transition-[max-height] duration-500 ease-in ${
              accordionState.revenueBreakdown ? "max-h-max" : "max-h-0"
            }`}
          >
            <div className="flex flex-col gap-6 p-4">
              <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                <div>
                  <span className="text-monochrome-30">Revenue generating asset used to pay back loan</span>
                  <p>{revenueGeneratingAssetArray.find((item) => item.value === formDetails.assetType)?.label}</p>
                </div>
                <div>
                  <span className="text-monochrome-30">Power capacity ( kW )</span>
                  <p>{formatNumberWithCommas(formDetails.energyCapacity)} kWh</p>
                </div>
                <div className="hidden xl:block"></div>
                <div>
                  <span className="text-monochrome-30">Do you have revenue-generating asset(s)?</span>
                  <p>{formDetails.hasRevenueGeneratingAssets === "1" ? "Yes" : "No"}</p>
                </div>
                <div>
                  <span className="text-monochrome-30">
                    {formDetails.hasRevenueGeneratingAssets === "1" ? "Since when is it generating revenue?" : "When will it generate revenue?"}
                  </span>
                  <p>{dayjs(formDetails.commercialOperationDate).format("DD/MM/YYYY")}</p>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-1">
                <div className="hidden xl:block">
                  <div className="grid grid-cols-3 text-monochrome-30 mb-1">
                    <span>Type of revenue</span>
                    <span className="xl:ml-1.5">Projected yearly revenue</span>
                    {/* <span>Projected yearly revenue?</span> */}
                  </div>
                </div>

                <div className="grid sm:grid-cols-2 xl:grid-cols-1 gap-4 xl:gap-1">
                  {formDetails.typeOfRevenue
                    ?.filter((item: any) => {
                      return item.status;
                    })
                    ?.map((revItem: any, index: number) => {
                      return (
                        <div key={index} className="grid xl:grid-cols-3 border xl:border-none p-3 xl:p-0 rounded-lg">
                          <label className="xl:hidden text-monochrome-30">Type of revenue</label>
                          <span className="mb-4 xl:mb-0">{revItem.name}</span>
                          <label className="xl:hidden text-monochrome-30">Projected yearly revenue</label>
                          <span className="xl:ml-1.5 mb-4 xl:mb-0">$ {formatNumberWithCommas(revItem.projectedRevenue)}</span>
                          {/* <label className="xl:hidden text-monochrome-30">Projected yearly revenue?</label> */}
                          {/* <span>{revItem.yearlyOrMonthly == 0 ? "Per Month" : "Per Year"}</span> */}
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                <div className="flex flex-column gap-2">
                  <span className="text-monochrome-30">Physical location of revenue-generating asset </span>
                  <div className="flex gap-2">
                    {/* <img src={countryList.find((item) => item.value == formDetails.assetLocation)?.icon} alt="" className="w-4 h-4" /> */}
                    {/* {countryList.find((item) => item.value == formDetails.assetLocation)?.label} */}
                    {countryList().getLabel(formDetails.assetLocation)}
                  </div>
                </div>
                <div className="flex flex-column gap-2">
                  <span className="text-monochrome-30">SPV Location of revenue-generating asset (If existent) </span>
                  <div className="flex gap-2">
                    {/* <img src={countryList.find((item) => item.value == formDetails.spvLocation)?.icon} alt="" className="w-4 h-4" /> */}
                    {/* {countryList.find((item) => item.value == formDetails.spvLocation)?.label */}
                    {countryList().getLabel(formDetails.spvLocation)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-column">
          <div className="flex justify-between border-b py-3 cursor-pointer" onClick={() => handleAccordion("supportingDocuments")}>
            <span className="body-medium">Supporting Documents</span>
            {accordionState.supportingDocuments ? <IcAcArrowUp /> : <IcAcArrowDown />}
          </div>
          <div
            className={`bg-[#FCFCFC] body-small-regular overflow-hidden transition-[max-height] duration-500 ease-in ${
              accordionState.supportingDocuments ? "max-h-max" : "max-h-0"
            }`}
          >
            <div className="grid grid-cols-1 gap-4 p-4 ">
              <div className="grid grid-cols-1 gap-2">
                <span className="text-monochrome-30 mt-2">Additional Links</span>
                <div dangerouslySetInnerHTML={{ __html: formDetails.dataRoomLink }}></div>
              </div>
              <div className="grid grid-cols-1 gap-2">
                <span className="text-monochrome-30 mt-2">Attached Files</span>
                <div className="grid grid-cols-1 gap-2">
                  {formDetails.files?.map((item: any, index: number) => {
                    return (
                      <div key={index} className="flex gap-2 underline">
                        <IcFilePlaceholder /> {item.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start gap-1 mt-4">
        <Checkbox id="accept" className="mt-1" onChange={() => setIsTermsAccepted(!isTermsAccepted)} />
        <Label htmlFor="accept" className="flex items-start body-regular pl-2">
          I acknowledge and consent to Penomo BV's collection, use, and protection of my data in strict compliance with applicable data privacy laws. This data
          will be used exclusively for internal analysis as part of the financing due diligence process.
        </Label>
      </div>
      <div className="flex gap-4">
        <Button
          primary
          rounded
          classNames="mt-6"
          disabled={isSubmitting}
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          Back
        </Button>
        <Button secondary rounded classNames="mt-6" disabled={isSubmitting || !isTermsAccepted} onClick={handleSubmit}>
          {isSubmitting ? <Loader isLoading={isSubmitting} /> : "Submit"}
        </Button>
      </div>
    </>
  );
};

export default GetCapitalSummery;
