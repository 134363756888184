import { useEffect } from "react";
import io from "socket.io-client";

const useSocketIO = (userId: string, onMessage: (data: any) => void) => {
  useEffect(() => {
    const socket = io("http://localhost:3000", {
      query: { userId },
    });

    socket.on("kycStatusUpdate", (data) => {
        console.log("Inside Socket io web hook")
      console.log("Received kycStatusUpdate: ", data);
      onMessage(data);
    });

    socket.on("documentAlreadyUsed", (data) => {
      console.log("Received documentAlreadyUsed: ", data);
      onMessage(data);
    });

    return () => {
      socket.disconnect();
    };
  }, [userId, onMessage]);
};

export default useSocketIO;