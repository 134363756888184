const IcContactSupport = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2136_1174)">
        <path
          d="M3.98012 10.2866V6.52947C3.99128 5.79095 4.14805 5.06188 4.44146 4.38405C4.73487 3.70622 5.15914 3.09294 5.68998 2.57936C6.22081 2.06578 6.84776 1.66199 7.53491 1.39113C8.22207 1.12027 8.95591 0.987662 9.69441 1.0009C10.4329 0.987662 11.1667 1.12027 11.8539 1.39113C12.5411 1.66199 13.168 2.06578 13.6988 2.57936C14.2297 3.09294 14.6539 3.70622 14.9474 4.38405C15.2408 5.06188 15.3975 5.79095 15.4087 6.52947V10.2866M12.5515 17.7866C13.3093 17.7866 14.036 17.4856 14.5719 16.9498C15.1077 16.414 15.4087 15.6872 15.4087 14.9295V11.7152M12.5515 17.7866C12.5515 18.2602 12.3634 18.7144 12.0285 19.0493C11.6936 19.3842 11.2394 19.5723 10.7658 19.5723H8.62298C8.14938 19.5723 7.69517 19.3842 7.36029 19.0493C7.0254 18.7144 6.83726 18.2602 6.83726 17.7866C6.83726 17.313 7.0254 16.8588 7.36029 16.5239C7.69517 16.189 8.14938 16.0009 8.62298 16.0009H10.7658C11.2394 16.0009 11.6936 16.189 12.0285 16.5239C12.3634 16.8588 12.5515 17.313 12.5515 17.7866ZM1.83726 8.14376H3.26583C3.45527 8.14376 3.63696 8.21901 3.77091 8.35297C3.90487 8.48692 3.98012 8.6686 3.98012 8.85805V13.1438C3.98012 13.3332 3.90487 13.5149 3.77091 13.6488C3.63696 13.7828 3.45527 13.858 3.26583 13.858H1.83726C1.45838 13.858 1.09502 13.7075 0.82711 13.4396C0.559201 13.1717 0.408691 12.8084 0.408691 12.4295V9.57233C0.408691 9.19345 0.559201 8.83009 0.82711 8.56218C1.09502 8.29427 1.45838 8.14376 1.83726 8.14376ZM17.5515 13.858H16.123C15.9335 13.858 15.7519 13.7828 15.6179 13.6488C15.4839 13.5149 15.4087 13.3332 15.4087 13.1438V8.85805C15.4087 8.6686 15.4839 8.48692 15.6179 8.35297C15.7519 8.21901 15.9335 8.14376 16.123 8.14376H17.5515C17.9304 8.14376 18.2938 8.29427 18.5617 8.56218C18.8296 8.83009 18.9801 9.19345 18.9801 9.57233V12.4295C18.9801 12.8084 18.8296 13.1717 18.5617 13.4396C18.2938 13.7075 17.9304 13.858 17.5515 13.858Z"
          stroke="#5B5B5B"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2136_1174">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcContactSupport;
