import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

// Importing page components and ProtectedRoute
import HomePage from "./HomePage";
import DashboardPage from "./DashboardPage";
import GetCapital from "./Projects/GetCapital";
import ProjectsPage from "./Projects/ProjectsPage";
import SettingsPage from "./SettingsPage";
import ProfilePage from "./ProfilePage";

// Importing common components
import NavBar from "@/components/NavBar";
import Header from "@/components/Header";

import ProtectedRoute from "@/services/ProtectedRoute";
import ExitConfirmationModal from "@/components/Projects/GetFinancial/ExitConfirmationModal";

import useStore from "@/store/useStore";
import { ROUTES } from "@/constants";
import TransactionsPage from "./Transactions/TransactionsPage";
import ContactSupportModal from "@/components/ContactSupport/ContactSupport";
import ContactSupportSuccessModal from "@/components/ContactSupport/SucessModal";
import Notifications from "./Notifications/Notifications";
import OverviewPage from "./Projects/Overview";
import LogoutModal from "@/components/LogoutModal";
import Footer from "@/components/Footer";
import TransactionDetailSideView from "@/components/Transactions/TransactionDetailSideView";
import { ITransaction, IUser } from "@/types";
import useAuth from "@/hooks/useAuth";
import { useNetwork } from "@/provider/NetworkContext";

type Props = {};

const Layout = (props: Props) => {
  const [selectedTransaction, setSelectedTransaction] = useState<ITransaction | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { setShowAllNotifications, showAllNotification } = useNetwork();

  const { userData } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isSetupProfilePage = location.pathname === ROUTES.PROFILE_SETUP;
  // Track the current path
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const { userId } = useAuth();

  useEffect(() => {
    setSelectedTransaction(null);
    // if (userData && userData?.isProfileExisting === false && localStorage.getItem("authToken")) {
    //   console.log("🚀 ~ useEffect ~ userData?.isProfileExisting:", userData?.isProfileExisting);
    //   navigate(ROUTES.PROFILE_SETUP);
    // }
  }, [userData, navigate]);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const web3AuthToken = localStorage.getItem("web3AuthToken");
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
    }
  }, [userData]);

  useEffect(() => {
    // Update the current path when the location changes
    setCurrentPath(location.pathname);
    setShowAllNotifications(false);
  }, [location]);

  useEffect(() => {
    if (userData && !userData.isProfileExisting) {
      navigate("/setupProfile");
    }
  }, []);

  const handleCloseContactModal = (isSuccess: boolean) => {
    setShowSuccessDialog(isSuccess);
    setShowContactModal(false);
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    // e.preventDefault();
    if (path == ROUTES.SUPPORT) {
      setShowContactModal(true);
    } else if (currentPath === ROUTES.GETCAPITAL) {
      // Only show exit confirmation if the current path is GetCapital
      setPendingNavigation(path);
      setShowExitConfirmation(true);
    } else if(path !=undefined){
      navigate(path);
    }
  };

  const handleExitConfirmation = (confirm: boolean) => {
    setShowExitConfirmation(false);
    if (confirm && pendingNavigation) {
      navigate(pendingNavigation);
    }
    setPendingNavigation(null);
  };

  return (
    <>
      <main className="flex flex-col lg:flex-row h-[100%] mx-auto relative w-full">
        <NavBar handleLinkClick={handleLinkClick} setShowLogoutModal={setShowLogoutModal} />
        <section
          className={`${selectedTransaction ? "lg:w-[calc(100%-520px)]" : "w-full"} h-[100%]  relative  ${isLoggedIn && !isSetupProfilePage ? "lg:ml-60" : ""}`}
        >
          <Header />
          <div className="p-4 mx-auto min-h-screen w-full">
            <Routes>
              <Route path={ROUTES.APP_ROOT} element={<HomePage />} />
              <Route
                path={ROUTES.DASHBOARD}
                element={
                  <ProtectedRoute>
                    <DashboardPage selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.PROJECTS}
                element={
                  <ProtectedRoute>
                    <ProjectsPage />
                  </ProtectedRoute>
                }
              />
              <Route path={ROUTES.OVERVIEW} element={<OverviewPage />} />
              <Route path={ROUTES.GETCAPITAL} element={<GetCapital handleLinkClick={handleLinkClick} />} />
              <Route
                path={`${ROUTES.PROJECTS}/:pid`}
                element={
                  <ProtectedRoute>
                    <ProjectsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.TRANSACTIONS}
                element={
                  <ProtectedRoute>
                    <TransactionsPage selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.SETTINGS}
                element={
                  <ProtectedRoute>
                    <SettingsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.PROFILE_SETUP}
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ROUTES.NOTIFICATIONS}
                element={
                  <ProtectedRoute>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>

          <div className="flex p-0 pb-0 mx-auto">
            <Footer />
          </div>
        </section>

        <ExitConfirmationModal show={showExitConfirmation} onClose={() => setShowExitConfirmation(false)} onConfirm={() => handleExitConfirmation(true)} />
        <LogoutModal show={showLogoutModal} setShowLogoutModal={setShowLogoutModal} onClose={() => setShowLogoutModal(false)} />
        {showContactModal && <ContactSupportModal open={showContactModal} onClose={handleCloseContactModal} />}
        {selectedTransaction && (
          <TransactionDetailSideView
            transaction={selectedTransaction}
            onClose={() => setSelectedTransaction(null)}
            currentUserId={userId}
            currentWalletAddress={userData?.walletDetails?.web3authWalletAddress}
          />
        )}
        <ContactSupportSuccessModal show={showSuccessDialog} handleClose={() => setShowSuccessDialog(false)} />
      </main>
    </>
  );
};

export default Layout;

// import React, { useEffect, useState } from "react";
// import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

// // Importing page components and ProtectedRoute
// import DashboardPage from "./pages/DashboardPage";
// import HomePage from "./pages/HomePage";
// import SettingsPage from "./pages/SettingsPage";
// import TokenPurchase from "./pages/TokenPurchase";
// import TokenSale from "./pages/TokenSale";
// import TransactionsPage from "./pages/TransactionsPage";

// // Importing common components
// import NavBar from "./components/NavBar";

// // Importing auth services
// import ProtectedRoute from "./services/ProtectedRoute";

// // Importing styling
// import ContactSupportModal from "./components/ContactSupport";
// import Header from "./components/Header";
// import MarketplaceDetails from "./components/Page/Marketplace/MarketplaceDetails";
// import TransactionDetailSideView from "./components/Page/Transactions/TransactionDetailSideView";
// import { ROUTES } from "./constants";
// import useAuth from "./hooks/useAuth";
// import CampaignPage from "./pages/CampaignPage";
// import Marketplace from "./pages/Marketplace_Launch";
// import Notifications from "./pages/Notifications";
// import PortfolioPage from "./pages/PortfolioPage";
// import { fetchUser } from "./services/api";
// import useStore from "./store/useStore";
// import { ITransaciton } from "./types/transactions";
// import Footer from "./components/Footer";
// import StakingPage from "./pages/StakingPage";
// import GovernancePage from "./pages/GovernancePage";
// import PresalePage from "./pages/PresalePage";

// // lazy loading
// const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
// const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

// const Layout = () => {
//   /**
//    * To Show the transaction details on right side
//    */
//   const navigate = useNavigate();
//   const [selectedTransaction, setSelectedTransaction] = useState<ITransaciton | null>(null);
//   const [showContactModal, setShowContactModal] = useState<boolean>(false);
//   const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);

//   const { pathname } = useLocation();
//   const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
//   const isMarketplaceDetailsPage = pathname === ROUTES.MARKETPLACEDETAILS;
//   const isHomePage = pathname === ROUTES.APP_ROOT;
//   const isCampaignPage = pathname === ROUTES.CAMPAIGN;
//   // Used to detect the route change and hide the transaciton view
//   const location = useLocation();
//   const { userId } = useAuth();
//   const { userData, setUser } = useStore();
//   const [currentPath, setCurrentPath] = useState(location.pathname);

//   useEffect(() => {
//     setSelectedTransaction(null);
//     if (userData && userData?.isProfileExisting === false && localStorage.getItem("authToken")) {
//       navigate(ROUTES.PROFILE_SETUP);
//     }
//   }, [userData, navigate]);

//   useEffect(() => {
//     // Update the current path when the location changes
//     setCurrentPath(location.pathname);
//   }, [location]);

//   useEffect(() => {
//     if (userData._id && localStorage.getItem("authToken")) {
//       fetchUser(userData._id)
//         .then((res: any) => {
//           if (res.data) {
//             setUser(res.data);
//           }
//         })
//         .catch(() => {});
//     }
//   }, [userData._id, setUser]);

//   const haveMargin = () => {
//     if (isSetupProfilePage) {
//       return false;
//     }
//     if (!userId) {
//       return false;
//     }
//     return true;
//   };
//   const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
//     e.preventDefault();
//     if (path == ROUTES.CONTACTSUPPORT) {
//       setShowContactModal(true);
//     } else {
//       navigate(path);
//     }
//   };

//   return (
//     <main className="flex flex-col lg:flex-row mx-auto relative h-screen">
//       <NavBar handleLinkClick={handleLinkClick} />
//       <section
//         className={`flex flex-col w-full ${haveMargin() ? "lg:ml-72" : "lg:ml-0"} ${
//           isHomePage ? "bg-[#1A1C22]" : "" //isCampaignPage ? "bg-monochrome-100" : "bg-monochrome-60"
//         } h-full`}
//       >
//         <Header />
//         <div
//           className={`mt-4 ${pathname == ROUTES.APP_ROOT || pathname == ROUTES.PROFILE_SETUP ? "mb-0" : "mb-4"} ${
//             pathname == ROUTES.MARKETPLACEDETAILS || pathname == ROUTES.CAMPAIGN ? "md:w-full" : "px-4 md:px-2 xl:px-0 mx-auto w-full md:w-[97%] lg:w-[98%]   "
//           } ${!userId ? "mx-0" : isCampaignPage ? "px-0 pt-6" : " pt-0"} p-${isMarketplaceDetailsPage ? 0 : 0} ${
//             haveMargin() ? "grow" : "h-[calc(100%-2rem)]"
//           } `}
//         >
//           <Routes>
//             <Route path={ROUTES.APP_ROOT} element={<HomePage />} />
//             <Route
//               path={ROUTES.DASHBOARD}
//               element={
//                 <ProtectedRoute>
//                   <DashboardPage selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.MARKETPLACE}
//               element={
//                 <ProtectedRoute>
//                   <Marketplace />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.MARKETPLACEDETAILS}
//               element={
//                 <ProtectedRoute>
//                   <MarketplaceDetails />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.TRANSACTIONS}
//               element={
//                 <ProtectedRoute>
//                   <TransactionsPage selectedTransaction={selectedTransaction} setSelectedTransaction={setSelectedTransaction} />
//                 </ProtectedRoute>
//               }
//             />
//             {/* <Route
//               path={ROUTES.CONTACTSUPPORT}
//               element={
//                 <ProtectedRoute>
//                   <ContactSupportPage />
//                 </ProtectedRoute>
//               }
//             /> */}
//             <Route
//               path={ROUTES.TOKEN_SALE}
//               element={
//                 <ProtectedRoute>
//                   <TokenSale />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.TOKEN_PURCHASE}
//               element={
//                 <ProtectedRoute>
//                   <TokenPurchase />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.SETTINGS}
//               element={
//                 <ProtectedRoute>
//                   <SettingsPage />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.CAMPAIGN}
//               element={
//                 <ProtectedRoute>
//                   <CampaignPage />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.PORTFOLIO}
//               element={
//                 <ProtectedRoute>
//                   <PortfolioPage />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.PROFILE_SETUP}
//               element={
//                 <ProtectedRoute>
//                   <ProfilePage />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.NOTIFICATIONS}
//               element={
//                 <ProtectedRoute>
//                   <Notifications />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.STAKING}
//               element={
//                 <ProtectedRoute>
//                   <StakingPage />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.GOVERNANCE}
//               element={
//                 <ProtectedRoute>
//                   <GovernancePage />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path={ROUTES.PNMO_PRESALE}
//               element={
//                 <ProtectedRoute>
//                   <PresalePage />
//                 </ProtectedRoute>
//               }
//             />
//             <Route
//               path="*"
//               element={
//                 <ProtectedRoute>
//                   <NotFoundPage />
//                 </ProtectedRoute>
//               }
//             />
//           </Routes>
//         </div>
//         <Footer />
//       </section>
//       {showContactModal && <ContactSupportModal open={showContactModal} onClose={() => setShowContactModal(false)} />}

//       {selectedTransaction && <TransactionDetailSideView transaction={selectedTransaction} onClose={() => setSelectedTransaction(null)} />}
//     </main>
//   );
// };

// export default Layout;
