import { useState, useEffect } from "react";
import GetListed from "./GetListed";
import ProjectModal from "../../Modal";
import { IcDropdownChecked } from "@/assets/menu";
import Button from "@/components/UI/Button";
import ListingPerformance from "./ListingPerformance";
import { requestForListing } from "@/services/api";
import Loader from "@/components/Loader";

export default function Listing({ projectData, refetch }: any) {
  const [open, setOpen] = useState(false);
  const [showList, setShowList] = useState(projectData?.status === "Listed");
  const [loading, setLoading] = useState(false);
  const [listingSuccess, setListingSuccess] = useState(false);

  const handleCloseModal = async () => {
    if (!loading) {
      setOpen(false);
      setListingSuccess(false);
      await refetch(); // Refetch project data after closing the modal
      setShowList(projectData?.status === "Listed");
    }
  };

  const handleClickListProject = async (data: any) => {
    setLoading(true);
    setOpen(true); // Open the modal immediately
    try {
      await requestForListing(projectData._id, data); // Send the listing data to the API
      setListingSuccess(true); // Set success state to true
      // await refetch();
    } catch (error) {
      console.error("Error tokenizing asset:", error);
      // Handle error (e.g., show a notification or error message)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setShowList(projectData?.status === "Listed");
  }, [projectData?.status]);

  return (
    <>
      <div className="w-full">
        {!showList ? (
          <GetListed onListProjectClick={handleClickListProject} projectData={projectData} loading={loading} />
        ) : (
          <ListingPerformance projectData={projectData} refetch={refetch} />
        )}
      </div>
      <ProjectModal open={open} onClose={handleCloseModal}>
        {loading ? (
          <section className="p-4 flex flex-col gap-7 items-center">
            <Loader isLoading={true} />
            <div className="flex flex-col gap-2 items-center w-full">
              <h3>Project Listing in Progress</h3>
              <p className="text-sm w-[90%] text-center text-[#8E8E8E]">Your project is getting listed. Please don't go back.</p>
            </div>
          </section>
        ) : listingSuccess ? (
          <section className="p-4 flex flex-col gap-7 items-center">
            <IcDropdownChecked className="h-10 w-10 bg-[#E8FFF9] rounded-full p-2 flex items-center justify-center" />
            <div className="flex flex-col gap-2 items-center w-full">
              <h3>Listing in Review</h3>
              <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
                You have successfully requested the listing of your project. We will get back to you soon.
              </p>
            </div>
            <div className="flex items-center w-full gap-[10px]">
              <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
                Close
              </Button>
            </div>
          </section>
        ) : null}
      </ProjectModal>
    </>
  );
}
