// KycVerificationBanner.tsx
import { IcArrowUp, IcKycNeeded } from "@/assets/Icons";
import Button from "../UI/Button";

interface KycVerificationBannerProps {
  isVerified: boolean;
  customerId?: string;
}

const KycVerificationBanner = ({ isVerified, customerId }: KycVerificationBannerProps) => {
  if (isVerified) {
    return null;
  }

  const handleVerifyClick = () => {
    window.open(
      `https://signup.getsilt.com/?company_app_id=e50d6912-5c56-40c8-8724-78503d5da4cb&customer_user_id=${customerId}&hide_redirect_button=true`,
      "_blank"
    );
  };

  return (
    <section className="col-span-2 bg-white px-4 py-4 mb-4 flex flex-col sm:flex-row gap-4 sm:gap-0 border-[0.5px] rounded-lg border-danger-100 shadow-lg mt-10">
      <div className="flex grow">
        <IcKycNeeded />
        <div className="flex flex-col ml-4">
          <h4 className="font-medium">Complete KYC</h4>
          <p className="body-small-regular text-monochrome-20">Get verified to be eligible for PNMO rewards.</p>
        </div>
      </div>
      <Button classNames="md:w-[250px] lg:w-[200px] xl:w-auto" primary rounded onClick={handleVerifyClick}>
        Verify KYC
        <IcArrowUp />
      </Button>
    </section>
  );
};

export default KycVerificationBanner;
