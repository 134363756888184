import React from "react";

const IcTransactions = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.868 2.5C16.6238 2.5 18.8655 4.74167 18.8655 7.49833V13.4792C18.8655 16.235 16.6238 18.4775 13.868 18.4775H6.66465C3.90882 18.4775 1.66632 16.235 1.66632 13.4792V7.49833C1.66632 4.74167 3.90882 2.5 6.66465 2.5H13.868ZM13.868 3.75H6.66465C4.59799 3.75 2.91632 5.43167 2.91632 7.49833V13.4792C2.91632 15.5458 4.59799 17.2275 6.66465 17.2275H13.868C15.9347 17.2275 17.6155 15.5458 17.6155 13.4792V13.2458L14.8672 13.2463C13.2864 13.2463 11.9997 11.9605 11.9989 10.3805C11.9989 8.79883 13.2856 7.51217 14.8672 7.51133L17.6155 7.51083V7.49833C17.6155 5.43167 15.9347 3.75 13.868 3.75ZM17.6155 8.76083L14.8672 8.76133C13.9747 8.76217 13.2489 9.488 13.2489 10.3797C13.2489 11.2705 13.9756 11.9963 14.8672 11.9963L17.6155 11.9958V8.76083ZM15.2486 9.703C15.5936 9.703 15.8736 9.983 15.8736 10.328C15.8736 10.673 15.5936 10.953 15.2486 10.953H14.9886C14.6436 10.953 14.3636 10.673 14.3636 10.328C14.3636 9.983 14.6436 9.703 14.9886 9.703H15.2486ZM10.5705 6.28175C10.9155 6.28175 11.1955 6.56175 11.1955 6.90675C11.1955 7.25175 10.9155 7.53175 10.5705 7.53175H6.07132C5.72632 7.53175 5.44632 7.25175 5.44632 6.90675C5.44632 6.56175 5.72632 6.28175 6.07132 6.28175H10.5705Z"
        fill="#5B5B5B"
      />
    </svg>
  );
};

export default IcTransactions;
