import logo from "@/assets/penomo_logo.svg";
import Login from "@/components/Login";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";
const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Check local storage for existing tokens
    const authToken = localStorage.getItem('authToken');
    const web3AuthToken = localStorage.getItem('web3AuthToken');
    if (authToken && web3AuthToken) {
      setIsLoggedIn(true);
      navigate('/dashboard');
    }
  }, [navigate]);
  return (
    <div className="flex home-main gap-4">
      {
        !isLoggedIn && <div className="w-2/4 hidden xl:flex flex-col h-full gap-4">
          <div className="flex w-full h-1/3 gap-4">
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="solar" src={'./assets/dashboard/soloar.png'} />
            <img className="w-2/3 rounded overflow-hidden" loading="lazy" alt="green energy" src={'./assets/dashboard/green_3.png'} />
          </div>
          <div className="flex w-full h-1/3 gap-4">
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="green energy" src={'./assets/dashboard/green_1.png'} />
            <img className="w-2/3 rounded overflow-hidden" loading="lazy" alt="wind energy" src={'./assets/dashboard/wind.png'} />
          </div>
          <div className="flex w-full h-1/3 gap-4">
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="ev charging station" src={'./assets/dashboard/chargin_station.png'} />
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="green energy" src={'./assets/dashboard/green_2.png'} />
            <img className="w-1/3 rounded overflow-hidden" loading="lazy" alt="ev charging point" src={'./assets/dashboard/ev_charging_point.png'} />
          </div>
        </div>
      }
      <div className={`w-full ${!isLoggedIn ? 'xl:w-2/4' : ''} flex h-full flex-col items-center justify-center`}>
        <img src={logo} className="h-[48px] md:w-67 h-14 mb-3" alt="penomo logo" />
        <p className="text-center font-medium text-[#3F3F3F]"> Get revenue-based smart capital for your green assets.</p>
        {
            <div className="mb-6 w-[218px]">
              <Login />
            </div>
        }
        {
          !isLoggedIn && <p style={{ fontSize: "12px", color: "#8E8E8E" }}>Haven't registered? Log in to create an account</p>
        }
        
      </div>
    </div>
  )
};
export default HomePage;